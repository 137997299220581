import React from "react";
import { Navigate } from "react-router-dom";
import { makeAPICall } from "../../global/global-functions";
import Button from "../../reuseables/button/button";
import Quote from "../../reuseables/quote/quote";
import "./track.css";

const TEMPLATES = {
  0: "Entrepreneurship",
  1: "Corporate Titian",
  2: "Student/Academic",
  3: "General",
};

class TrackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: [],
      track_id: null,
    };

    this.NavigateToTemplateList = this.NavigateToTemplateList.bind(this);
  }

  componentDidMount() {
    makeAPICall(
      "GET",
      "/template/templates",
      undefined,
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          this.setState({ tracks: response["message"] });
        }
      }
    );
  }

  NavigateToTemplateList(track_id) {
    this.setState({ track_id: track_id });
  }

  render() {
    let tracks = this.state.tracks;
    let track_id = this.state.track_id;
    if (track_id !== null) {
      return <Navigate to={`/inspire/templates/${track_id}`} />;
    }
    return (
      <div id="templates-page" className="page">
        <div>
          <Quote
            quote="If you cannot see where you are going, ask someone who has been there before"
            author="J Loren Norris"
            showLine={true}
            align="center"
          />
        </div>
        <div id="track-container">
          {tracks.map((track, index) => {
            let id = track[0];
            return (
              <div
                key={index}
                className="track-card"
                style={{
                  backgroundColor: index % 2 !== 0 ? "#fffffe" : "#D5F3FE",
                }}
              >
                <label className="label-input">{TEMPLATES[id]}</label>
                <label className="centered-italized-small-text">
                  {track[1]} Templates Available
                </label>
                <div style={{ width: "50%" }}>
                  <Button
                    buttonName="View"
                    fontSize="0.8rem"
                    buttonColor="#2F7189"
                    textColor="#FFFFFE"
                    onClick={() => {
                      this.NavigateToTemplateList(id);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default TrackPage;
