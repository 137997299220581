import React from "react";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../reuseables/button/button";
import { emojify } from "node-emoji";
import "./track.css";
import { Navigate } from "react-router-dom";
import { makeAPICall } from "../../global/global-functions";

function TemplateCard(naviagate, index, template) {
  return (
    <div className="column-3" key={index} style={{ backgroundColor: "" }}>
      <div className="row-2" style={{ padding: "5px", position: "relative" }}>
        <FontAwesomeIcon icon={faFileAlt} fontSize="4.5rem" />
        <label className="emoji-container">
          {emojify(`:${template["template_icon"]}:`)}
        </label>
      </div>
      <div className="row-2">
        <label
          className="centered-italized-small-text"
          style={{ fontWeight: "bold" }}
        >
          {template["template_name"]}
        </label>
        <div>
          <Button
            buttonName="View"
            buttonColor="#3C45A8"
            textColor="#FFFFFE"
            fontWeight="100"
            fontSize="0.8rem"
            onClick={() => naviagate(template["template_id"])}
          />
        </div>
      </div>
    </div>
  );
}

class TemplateListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      template_id: null,
    };

    this.NavigateToTemplateView = this.NavigateToTemplateView.bind(this);
    this.track_id = null;
  }

  componentDidMount() {
    let path_array = window.location.pathname.split("/");
    this.track_id = path_array[path_array.length - 1];
    makeAPICall(
      "GET",
      `/template/templates/${this.track_id}`,
      undefined,
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          this.setState({ templates: response["message"] });
        }
      }
    );
  }

  NavigateToTemplateView(template_id) {
    this.setState({ template_id: template_id });
  }

  render() {
    let templates = this.state.templates;
    let template_id = this.state.template_id;

    if (template_id !== null) {
      return <Navigate to={`/inspire/templates/${this.track_id}/${template_id}`} />;
    }

    return (
      <div className="page">
        <div className="search-container">
          <input
            className="search-input "
            type="text"
            placeholder="Enter a name or keyword..."
          />
          <hr className="horizontal-line" />
        </div>
        <div className="list-container">
          {templates.map((template, index) => {
            return TemplateCard(this.NavigateToTemplateView, index, template);
          })}
        </div>
      </div>
    );
  }
}

export default TemplateListPage;
