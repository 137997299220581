import { Component } from 'react';
import "./inspire.css";
import {
    faBook, faVideo, faScroll, faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../navbar/logo-mvision.jpg";
import {
  makeAPICall,
} from "../../global/global-functions";

class InspirePage extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        content: [],
        types: [],
    }
    componentDidMount(){
        makeAPICall("GET", "/library/content", undefined, (haserror, response) => {
            if (haserror) {
                console.log(response["message"]);
            } else {
                const content = response["message"].sort((a,b)=>b.vote_count-a.vote_count).splice(0,4);
                this.setState({ content });
            }
        });
        makeAPICall("GET", "/library/types", undefined, (haserror, response) => {
            if (haserror) {
                console.log(response["message"]);
            } else {
                const types = response["message"];
                this.setState({ types });
            }
        });
    }
    render() { 
        return (
            <div id='inpire-page'>
                <div className="container">
                    <div className="box">
                        <h1>Inspire Sections</h1>
                    </div>
                    <div className="box">
                        <i>The edge is in the inputs. 
                        <br/>
                        The person who consumes from better sources, gets better thoughts.
                        <br/>
                        The person who asks better questions, gets better answers. 
                        <br/>
                        - James Clear</i>
                    </div>
                    <div className="box">
                        <div className="container">
                            <div className="row-1">
                                <span><FontAwesomeIcon icon={faBook}/></span>
                                <h1>&nbsp;<br/>Shaper Examples<br/>(MyVision Page)</h1>
                                <hr/>
                            </div>
                            <div className="row-2">
                                <div className='mv-box blue'>
                                    <h1>Entrepreneur Track</h1>
                                    <h1>&nbsp;</h1>
                                    <h2>5 Shaper Examples Available</h2>
                                </div>
                            </div>
                            <div className="row-2">
                                <div className='mv-box white'>
                                    <h1>Corporate Titan Track</h1>
                                    <h1>&nbsp;</h1>
                                    <h2>10 Shaper Examples Available</h2>
                                </div>                                
                            </div>
                            <div className="row-3">
                                <div className='mv-box white'>
                                    <h1>General Track</h1>
                                    <h1>&nbsp;</h1>
                                    <h2>10 Shaper Examples Available</h2>
                                </div>           
                            </div>
                            <div className="row-3">
                                <div className='mv-box blue'>
                                    <h1>Academia Student</h1>
                                    <h1>Track</h1>
                                    <h2>5 Shaper Examples Available</h2>
                                </div>
                            </div>
                            <div className="row-4">
                                <button
                                    onClick={event =>  window.location.href='/inspire/shaper'}
                                    className="mv-btn mv-light-purple btn-sm no-border">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    View
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="container">
                            <div className="container">
                                <div className="row-1">
                                    <span><FontAwesomeIcon icon={faVideo}/></span>
                                    <h1>
                                        <img id="logo" src={Logo} alt="MVision Logo" height={28} />
                                        <br/>Knowledge<br/>Library</h1>
                                    <hr/>
                                </div>
                                <div className="row-2">
                                    <div className='content-type-container'>
                                    {this.state.types.map((type, index)=>
                                        <div
                                            onClick={event =>  window.location.href='/inspire/library?type='+type.id}
                                            key={'type-'+index}
                                            className='content-type-button-wrapper'>
                                            <div className='content-type-button'>
                                                <div className='icon'><FontAwesomeIcon icon={faScroll}/></div>
                                                <span>{type.name.toUpperCase()}</span>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                                <div className='row-3'>
                                    {this.state.content.map((content, index)=>
                                        <div key={'content-'+index} className="row-31">
                                            <div className='library-content-container'>
                                                <img src={content.thumbnail}/>
                                                <h1>{content.title}</h1>
                                                <i>by {content.author}</i>
                                                <div>
                                                    <div>
                                                        <span>{content.vote_count}</span>&nbsp;
                                                        <div><FontAwesomeIcon icon={faThumbsUp}/></div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row-4">
                                    <button
                                        onClick={event =>  window.location.href='/inspire/library'}
                                        className="mv-btn mv-light-gray no-border purple-grad btn-sm">
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        View
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default InspirePage;