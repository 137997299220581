import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';

import "./button.css";

function toggleToolTipVisibility(e) {
  let target = e.currentTarget;
  let tooltip = target.childNodes[0];
  let current_view = tooltip.style.display;
  tooltip.style.display = current_view === "block" ? "none" : "block";
}

function CircularButton(props) {
  return (
    <div
      action={props.action}
      className={"circular-button "+props.className}
      onClick={props.onClick}
      style={{
        backgroundColor: props.buttonColor,
      }}
      onMouseEnter={props.tooltip ? toggleToolTipVisibility : null}
      onMouseLeave={props.tooltip ? toggleToolTipVisibility : null}
    >
      {props.tooltip ? (
        <label className="circular-button-tooltip">{props.tooltip}</label>
      ) : null}
      <FontAwesomeIcon
        color={props.textColor}
        fontSize={props.fontSize}
        icon={props.icon}
      />
    </div>
  );
}

export default CircularButton;
