import React, { useState, useEffect } from 'react';
import { uploadFile } from "../../global/global-functions";

function AvatarUpload(props) {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    clearInputFile();
  }, []);

  const clearInputFile = ()=>{
    let f = document.getElementById('avatar-upload');
    try{
      f.value = 'xx';
    }catch(err){ }
    if(f.value){
      var form = document.createElement('form'),
          parentNode = f.parentNode, ref = f.nextSibling;
      form.appendChild(f);
      form.reset();
      parentNode.insertBefore(f,ref);
    }
  }


  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const response = await uploadFile(formData, '/user/avatar');
      
      if (response.ok) {
        console.log('File uploaded successfully');
        window.location.reload(false);
      }
      else{
        console.error(response)
      }
      clearInputFile();
    }
  };

  const handleFileChange = (event) => {
    setErrorMessage('');

    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size / 1024; // Convert to KB

      if (!fileType.startsWith('image/')) {
        setErrorMessage('Please select an image file.');
        clearInputFile();
      } else if (fileSize > 1024) {
        setErrorMessage('File size exceeds 1MB.');
        clearInputFile();
      } else {
        setErrorMessage('');
        setFile(selectedFile);
        setTimeout(() => {
          document.getElementById('upload-button').click();
        }, 200);
      }
    }
  };

  return (
    <div className="avatar-upload">
      <input id='avatar-upload' type="file" accept="image/*" onChange={handleFileChange} />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button type='button' id='upload-button' onClick={handleUpload}>
        Upload
      </button>
    </div>
  );
}

export default AvatarUpload;
