import React from "react";
import { Navigate } from "react-router-dom";
import "./landing.css";
import Logo from "./Logo_V2.png";
import {
    faEye, faLaptop, faLightbulb, faChevronCircleLeft, faChevronCircleRight, faBars
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "nuka-carousel"
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import {removeClass} from '../../global/global-functions'


class SchoolsPage extends React.Component{

    render(){
        return (
            <div id="whole">
                <div>
                    <div className="topnav" id="myTopnav">

                        <a className="home" href="/"><img id="logo" src={Logo} alt="MVision Logo" height={60} /></a>
                        <a href="/login" id="login">Login</a>
                        <Dropdown className="landing-links">
                            <DropdownTrigger><FontAwesomeIcon icon={faBars}/></DropdownTrigger>
                            <DropdownContent>
                                <ul>
                                    <li><a href="/#product">Our Product</a></li>
                                    {/* <li><a href="/schools">For Schools</a></li> */}
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                </ul>
                            </DropdownContent>
                        </Dropdown>
                        <a href="/contact">Contact Us</a>
                        <a href="#about">About Us</a>
                        <a href="#schools" className="active">For Schools</a>
                        <a href="/#product">Our Product</a>
                        <a href="javascript:void(0);" className="icon" onclick="myFunction()">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div id="schools">
                    <div id="header">
                        <br/>
                        <h1>For Schools</h1>
                        <br/>
                        <h2>
                            Empower your students to develop clear life direction, taking actionable<br/> steps
                            toward meaningful achievement &nbsp;
                            <span className="icon"><FontAwesomeIcon icon={faLightbulb}/></span>
                        </h2>
                        <br/>
                    </div>
                    <div id="school-section-container">
                        <br/>
                        <Carousel
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleRight}/>
                                </button>
                            )}
                            wrapAround>
                            <div className="school-section">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEye}/>
                                </div>
                                <div className="text">
                                    <h1>Develop Life Vision</h1>
                                    <h2>
                                        Provide students with a central location to 
                                        organize personal ideas,  
                                        goals and to track achievements
                                    </h2>
                                </div>
                            </div>
                            <div className="school-section">
                                <div className="text">
                                    <h1>Data-Driven Achievement</h1>
                                    <h2>
                                        Students can view actionable metrics to boost 
                                        productivity over time and achieve more!
                                    </h2>
                                </div>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLaptop}/>
                                </div>
                            </div>
                            <div className="school-section">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLightbulb}/>
                                </div>
                                <div className="text">
                                    <h1>Inspire New Thoughts</h1>
                                    <h2>
                                        With curated and recommended content via video 
                                        libraries, recommended books, quotes, 
                                        online courses, and podcasts, 
                                        students can discover new passions
                                    </h2>
                                </div>
                            </div>
                        </Carousel>
                        <br/>
                        <br/>
                        <br/>
                        
                    </div>
                    <br/>
                    <br/>
                    <div id="actions">
                        {/* <a id="button1" href="/about">Learn more</a> */}
                        <a id="button2" href="/contact">Learn More</a>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default SchoolsPage;