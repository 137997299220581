import {
  faPen,
  faTrashCan,
  faAdd,
  faEllipsisVertical,
  faPlusSquare,
  faUndo,
  faFloppyDisk,
  faCakeCandles,
  faChevronUp,
  faChevronDown,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autosize from "autosize";
import React from "react";
import { entryPathToArray, entryCompleted, toMysqlDate } from "../../../global/global-functions";
import CircularButton from "../../../reuseables/button/circularbutton";
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';

import EmojiPicker from "./emoji-picker";
import HeaderHelp from "./header-help";
import "./content.css";
import "./new-content.css";

import stonePic from '../../../assets/stone.png';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import $ from 'jquery';

import { Quill } from 'react-quill'
var Embed = Quill.import('blots/block/embed')
class Hr extends Embed {
    static create(value) {
        return super.create(value)
    }
}


const MAX_LEVEL = 7;
const MODES = Object.freeze({
  edit: Symbol("edit"),
  add: Symbol("add"),
});

const WEIGHTS = [1, 3, 5];

const SECTION_WEIGHT_OPTIONS = { width: "95%", margin: "0 auto" };

class MyVisionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      modeID: null,
      bigWin: 0,
      stepingStone: 0,
      editingSection: 0,
      batchChanges: []
    };

    this.showEntryOption = this.showEntryOption.bind(this);
    this.hideEntryOption = this.hideEntryOption.bind(this);
    this.editEntry = this.editEntry.bind(this);
    this.editEntryAPICall = this.editEntryAPICall.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.discardChangesEdit = this.discardChangesEdit.bind(this);
    this.discardChangesAdd = this.discardChangesAdd.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.addEntryAPICall = this.addEntryAPICall.bind(this);
    this.userInput = this.userInput.bind(this);
    this.changeWeight = this.changeWeight.bind(this);
    this.addSectionEntry = this.addSectionEntry.bind(this);
    this.cancelSectionEdit = this.cancelSectionEdit.bind(this);
    this.current_changes = {
      original_value: null,
      current_value: null,
    };
    this.quillChanges = [];


  }

  componentDidUpdate() {
    let id = this.props.addID;
    if (id) {
      autosize(document.querySelector(`#input-${id}`));
    }

    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
      tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
      this.style.height = 0;
      this.style.height = (this.scrollHeight) + "px";
    }
  }

  componentDidMount(){
    Hr.blotName = 'hr'
    Hr.className = 'hr'
    Hr.tagName = 'hr'
    Quill.register({
        'formats/hr': Hr,
    })
  }

  changeWeight(e) {
    this.current_changes["current_value"]["entry_weight"] = parseInt(
      e.currentTarget.value
    );
  }

  weightOptions(data, is_add) {
    let data_weight = data["entry_weight"];
    let id = data["entry_id"];
    return (
      <div
        style={{
          display: "flex",
          marginLeft: data["entry_level"] == 1 || is_add ? "-3%" : "-8%",
        }}
      >
        {WEIGHTS.map((weight, index) => {
          let view = weight == 0 ? "informational" : `${weight} weight`;
          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8rem",
              }}
            >
              <input
                style={{ width: "unset" }}
                type="radio"
                value={weight}
                name={"weights-" + id}
                defaultChecked={weight == data_weight}
                onChange={this.changeWeight}
              />
              <label htmlFor={"weights-" + id}>{view}</label>
            </div>
          );
        })}
      </div>
    );
  }


  showEntryOption(e) {
    let target = e.currentTarget;
    target.childNodes[1].childNodes[0].style.display = "flex";
  }

  hideEntryOption(e) {
    let target = e.currentTarget;
    target.childNodes[1].childNodes[0].style.display = "none";
  }

  userInput(e) {
    this.current_changes["current_value"]["entry"] = e.currentTarget.value;
    this.updateBatchChanges(this.current_changes["current_value"]);
    // this.updateInputOptionsButtons(this.current_changes["current_value"].entry_id, false);
  }

  addSectionEntry() {
    let id = "section-add";
    let input_container = document.getElementById(id);
    this.current_changes["current_value"] = {
      entry: "",
      entry_path: "/",
      entry_level: 1,
      entry_weight: 0,
      children: {},
    };
    input_container.classList.remove("hide-container");
    input_container.scrollIntoView({ behavior: "smooth", block: "end" });
    this.setState({ mode: MODES.add, modeID: id });
  }

  addEntry(e, my_info) {
    let target = e.currentTarget;
    let parent_container =
      target.parentElement.parentElement.parentElement.nextSibling;
    let input_container =
      parent_container.childNodes[parent_container.childNodes.length - 1];
    let id = input_container.id;
    let level = entryPathToArray(my_info["entry_path"]).length + 2;
    this.current_changes["current_value"] = {
      entry: "",
      entry_path: my_info["entry_path"] + `${my_info["entry_id"]}/`,
      entry_level: level,
      entry_weight: 0,
      children: level == MAX_LEVEL ? null : {},
    };
    input_container.classList.remove("hide-container");
    parent_container.scrollIntoView({ behavior: "smooth" });
    input_container.querySelector('textarea, input').focus();
    this.setState({ mode: MODES.add, modeID: id });
  }

  addEntryAPICall() {
    this.props.addFunction(this.current_changes["current_value"]);
    this.discardChangesAdd();
  }

  editEntry(e, my_info) {
    let target = e.currentTarget;
    let input = target.parentElement.parentElement.parentElement.childNodes[0];
    let id = input.id.split("-")[1];
    input.classList.add("entry-input-edit-mode");
    input.classList.remove("entry-input-view-mode");
    this.current_changes["original_value"] = my_info;
    this.current_changes["current_value"] = Object.assign({}, my_info);
    this.setState({ mode: MODES.edit, modeID: id });
  }

  editEntryAPICall() {
    this.props.editFunction(parseInt(this.state.modeID), this.current_changes);
    this.discardChangesEdit({}, true);
  }

  deleteEntry(id, path) {
    this.props.deleteMode(id, path);
  }

  deleteFromChangesBatch(id){
    let batchChanges = this.state.batchChanges;
    const index = batchChanges.findIndex(i=>i.entry_id==id);
    if(index > -1){
      batchChanges.splice(index, 1);
      this.setState({batchChanges});
    }
  }
  upsertBatchChanges(entry, quill){
    let batchChanges = this.state.batchChanges;
    const index = batchChanges.findIndex(i=>i.entry_id==entry.entry_id);
    if(index > -1){
      batchChanges[index].entry = entry.entry;
    }
    else{
      batchChanges.push(entry);
    }
    this.setState({batchChanges}, ()=>{
      if(quill) this.showQuillToolbar(entry.entry_id);
    });
  }

  quillUpdateBatchChanges(current_value, content, id){
    this.updateBatchChanges(current_value);
    // let input = document.querySelector('#input-'+id);
    // input.querySelector('.ql-editor p').innerHTML = content;
  }
  updateBatchChanges(entry, quill=false){
    if(entry.entry == entry.originalValue){
      this.deleteFromChangesBatch(entry.entry_id);
      return;
    }
    this.upsertBatchChanges(entry, quill);
  }

  discardChangesEdit(event, success = false) {
    if(!success){
      try {
        const originalEntry = this.current_changes['original_value'].entry;
        if(this.current_changes['current_value'].entry != originalEntry){
          this.current_changes['current_value'].originalEntry = originalEntry;
          this.updateBatchChanges(this.current_changes['current_value']);
        }        
      } catch (error) {
        console.error(error);
      }
    }
    this.setState((prevState) => {
      let input = document.getElementById(`input-${prevState.modeID}`);
      try {
        input.classList.remove("entry-input-edit-mode");
        input.classList.add("entry-input-view-mode");
      } catch (error) {
        
      }
      // if (!success) input.value = input.defaultValue;
      this.current_changes["original_value"] = null;
      this.current_changes["current_value"] = null;
      return { mode: null, modeID: null };
    });
  }

  discardChangesAdd() {
    this.setState((prevState) => {
      let input = document.getElementById(`${prevState.modeID}`);
      input.childNodes[0].value = "";
      input.classList.add("hide-container");
      this.current_changes["current_value"] = null;
      let editingSection = this.state.editingSection;
      if (this.state.editingSection == 'section-add') {
        editingSection = 0;
      }
      return { mode: null, modeID: null, editingSection };
    });
  }

  editSection(editingSection) {
    this.cancelOpenEdit();
    this.setState({ editingSection })
  }
  prepareEdit(element, id, section) {
    if(id == this.state.modeID && section == this.state.editingSection){
      return;
    }
    this.cancelOpenEdit();
    if (id == this.state.modeID || section != this.state.editingSection) {
      return;
    }
    try {
      setTimeout(() => {
        let target = element.target.parentElement;
        target = target.querySelector('div.circular-button[action=edit]');
        target.click();
      }, 100);
    } catch (error) { }

  }
  cancelOpenEdit(except) {
    if(except==this.state.modeID){
      return;
    }
    try {
      this.triggerCircularButtonFromIputId(this.state.modeID, 'cancel');
    } catch (error) { }
  }
  triggerCircularButtonFromIputId(input, button) {
    let target = this.inputFromId(input);
    target = target.parentElement.querySelector(`div.circular-button[action=${button}]`);
    target.click();
  }
  legacy_triggerCircularButtonFromIputId(input, button) {
    let target = this.inputFromId(input);
    target = target.parentElement.querySelector(`div.circular-button[action=${button}]`);
    target.click();
  }
  inputFromId(id){
    return document.querySelector('#myvision-content #input-' + id);
  }
  sectionOptions(entry) {
    const show = this.state.editingSection == 0;
    return (
      <div className="section-options-wrapper">
        {show && <>
          <CircularButton
            className="mvision-gradient"
            action="edit"
            icon={faPen}
            buttonColor="transparent"
            textColor="#a407b9"
            fontSize="0.8rem"
            onClick={() => {this.editSection(entry.entry_id)}}
          />
          <CircularButton
            action="delete"
            icon={faTrashCan}
            buttonColor="transparent"
            textColor="#f0807f"
            fontSize="0.8rem"
            onClick={() => {this.deleteEntry(entry.entry_id, entry.entry_path)}}
          />
        </>
        }
      </div>
    );
  }
  contentOptions(id, entry) {
    let childrenCount = 0;
    try {
      childrenCount = Object.keys(entry.children).length;
    } catch (error) {}
    const canAdd = entry.entry_level < 4 && !entry.big_win;
    const canAddSS = entry.big_win && entry.entry_level == 4;

    let changes = this.state.batchChanges.filter(e=>e.entry_id==parseInt(id));
    return (
      <div className="input-options-wrapper">
        <Dropdown className="input-options" id={'input-options-'+id}>
          <DropdownTrigger onClick={()=>{
              this.cancelOpenEdit(id);
            }}>
            <div>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
              />
            </div>
          </DropdownTrigger>
          <DropdownContent>
            {canAdd &&
            <div className="add option">
              <button
                disabled={!canAdd}
                onClick={()=>{
                  this.setState({modeID: 'l'+id})
                }}>
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faAdd}
                    color="purple"/>
                </div>
                <span>Add child entry</span>
              </button>
            </div>
            }
            {canAddSS == true &&
              <div className="add option">
                <button
                  onClick={()=>{
                    this.setState({modeID: 'ss'+id, bigWin: 1, stepingStone: 1})
                  }}>
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="purple"/>
                  </div>
                  <span>Add steping stone</span>
                </button>
              </div>
            }
            <div className="delete option">
              <button
                onClick={()=>{
                  this.props.deleteMode(id, entry.entry_path)
                }}>
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    color="purple"/>
                </div>
                <span>Remove entry</span>
              </button>
            </div>
            <hr/>
            <div className={"save option"}>
              <button
                onClick={()=>{this.saveSelectionEntries(parseInt(id))}}
                disabled={changes.length < 1}>
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    color="purple"/>
                </div>
                <span>Save changes</span>
              </button>
            </div>
            <div className={"undo option"}>
              <button
                onClick={()=>{this.preUndoEntryChanges(changes[0])}}
                disabled={changes.length < 1}>
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faUndo}
                    color="purple"/>
                </div>
                <span>Undo changes</span>
              </button>
            </div>
          </DropdownContent>
        </Dropdown>
      </div>
    );

  }

  preUndoEntryChanges(content){
    this.undoEntryChanges(content);
    this.deleteFromChangesBatch(content.entry_id);
    this.updateInputOptionsButtons(content.entry_id);

  }
  preSaveEntryChanges(id){
    this.triggerCircularButtonFromIputId(id, 'save');
    this.deleteFromChangesBatch(id);
    this.updateInputOptionsButtons(id, true);
  }
  updateInputOptionsButtons(id, saved=true){
    try {
      if(saved){
        document.querySelector('#input-'+id).classList.remove('not-saved');
      }
      else {
        document.querySelector('#input-'+id).classList.add('not-saved');
      }
    } catch (error) {
      console.error(error);
    }
    let target = document.querySelector('#input-options-'+id+' .option.save');
    if(saved) target.classList.add('disabled');
    else target.classList.remove('disabled');
    target = document.querySelector('#input-options-'+id+' .option.undo');
    if(saved) target.classList.add('disabled');
    else target.classList.remove('disabled');
  }

  showTextToolbar(id, section){
    const editor = document.querySelector('#input-'+id+" .ql-editor");
    const editable = editor.attributes.contenteditable.value;
    if(editable!='true'){
      return;
    }
    this.prepareEdit({target: document.getElementById('input-'+id)}, id, section);
    document.getElementById('input-'+id).classList.add("show-toolbar")
  }

  hideTextToolbar(id){
    document.getElementById('input-'+id).classList.remove("show-toolbar")
  }  
  quillInput(content, id) {
    if(this.state.editingSection == 0){
      return;
    }
    if(this.state.modeID != id){
      return;
    }
    this.current_changes["current_value"]["entry"] = content;
  }
  preDelete(section){
    this.cancelOpenEdit();
    this.triggerCircularButtonFromIputId(section, 'delete');
  }
  cancelSectionEdit(){
    this.state.batchChanges.forEach(e=>{
      this.undoEntryChanges(e);
      // this.current_changes["current_value"] = e;
      // this.current_changes["current_value"]["entry"] = 'hola';
      // this.props.content.sections[(e.entry_id)].entry = e.originalEntry;
    });
    // this.props.content.sections[1].entry = 'hola';
    this.setState({batchChanges: []});
    this.editSection(0);
    this.hideQuillToolbar(0);

    // this.props.discardSectionChanges(this.batch_changes);
  }
  undoEntryChanges(content){
    let input = document.querySelector('#input-'+content.entry_id) ||
      document.querySelector('#quill-'+content.entry_id);
    if(input.classList.contains('quill')){
      input.querySelector('.ql-editor p').innerHTML = content.originalEntry;
    }
    else{
      input.value = content.originalEntry;
    }
  }
  saveSelectionEntries(id = -1){
    this.state.batchChanges.forEach(e=>{
      if(id > -1 && id!=e.entry_id){
        return;
      }
      const current = Object.assign({}, e);
      e.entry = e.originalValue;
      const original = e;
      this.saveSingleEntry(current, original);
    });
    if(id>-1){
      this.deleteFromChangesBatch(id);
    }
    else{
      this.setState({batchChanges: []});
      // this.batch_changes=[];
    }
  }

  saveSingleEntry(current, original){
    this.props.editFunction(parseInt(current.entry_id), {
      current_value: current,
      original_value: original,
    })
  }

  inputUpdated(input, entry){
    entry.originalValue = entry.entry;
    entry.entry = input.currentTarget.value;
    this.updateBatchChanges(entry);
  }

  quillUpdated(html, args, entry, ref){
    if(args[1]!='user'){
      return;
    }
    try {
      let quill = ref.current;
      quill = quill.getEditor();
      const shortcut = this.quillShorcuts(html);
      if(shortcut){
        const retain = args[0].ops[0].retain;
        html = shortcut;
        setTimeout(() => {
          quill.setSelection(retain);
        }, 100);
      }
    } catch (error) {
    }
    html = html.replace(/background-color.*?;/g, "");
    entry.originalValue = entry.entry;
    entry.entry = html;
    if(entry.entry_id) this.updateBatchChanges(entry, true);
  }
  quillShorcuts(html){
    const shortcuts = [
      {s: '---', r: '<hr>'},
      {s: '-&gt; ', r: '➡	'},
    ]
    for (let index = 0; index < shortcuts.length; index++) {
      const shortcut = shortcuts[index];
      const start = html.indexOf(shortcut.s);
      if(start>-1){
        html = html.replace(shortcut.s, shortcut.r);
        return html;
      }
    }
    return false;
  }

  showQuillToolbar(id){
    const selector = '#quill-'+id+" .ql-editor";
    let editable = '';
    try {
      editable = document.querySelector(selector).attributes.contenteditable.value;
    } catch (error) {
    }
    if(editable!='true'){
      return;
    }
    document.getElementById('quill-'+id).classList.add("show-toolbar")
    // document.getElementById('quill-'+id).classList.add("show-toolbar")
  }
  hideQuillToolbar(id=false){
    try {
      if(id){
        document.getElementById('quill-'+id).classList.remove("show-toolbar");
      }
      else{
        document.querySelector('.quill-container.show-toolbar').classList.remove("show-toolbar");
      }
    } catch (error) {
      
    }
  }
  toggleEntrySwitch(entry, field){
    const value = entry[field] ? 0 : 1;
    const _entry = {
      entry_id: entry.entry_id,
      entry_path: entry.entry_path
    }
    let update = {};
    update[field] = value;
    if(field=='completed'){
      update.completed_date = value==1 ? toMysqlDate():null;
    }
    this.props.patchEntry(_entry, update)
  }
  toggleEntryCompleted(entry){
    if(!entry.completed){
      entryCompleted(entry.entry_id, entry.entry_level);
      const timeout = 2000;
      setTimeout(() => {
        this.toggleEntrySwitch(entry, 'completed');
      }, timeout);
    }
    else{
      this.toggleEntrySwitch(entry, 'completed');
    }
  }
  toggleEntryBigWin(entry){
    this.toggleEntrySwitch(entry, 'big_win');
  }
  newStepingStone(id, parent, section){
    if(this.state.modeID != id){
      return null;
    }
    return (
      <div
        className="entry-input-container new-entry-container"
        style={{display: 'grid', gridTemplateColumns: '100%'}}
        id={id}>
        {this.quillComponent(
          id, {}, section
        )}
        <div className="section-edit-options-wrapper">
          <button
            onClick={()=>{
              this.saveNewFromQuill(id, parent)}}
            className="mv-btn secondary btn-sm">Add</button>
          <button
            onClick={()=>{this.setState({modeID: null, bigWin: 0})}}
            className="mv-btn secondary btn-sm">Cancel</button>
        </div>
      </div>
    );
  }
  quillComponent(id, entry, section){
    const modules = {
      toolbar: {
        container: '#toolbar'+id,
      },
      clipboard: {
        matchVisual: false,
      }
    }
    const notSaved = this.state.batchChanges.map(i=>i.entry_id).includes(parseInt(id));
    const isBigWin = entry.big_win == 1;
    const isCompleted = entry.completed == 1;
    const ref = React.createRef();

    return (
      <div
        id={"quill-"+id}
        className={"quill-container "+(notSaved?'not-saved':'')}>
        <div id={"toolbar"+id} className="ql-toolbar ql-snow">
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-link"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-indent" value="-1"></button>
            <button className="ql-indent" value="+1"></button>
            {isBigWin?
              <button
                title={"Mark as "+(isCompleted?"not":"")+" completed"}
                className={"ql-completed "+(isCompleted?"completed":"")}
                onClick={()=>{this.toggleEntryCompleted(entry)}}>
                {entry.entry_level == 4 ? '🎉':
                  <img src={stonePic} height={20} style={{verticalAlign: 'middle'}}/>
                }
              </button>
            :
              <button
                title="Make this a big win"
                onClick={()=>{this.toggleEntryBigWin(entry)}}>🎂</button>
            }
          </span>
        </div>
        <ReactQuill
          ref={ref}
          className={"quill-container "+(notSaved?'not-saved':'')}
          modules={modules}
          theme="snow"          
          value={entry.entry}
          onChange={(html, ...args)=>{this.quillUpdated(html, args, entry, ref)}}
          readOnly={!(this.state.editingSection == section)}
          onFocus={()=>{this.showQuillToolbar(id)}}
          onBlur={()=>{this.hideQuillToolbar(id)}}
        />
      </div>
    );

  }

  bigWinComponent(entry){
    if(!entry.big_win){
      return null;
    }
    const classes = [
      'big-win-icon',
      entry.completed ? 'completed': ''
    ]
    return (
      <div id={'big-win-'+entry.entry_id} className={classes.join(' ')}>🎂</div>
      );
    }
  stepingStoneComponent(entry){
    if(!entry.big_win){
      return null;
    }
    const classes = [
      'ss-win-icon',
      entry.completed ? 'completed': ''
    ]
    return (
      <div id={'steping-stone-'+entry.entry_id} className={classes.join(' ')}>
        <img src={stonePic} height={24}/>
      </div>
    );
  }

  updateIcon(entry, icon){
    this.props.patchEntry(entry, {icon});
  }

  saveNewFromQuill(id, parent){
    const selector = '#quill-'+id+" .ql-editor";
    const editor = document.querySelector(selector);
    const  entry = {
      entry: editor.innerHTML,
      entry_path: parent.entry_path + '' + parent.entry_id + '/',
      entry_level: parent.entry_level + 1,
      entry_weight: 0,
      big_win: this.state.bigWin,
      children: {},
    };
    this.props.addFunction(entry);
    this.setState({modeID: null, bigWin: 0, stepingStone: 0});
  }

  saveNewFromInput(id, parent){
    if(parent.entry_path==''){
      const staticId = document.querySelector('input[name="new-section-option"]:checked').value;
      parent.entry_id = '/'+staticId;
    }
    const editor = document.getElementById('input-'+id);
    const  entry = {
      entry: editor.value,
      entry_path: parent.entry_path + '' + parent.entry_id + '/',
      entry_level: parent.entry_level + 1,
      entry_weight: 0,
      children: {},
    };
    this.props.addFunction(entry);
    this.setState({modeID: null});
  }

  resetEditionMode(){
    this.setState({
      editingSection: 0,
      modeID: null,
    })
  }

  orderedSectionsByIndex(sections){
    let order = [];
    let sectionIds = [];
    try {
      sectionIds = Object.keys(sections);
    } catch (error) {
      return [];
    }
    order = sectionIds.map(id=>({id, group: sections[id].entry_path, index:sections[id].index||sections[id].entry_id}));
    order.sort((a,b)=>a.index-b.index);
    order.sort((a,b)=>{
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });
    return order.map(section=>section.id);
  }

  ungroup(sections){
    let newSections = {};
    let groupIds = [];
    try {
      groupIds = Object.keys(sections);
    } catch (error) {
      return [];
    }
    for(const groupId of groupIds){
      const group = sections[groupId];
      const sectionIds = Object.keys(group.children);
      for(const sectionId of sectionIds){
        const section = group.children[sectionId];
        newSections[sectionId] = section;
      }
    }
    return newSections;
  }

  prepareNewSection(){
    this.setState({ editingSection: 'section-add', modeID: 'section-add' });
    setTimeout(() => {
      document.querySelector('input[name="new-section-option"]').checked=true;
    }, 100);
  }

  getGroups(sections){
    let groups = [];
    let groupIds = [];
    try {
      groupIds = Object.keys(sections);
    } catch (error) {
      return [];
    }
    for(const groupId of groupIds){
      const group = sections[groupId];
      groups.push({title: group.entry, value: group.entry_id})
    }
    return groups;
  }

  collapseToggle(e){
    const section = $(e.currentTarget).closest('.section-container-wrapper ');
    section.find('.subsection-div').slideToggle(300);
    section.find('button.collapse').toggleClass('on');
  }
  getVisionHeight(){
    const table = document.querySelector('#myvision-content');
    const bar = document.querySelector('#nav-bar');
    try {
      return window.innerHeight - table.offsetTop - bar.offsetHeight - 20;
    } catch (error) {
      // return 0;
    }
  }
  setVisionHeight(){
    const table = document.querySelector('#myvision-content');
    const bar = document.querySelector('#nav-bar');
    try {
      const height = (window.innerHeight - table.offsetTop - bar.offsetHeight - 20) + 'px';
      table.style.height = height;
    } catch (error) {
      setInterval(() => {
        this.setVisionHeight();
      }, 300);
    }
  }

  render() {
    let content = this.props.content;
    let mode = this.state.mode;
    let modeID = this.state.modeID;
    let not_saved = this.state.batchChanges.map(i=>i.entry_id);
    let groups = this.getGroups(content["sections"]);
    this.setVisionHeight();

    return (
      <div id="myvision-content" className="_mvision-scrollbar">
        {(() => {
          let res = [];
          let sections = this.ungroup(content["sections"])
          const order = this.orderedSectionsByIndex(sections);
          for (let sec_id of order) {
            let add_new_sec_container_id = "l" + sec_id;
            res.push(
              <div key={sec_id}
                className={"section-container-wrapper "+(this.state.editingSection == sec_id ? 'edit' : '')}>
                <div id={sec_id}>
                  <div
                    className="entry-input-container section"
                  >
                    <EmojiPicker
                      id={sec_id}
                      onEmojiClick={(emoji)=>{this.updateIcon(sections[sec_id], emoji)}}
                      className='section-icon' emoji={sections[sec_id].icon}/>

                    {this.state.editingSection == sec_id ? 
                    <input
                      className={`section-label label-input 
                      entry-input-${this.state.editingSection == sec_id ? 'edit' : 'view'}-mode 
                      ${not_saved.includes(parseInt(sec_id))?'not-saved':''}`}
                      defaultValue={sections[sec_id]["entry"]}
                      id={"input-" + sec_id}
                      onChange={(element)=>{this.inputUpdated(element,sections[sec_id])}}
                    />
                    :
                    <>
                      <span className="section-title">
                        {sections[sec_id]["entry"]}
                        <button className='collapse no-btn pointer' onClick={this.collapseToggle}>
                          <FontAwesomeIcon color="purple" className='down' icon={faChevronDown}/>
                          <FontAwesomeIcon color="purple" className='up' icon={faChevronUp}/>                          
                        </button>
                      </span>
                    </>
                    }
                    {this.contentOptions(sec_id, sections[sec_id])}
                    {this.sectionOptions(sections[sec_id])}
                  </div>
                  <div className="subsection-div">
                    {(() => {
                      let res1 = [];
                      let subsections = sections[sec_id]["children"];
                      let orderedHeaders = Object.keys(subsections).map(header=>{
                        const h = subsections[header];
                        return {
                          id: h.entry_id, index: h.index || h.entry_id
                        }
                      }).sort((a,b)=>a.index-b.index);

                      for (let o of orderedHeaders) {
                        const subsec_id = o.id;
                        let add_new_sub_sec_container_id = "l" + subsec_id;
                        res1.push(
                          <div key={subsec_id} id={subsec_id}>
                            <div
                              className="entry-input-container"
                            >
                              <EmojiPicker
                                id={subsec_id}
                                onEmojiClick={(emoji)=>{this.updateIcon(subsections[subsec_id], emoji)}}
                                className='section-icon' emoji={subsections[subsec_id].icon}/>
                              {this.state.editingSection == sec_id ? 
                              <input
                                className={`subsection-label entry-input-
                                ${this.state.editingSection == sec_id ? 'edit' : 'view'}-mode
                                ${not_saved.includes(parseInt(subsec_id))?'not-saved':''}`}
                                defaultValue={subsections[subsec_id]["entry"]}
                                id={"input-" + subsec_id}
                                // onFocus={(element) => { this.prepareEdit(element, subsec_id, sec_id) }}
                                onChange={(element)=>{this.inputUpdated(element,subsections[subsec_id])}}
                              />
                              :
                              <span className="header-title">{subsections[subsec_id]["entry"]}</span>
                              }
                              {<HeaderHelp
                                id={'help-'+subsec_id}
                                emoji={subsections[subsec_id].icon}
                                header={subsections[subsec_id].entry}
                              />}
                              {this.contentOptions(subsec_id, subsections[subsec_id])}
                            </div>

                            {(() => {
                              let res2 = [];
                              let entries =
                                sections[sec_id]["children"][subsec_id][
                                "children"
                                ];

                              for (let first_data_level_id in entries) {
                                const children = entries[first_data_level_id].children;
                                res2.push(
                                  <div
                                    id={first_data_level_id}
                                    className="entry-input-container description big_win"
                                    style={{ marginTop: "0", marginLeft: 23 }}>
                                    <div className="big-win">
                                      {this.bigWinComponent(entries[first_data_level_id])}
                                      {this.quillComponent(first_data_level_id, entries[first_data_level_id], sec_id)}
                                      {this.contentOptions(first_data_level_id, entries[first_data_level_id])}
                                    </div>
                                    {Object.keys(children).length>0 &&
                                    <div className="steping-stones">
                                      {Object.keys(children).map((e,i)=>
                                        <div key={e} id={e} className="steping-stone">
                                          {this.stepingStoneComponent(children[e])}
                                          {this.quillComponent(e, children[e], sec_id)}
                                          {this.contentOptions(e, children[e])}
                                        </div>
                                      )}
                                    </div>
                                    }
                                    {this.newStepingStone(
                                      'ss'+first_data_level_id, entries[first_data_level_id], sec_id)}
                                  </div>
                                );
                              }
                              return res2;
                            })()}
                            {(this.state.modeID == add_new_sub_sec_container_id) &&
                              <div
                                className="entry-input-container new-entry-container"
                                style={{display: 'grid', gridTemplateColumns: '100%'}}
                                id={add_new_sub_sec_container_id}>
                                {this.quillComponent(
                                  add_new_sub_sec_container_id, 
                                  {}, sec_id
                                )}
                                <div className="section-edit-options-wrapper">
                                  <button
                                    onClick={()=>{
                                      this.saveNewFromQuill(add_new_sub_sec_container_id, subsections[subsec_id])}}
                                    className="mv-btn secondary btn-sm">Add</button>
                                  <button
                                    onClick={()=>{this.setState({modeID: null, bigWin: 0})}}
                                    className="mv-btn secondary btn-sm">Cancel</button>
                                </div>
                              </div>
                            }
                          </div>
                        );
                      }
                      return res1;
                    })()}
                    {(this.state.modeID == add_new_sec_container_id) &&
                      <div
                        className="entry-input-container new-entry-container"
                        style={{display: 'grid', gridTemplateColumns: '100%'}}
                        id={add_new_sec_container_id}>
                        {/* {this.quillComponent(add_new_sec_container_id, {}, sec_id)} */}
                        <input
                          placeholder="New header"
                          className="subsection-label entry-input-edit"
                          id={"input-" + add_new_sec_container_id}
                        />
                        <div className="section-edit-options-wrapper">
                          <button
                            onClick={()=>{
                              this.saveNewFromInput(
                                add_new_sec_container_id, sections[sec_id]
                            )}}
                            className="mv-btn secondary btn-sm">Add</button>
                          <button
                            onClick={()=>{this.setState({modeID: null})}}
                            className="mv-btn secondary btn-sm">Cancel</button>
                        </div>
                      </div>
                    }
                  </div>
                  {this.state.editingSection==sec_id && 
                    <div className="section-edit-options-wrapper">
                      <button
                        onClick={()=>{this.saveSelectionEntries()}}
                        disabled={not_saved.length<1}
                        className="mv-btn secondary">Save changes</button>
                      <button
                        onClick={() => { this.cancelSectionEdit() }}
                        className="mv-btn secondary">Cancel</button>
                    </div>
                  }
                </div>
              </div>
            );
          }
          return res;
        })()}
        {(this.state.modeID == 'section-add') &&
        <div
          className="entry-input-container new-entry-container"
          style={{display: 'grid', gridTemplateColumns: '100%'}}
          id="section-add">
          <input
            placeholder="New section title"
            id="input-section-add"
            className="entry-input-edit-mode"/>
          <div className="radio-container">
          {groups.map((g,groupIndex)=>
            <label key={groupIndex}>
              <input
                key={'group-radio-'+groupIndex} type="radio"
                name="new-section-option" value={g.value}/>
              <span>{g.title}</span>
            </label>
          )}
          </div>
          <div className="section-edit-options-wrapper">
            <button
              onClick={()=>{
                this.saveNewFromInput(
                  'section-add', {
                    entry_id: '',
                    entry_path: '',
                    entry_level: 1,
                  }
                );
                this.resetEditionMode();
              }}
              className="mv-btn secondary btn-sm">Add</button>
            <button
              onClick={()=>{this.setState({modeID: null, editingSection: 0})}}
              className="mv-btn secondary btn-sm">Cancel</button>
          </div>
        </div>
        }
        <div style={{ display: 'grid', placeItems: 'center', marginBottom: 8 }}>
          <button
            disabled={mode == MODES.add || modeID == "section-add"}
            className="purple-button grad"
            onClick={() => {this.prepareNewSection()}}>
            ADD NEW SECTION
            <FontAwesomeIcon icon={faPlusSquare} />
          </button>
        </div>
      </div>
    );
  }
}

export default MyVisionContent;
