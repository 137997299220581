import React from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Routes,
  Route,
} from "react-router-dom";

import "./App.css";
import "./App2.css";
import "./responsive.css";

import LandingPage from "./routes/landing/landing";
import OurProductPage from "./routes/landing/our_product";
import SchoolsPage from "./routes/landing/schools";
import ContactPage from "./routes/landing/contact";
import AboutPage from "./routes/landing/about";
import LoginPage from "./routes/auth/login/login.js";
import ConfirmPage from "./routes/auth/confirm/confirm";
import SignUpPage from "./routes/auth/signup/signup";
import MyTools from "./routes/mytools/mytools";
import LearningManager from "./routes/mytools/learning_manager/learning-manager";
import MyMetricsPage from "./routes/mymetrics/mymetrics";
import MyVisionPage from "./routes/myvision/myvision";
import NavBar from "./routes/navbar/navbar";
import AdminNavBar from "./routes/navbar/admin-navbar";
import NotFoundPage from "./routes/not-found/404";
import ProfilePage from "./routes/profile/profile";
import StaticsPage from "./routes/profile/statics";
import ReportPage from "./routes/report/report";
import TrackPage from "./routes/template/track";
import InspirePage from "./routes/inspire/inspire";
import ShaperProfilePage from "./routes/inspire/shaper";
import KnowledgeLibrary from "./routes/inspire/library";
import TemplateListPage from "./routes/template/template-list";
import TemplateViewPage from "./routes/template/template-view";
import PlayPage from "./routes/video-series/play";
import VideoSeriesPage from "./routes/video-series/series";
import VideosPage from "./routes/video-series/videos";
import { UserProvider } from "./reuseables/user-context/user-context";
import UserAdmin from "./routes/admin/user";
import ContentAdmin from "./routes/admin/content";
import MetricsAdmin from "./routes/admin/metrics";

import { validateRole } from './global/global-functions'



class App extends React.Component {
  constructor(props) {
    super(props);
    this.RoutesWithNavBar = this.RoutesWithNavBar.bind(this);
    this.AdminNavBar = this.AdminNavBar.bind(this);
  }

  RoutesWithNavBar() {
    if(validateRole()==='admin'){
      return this.AdminNavBar();
    }
    else{
      return (
        <div>
          <NavBar />
          <Outlet />
        </div>
      );
    }
  }
  AdminNavBar() {
    if(validateRole()==='user'){
      return this.RoutesWithNavBar();
    }
    else{
      return (
        <div>
          <AdminNavBar />
          <Outlet />
        </div>
      );
    }
  }

  render() {
    return (
      <UserProvider>
        <Router>
          <Routes>
            <Route exact path="" element={<LandingPage />} />
            <Route path="landing" element={<LandingPage />} />
            <Route path="product" element={<OurProductPage />} />
            <Route path="schools" element={<SchoolsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="/" element={<this.RoutesWithNavBar />}>
              <Route path="myvision" element={<MyVisionPage />} />
              <Route path="learning_manager" element={<LearningManager />} />
              <Route path="mymetrics" element={<MyMetricsPage />} />
              <Route path="inspire" element={<InspirePage />} />
              <Route path="inspire/library" element={<KnowledgeLibrary />} />
              <Route path="inspire/shaper" element={<ShaperProfilePage />} />
              <Route path="videos" element={<VideoSeriesPage />} />
              <Route path="videos/:series_id" element={<VideosPage />} />
              <Route
                  path="videos/:series_id/:video_id"
                  element={<PlayPage />}
              />
              <Route path="templates" element={<TrackPage />} />
              <Route
                  path="templates/:track_id"
                  element={<TemplateListPage />}
              />
              <Route
                  path="templates/:track_id/:template_id"
                  element={<TemplateViewPage />}
              />
              <Route path="mytools" element={<MyTools />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="statics" element={<StaticsPage />} />
              <Route path="report" element={<ReportPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/confirm/:confirmation_token" element={<ConfirmPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="admin/" element={<this.AdminNavBar/>}>
              <Route path="user" element={<UserAdmin />} />
              <Route path="content" element={<ContentAdmin />} />
              <Route path="metrics" element={<MetricsAdmin />} />
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    );
  }
}


export default App;
