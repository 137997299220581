import React from "react";
import "./loading.css";

function LoadingModal() {
    return (
        <div id="loading">
            <div>
            <div id="page-loading-icon"></div>
            <label className="label-header">Loading ...</label>
            </div>
        </div>
    )
}

export default LoadingModal;