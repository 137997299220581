import React from "react";
import { makeAPICall, getAvatarsUrl } from "../../global/global-functions";
import "./profile.css";
import UserContext from "../../reuseables/user-context/user-context";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileMenu from './profile-menu';
import AvatarUpload from './avatar_upload'

const avatarUrlRoot = getAvatarsUrl();
class ProfilePage extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      user_info: null,
      loading: false,
      tracks: [
        {value: 0, name: 'No Track'},
        {value: 1, name: 'Corporate Titan'},
        {value: 2, name: 'Student / Academic'},
        {value: 3, name: 'General'},
        {value: 4, name: 'Entrepreneurship'},
      ]
    };

    this.newValue = this.newValue.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.cancelEditMode = this.cancelEditMode.bind(this);
    this.getImage = this.getImage.bind(this);
    this.updated_user = {};
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;
    
    if (user === null) {
      makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let user_info = response["message"];
          setUser(user_info);
          this.setState({ user_info: user_info });
        }
      });
    } else {
      this.setState({ user_info: user });
    }
  }

  updateUser(update) {
    this.setState({ loading: true });
    makeAPICall("PUT", "/user/users", update,
      (haserror, response) => {
        if (haserror) {
          this.cancelEditMode();
        } else {
          let setUser = this.context.setUser;
          let user = this.state.user_info;

          for (let key in update) {
            user[key] = update[key];
          }
          setUser(user);
        }
      }
    );
    this.setState({ loading: false, isEditMode: false });
  }

  getImage() {
    document.getElementById("profile_picture").click();
  }

  newValue(e) {
    let target = e.currentTarget;
    let value = target.value;
    let key = target.name;
    this.updated_user[key] = value;
  }

  cancelEditMode() {
    for (let key in this.updated_user) {
      let input = document.getElementById(key);
      input.value = input.defaultValue;
    }
    this.updated_user = {};
    this.setState({ isEditMode: false });
  }

  avatar(info){
    if(!info)
      return (<></>);
    if (info["profile_picture"]) {
      return (
        <img src={avatarUrlRoot+info["profile_picture"]} alt="user icon" />
      );
    }
    return (
      <label className="no-avatar" style={{backgroundImage: 'url(/logo_white.png)'}}>
        <span>
          <br/>
          <br/>
          {info["first_name"][0] + info["last_name"][0]}
        </span>
      </label>
    );
  }

  preupdateUser(form){
    form.preventDefault();
    const button = form.target.querySelector('button[type=submit]');
    button.disabled = true
    const updated_user = Object.fromEntries(new FormData(form.target));
    this.updateUser(updated_user);
    setTimeout(() => { button.disabled = false;  }, 200);
  }
  clickUpdateAvatar(){
    document.getElementById('avatar-upload').click();
  }

  render() {
    let user_info = this.state.user_info;
    if(!user_info){
      return null;
    }
    const preupdateUser = this.preupdateUser.bind(this);
    return (
      <div id="profile-container">
        <div className="parent0">
          <ProfileMenu
            current='profile'
            avatar={this.avatar(user_info)}
            userInfo={user_info}
          />
          <div className="profile-content">
            <h2>ACCOUNT SETTINGS</h2>
            <form onSubmit={preupdateUser}>
              <div className="personal-container">
                <div className="personal">
                    <div className="container">
                      <h1 style={{marginBottom: 26}}>PERSONAL INFORMATION</h1>
                      <h2>Here you can edit information about your Profile and Account.</h2>
                      <div className="input-group">
                        <label>Email address</label>
                        <input readOnly={true} defaultValue={user_info?.email} type="text"/>
                      </div>
                      <div className="input-group">
                        <label>First name</label>
                        <input name="first_name" defaultValue={user_info?.first_name} type="text"/>
                      </div>
                      <div className="input-group">
                        <label>Last name</label>
                        <input name="last_name" defaultValue={user_info?.last_name} type="text"/>
                      </div>
                      <div className="input-group">
                        <label>Company or School Name</label>
                        <input name="school" defaultValue={user_info?.school} type="text"/>
                      </div>
                      <br/>
                      <button
                        type="submit"
                        className="mv-btn mv-blue btn-sm">
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        Save
                        &nbsp; &nbsp; &nbsp; &nbsp;
                      </button>
                    </div>
                </div>
                <div className="photo">
                  <div className="container">
                    <h2>PROFILE PHOTO</h2>
                    <div className="avatar">
                      {this.avatar(user_info)}
                      <button
                        onClick={()=>{this.clickUpdateAvatar()}}
                        type="button"
                        className="mv-btn mv-blue icon"
                        style={{position: 'absolute', translate: 70}}>
                        <FontAwesomeIcon icon={faCamera}/>
                      </button>
                    </div>
                    <AvatarUpload/>
                    <div className="input-group">
                      <label>Username</label>
                      <input name="username" defaultValue={user_info?.username} type="text"/>
                    </div>
                    <div className="input-group mv-form">
                      <label>Chosen Track</label>
                      <select name="chosen_track" defaultValue={user_info?.chosen_track}>
                        {this.state.tracks.map((track, i)=>
                          <option key={i} value={track.value}>{track.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="subscription">
              <div className="plan">
                <div className="card">
                  <header>
                    <span>
                      My Plan &nbsp;
                      <button className="mv-btn btn-sm purple-grad">Pro</button>
                    </span>
                    <span className="monthly">$10/mo</span>
                  </header>
                  <p>
                    More purposeful living since <span className="date">March.23.2022</span>
                  </p>
                </div>
              </div>
              <div className="billing">
                <h1>MANAGE SUBSCRIPTION</h1>
                <h2>Here you can edit information about your Profile and Account.</h2>
                <div className="input-group">
                    <label>Billing address</label>
                    <input type="text" defaultValue="xyz street"/>
                </div>
                <div className="input-group">
                    <label>Billing status</label>
                    <button
                      disabled
                      className="mv-btn mv-light-gray btn-sm">
                      Stripe
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;
