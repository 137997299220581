import React, { Component } from 'react';
import { prepareVision, stripHtml, scrollToJustAbove } from '../../global/global-functions';
import {
    faMagnifyingGlass, faTimes as closeIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './search-bar.css'

class SearchBar extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    input: '',
    vision: {
      sections: [],
      headers: [],
      descriptions: [],
    },
    result: {
      sections: [],
      headers: [],
      descriptions: [],
      show: false,
    }
  }

  componentDidMount(){
    this.prepareData();
  }

  prepareData(){
    const content = prepareVision(this.props.content);
    let vision = {
      sections: [],
      headers: [],
      descriptions: [],
    };
    for (let staticIndex = 0; staticIndex < content.length; staticIndex++) {
      const static_ = content[staticIndex];
      for (let sectionIndex = 0; sectionIndex < static_.sections.length; sectionIndex++) {
        const section = static_.sections[sectionIndex];        
        vision.sections.push(section);
        for (let headerIndex = 0; headerIndex < section.headers.length; headerIndex++) {
          const header = section.headers[headerIndex];
          vision.headers.push(header);
          for (let descriptionIndex = 0; descriptionIndex < header.descriptions.length; descriptionIndex++) {
            let description = header.descriptions[descriptionIndex];
            description.title = stripHtml(description.title);
            vision.descriptions.push(description);
          }
        }
      }
    }
    this.setState({ vision });
  }

  doFilter(value){
    const input = value;
    const vision = this.state.vision;
    let result = {
      sections: [],
      headers: [],
      descriptions: [],
      show: true
    };
    if(value !=''){
      result.sections = vision.sections.filter(entry=>entry.title.toLowerCase().includes(value.toLowerCase()));
      result.headers = vision.headers.filter(entry=>entry.title.toLowerCase().includes(value.toLowerCase()));
      result.descriptions = vision.descriptions.filter(entry=>entry.title.toLowerCase().includes(value.toLowerCase()));
    }
    else{
      result.show = false;
    }
    this.setState({input, result})
  }

  doHighlight(id, level){
    const {result} = this.state;
    result.show = false;
    this.setState({ result }, ()=>{
      this.clearHighlights();
      let element = document.getElementById(id);
      if(level===1){
        element = element.querySelector('.entry-input-container.section');
      }
      else if(level===2){
        element = element.children.item(0);
      }
      else if(level===3){
        element = element.querySelector('.ql-editor');
      }
      element.scrollIntoView({block: "start", behavior: "smooth"});
      // scrollToJustAbove(element, 20);
      this.blurInput();
      element.classList.add("highlighted");
      // setTimeout(() => {
      //   element.classList.remove("highlight-effect");
      // }, 3000);
    });
  }
  clearHighlights(){
    const elements = document.querySelectorAll('.highlighted');
    elements.forEach((element) => {
      element.classList.remove('highlighted');
    });
  }

  focusInput(value){
    const element = document.getElementById('my-vision-title');
    element.classList.add('focused');
    this.doFilter(value);
  }
  blurInput(){
    const element = document.getElementById('my-vision-title');
    element.classList.remove('focused');
  }

  resetInput(e){
    // e.target.value = '';
    document.getElementById("search-bar-input").value = "";
    this.clearHighlights();
    this.blurInput();
    let {input, result} = this.state;
    input = '';
    result.show = false;
    this.setState({input, result});
  }

  render(){
    const {result} = this.state;
    return (
      <div id='search-bar'>
        <div id='search-bar-container'>
          <div id="header-input">
						<input 
              id='search-bar-input'
              defaultValue={this.state.input}
              onChange={(e)=>{this.doFilter(e.target.value)}} 
              onFocus={(e)=>{this.focusInput(e.target.value)}} 
              // onBlur={()=>{this.blurInput()}} 
              placeholder="Enter a section or keyword, i.e. Elon Musk"/>
						<div>
							<div>
                <FontAwesomeIcon icon={closeIcon} onClick={(e)=>{this.resetInput(e)}}/>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
              </div>
						</div>
					</div>
          {result.show &&
            <div id='result-container'>
              {result.sections.length > 0 &&
                <div className='result-group' id='sections'>
                  <h3>Sections</h3>
                  <hr/>
                  <ul>
                  {result.sections.map(s=>
                    <li className='no-bullet' key={'result-section-'+s.id}><a onClick={()=>{this.doHighlight(s.id, 1)}}>
                      {String.fromCodePoint(s.icon)} {s.title}
                    </a></li>
                  )}
                  </ul>
                </div>
              }
              {result.headers.length > 0 &&
                <div className='result-group' id='headers'>
                  <h3>Headers</h3>
                  <hr/>
                  <ul>
                  {result.headers.map(h=>
                    <li className='no-bullet' key={'result-header-'+h.id}><a onClick={()=>{this.doHighlight(h.id, 2)}}>
                      {String.fromCodePoint(h.icon)} {h.title}
                    </a></li>
                  )}
                  </ul>
                </div>
              }
              {result.descriptions.length > 0 &&
                <div className='result-group' id='desciptions'>
                  <h3>Descriptions</h3>
                  <hr/>
                  <ul>
                  {result.descriptions.map(d=>
                    <li key={'result-header-'+d.id}><a onClick={()=>{this.doHighlight(d.id, 3)}}>{d.title}</a></li>
                  )}
                  </ul>
                </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default SearchBar;
