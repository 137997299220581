import { Component } from 'react';
import Chart from 'react-apexcharts';

class MyMetricsLineChart extends Component{
  constructor(props) {
    super(props);
    
    this.state = {
      options: {
        annotations: {
          yaxis:[{
            y:0
          }]
        },
        markers: {
          size: 8,
        },
        grid: {
          padding: {
            left: 32,
          }
        },
        chart: {
          id: 'apexchart-example',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.props.data.categories,
          // min: 0
        },
        yaxis:{
          // tickAmount: 4,
          min: 0,
          forceNiceScale: true,
          title: {
            text: '# of Big Wins Completed',
            style:{
              fontFamily: 'Poppins',
              fontSize: '12px',
            }
          }
        },
        colors: ['#a362c9'],
        fill: {
          type: 'gradient',
          gradient: {
            gradientToColors: ['#3C45A8']
          }
        }
      },
      series: [{
        name: '',
        data: this.props.data.values
      }]
    }
  }
  
  render(){
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="line"
        // width={500}
        height={320}
      />
    )
  }
}
export default MyMetricsLineChart;