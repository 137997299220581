import "./mytools.css";
import React, { Component } from 'react';
import {
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class MyToolsPage extends Component {
    constructor(props) {
        super(props);
		this.setFilterBy = this.setFilterBy.bind(this);
    }
    state = {
		filterBy: '',
		content: [
			{
				header: 'Propietary',
				resources: [
					{
						title: 'Learning Management System',
						subtitle: 'Track Your Learning',
						link: '/learning_manager',
					},
					{
						title: 'Weighted Career Options Algo',
						subtitle: 'Via Google Sheets',
						link: 'https://docs.google.com/spreadsheets/d/1ZiWg14SPJXilP4Ww9jnUzGUvc2MJ-MJIs_jS3uPNVOI/edit?usp=sharing',
					},
					{
						title: 'Personal Finance Budget',
						subtitle: 'Via Google Sheets',
						link: 'https://docs.google.com/spreadsheets/d/1X-F3OpT_51R3RZ2QN7Of7bZXgYYJxZOLnimMsORSugY/edit?usp=sharing',
					},
					{
						title: 'Mentor Tracker',
						subtitle: 'Via Google Sheets',
						link: 'https://docs.google.com/spreadsheets/d/1OZtj1pg4OIQLez_ssYld6bxW-OD5UQd0hG8x4eN3IkA/edit?usp=sharing',
					},
					{
						title: 'Personal Tasks Tracker',
						subtitle: 'Via Google Sheets',
						link: 'https://docs.google.com/spreadsheets/d/1zYDElhU-RwD3SYOs3A0oXBCovN6r7P9poFP-TPUyIh0/edit#gid=1916414134',
					},
					{
						title: 'Daily Planner',
						subtitle: 'Daily Sim Break Formula/Planner',
						link: '/planner.png'
					},
				]
			},
			{
				header: 'Partners',
				resources: [
					{
						title: 'Coursera',
						subtitle: 'Education',
						link: 'https://www.coursera.org/'
					},
					{
						title: 'Mint',
						subtitle: 'Personal Finance',
						link: 'https://mint.intuit.com/'
					},
					{
						title: 'Motion',
						subtitle: 'Productivity',
						link: 'https://www.usemotion.com/'
					},
					{
						title: 'Opal',
						subtitle: 'Focus Score',
						link: 'https://www.opal.so/'
					},
				]
			},
		]
	}
	filterContent(){
		let {filterBy, content} = this.state;
		content = content.map(e=>Object.assign({}, e));

		if(!filterBy){
			return content;
		}
		for (let index = 0; index < content.length; index++) {
			content[index]['resources'] = content[index]['resources'].filter(
				resources=>(
					resources.title.toUpperCase().includes(filterBy.toUpperCase()) || 
					resources.subtitle.toUpperCase().includes(filterBy.toUpperCase()))
			);
		}
		return content;
	}
	setFilterBy(event){
		const filterBy = event.currentTarget.value;
		this.setState({filterBy});
	}
	getTotal(content){
		let total = 0;
		for (let index = 0; index < content.length; index++) {
			total += content[index]['resources'].length;
		}
		return total;
	}
    render() { 
		const content = this.filterContent();
		const total = this.getTotal(content);
        return (
            <div id='my-tools-page'>
				<div className="header">
					<h1>MyTools &nbsp; &nbsp;</h1>
					<div id="header-input">
						<input onChange={this.setFilterBy} placeholder="Enter a topic"/>
						<div>
							<div><FontAwesomeIcon icon={faMagnifyingGlass}/></div>
						</div>
					</div>
				</div>
                <h3>Curated additional resources to achieve more</h3>
				<div id='tools-content'>
					{content.map((data, index)=>
					<>
						{data.resources.length > 0 &&
							<div key={'data-'+index}>
								<div className='track-header'>
									{data.header}
								</div>
								<div className='track-container'>
									{data.resources.map((resource, index)=>
									<div key={'resource-'+index} className={'track '+(index%2?'':'primary')}>
										<h1>{resource.title}</h1>
										<i>{resource.subtitle}</i>
										<button
											onClick={event =>  window.open(resource.link, '_blank')}
											className={"mv-btn btn-sm no-border "+(index%2?'purple-grad-light':'mv-light-purple')}>
											Open
										</button>
									</div>
									)}
								</div>
								<br/>
							</div>
						}
					</>
					)}
					{total < 1 && <div className='empty'>There is no available content</div>}
				</div>
            </div>
        );
    }
}
 
export default MyToolsPage;