import autosize from "autosize";
import React from "react";
import {
  depthAdd,
  entryPathToArray,
  makeAPICall,
} from "../../global/global-functions";
import QuoteCarousel from "../../reuseables/quote-carousel/quote-carousel";
import TableOfContent from "../../reuseables/table-of-content/table-of-content";
import MobileTableOfContent from "../../reuseables/table-of-content/mobile-table-of-content";
import DecisionModal from "../../reuseables/yes-no-modal/modal";
import MyVisionContent from "./content/content";
import UserContext from "../../reuseables/user-context/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "./search-bar";
import {
  faCompass,
} from "@fortawesome/free-regular-svg-icons";
import {
  faRefresh as changeTrackIcon, faIndent as wikiIcon
} from "@fortawesome/free-solid-svg-icons";

import "./myvision.css";
import NoTrackView from "./notrack/notrack";
import ReactModal from 'react-modal';

class MyVisionPage extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      user_info: null,
      deleteModeID: null,
      addID: null,
      deleteModePath: null,
      content: { section: {}, orderedSections: [], preparedData: [] },
      loadingButton: false,
      userReady: false,
      contentReady: false,
      showChangeTrackModal: false,
      selectedGroup: 0,
      tracks: [
        {value: 0, name: 'No Track'},
        {value: 1, name: 'Corporate Titan'},
        {value: 2, name: 'Student / Academic'},
        {value: 3, name: 'General'},
        {value: 4, name: 'Entrepreneurship'},
      ]
    };

    this.deleteMode = this.deleteMode.bind(this);
    this.editFunction = this.editFunction.bind(this);
    this.deleteFunction = this.deleteFunction.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.findParent = this.findParent.bind(this);
    this.sameEntry = this.sameEntry.bind(this);
    this.updateEntryField = this.updateEntryField.bind(this);
    this.patchEntry = this.patchEntry.bind(this);
    this.reorderSections = this.reorderSections.bind(this);
    this.reorderHeaders = this.reorderHeaders.bind(this);
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;

    if (user === null) {
      makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let user_info = response["message"];

          if(user_info.email=='jackpotvin50@gmail.com'){
            window.open('/admin/user','_self');
          }
          
          setUser(user_info);
          this.setState({ user_info: user_info, userReady: true });
        }
      });
    } else {
      this.setState({ user_info: user });
    }

    makeAPICall("GET", "/entry/entries", undefined, (haserror, response) => {
      if (haserror) {
        console.log(response["message"]);
      } else {
        let data = [...response["message"]];
        let content = { sections: {} };
        let sections = data.shift();

        for (let entry of sections) {
          content["sections"][entry["entry_id"]] = Object.assign({}, entry);
          content["sections"][entry["entry_id"]]["children"] = {};
        }
        try {
          content = depthAdd(data, content);
        } catch (error) {console.error(error);}
        content.orderedSections = this.orderedSections(content.sections);
        this.setState({ content: content });
      }
      this.setState({ contentReady: true });
    });
  }

  orderedSections(sections){
    let ordered = [];
    for (const key in sections) {
      if (Object.hasOwnProperty.call(sections, key)) {
        const element = sections[key];
        ordered.push({
          index: element.index || element.entry_id,
          id: element.entry_id
        })
      }
    }
    ordered.sort((a, b)=> a.index - b.index);
    return ordered;
  }

  deleteMode(id, path) {
    this.setState({ deleteModeID: id, deleteModePath: path });
  }

  sameEntry(a, b) {
    let response = a["entry"] === b["entry"];
    response = response && a["entry_weight"] === b["entry_weight"];
    response = response && a["completed"] === b["completed"];
    return response;
  }

  showTrackLabel(){
    try {
      return this.state.tracks.filter(t=>t.value === this.state.user_info.chosen_track)[0].name;
    } catch (error) {
      return this.state.tracks[0].name;
    }
  }

  editFunction(id, entry) {
    let new_entry = entry["current_value"];
    let original_entry = entry["original_value"];
    if (!this.sameEntry(original_entry, new_entry)) {
      let content = { ...this.state.content };
      this.setState({ loadingButton: true });
      setTimeout(() => {
        makeAPICall(
          "PUT",
          "/entry/entries",
          Object.assign({}, new_entry, { entry_id: id }),
          (haserror, response) => {
            if (haserror) {
              document.getElementById(`input-${id}`).value =
                original_entry["entry"];
            } else {
              let parent = this.findParent(new_entry["entry_path"], content);
              parent[id] = Object.assign({}, new_entry);
            }
            autosize(document.querySelector(`#input-${id}`));
            this.setState({
              content: content,
              loadingButton: false,
            });
          }
        );
      }, 500);
    }
  }

  deleteFunction() {
    this.setState({ loadingButton: true });
    let content = { ...this.state.content };
    setTimeout(() => {
      let id = this.state.deleteModeID;
      let path = this.state.deleteModePath;
      let body = {
        entry_id: parseInt(id),
        entry_path: path,
      };
      makeAPICall("DELETE", "/entry/entries", body, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let parent = this.findParent(path, content);
          delete parent[id];
        }
        content.orderedSections = this.orderedSections(content.sections);
        this.setState({
          content: content,
          deleteModeID: null,
          deleteModePath: null,
          loadingButton: false,
        });
      });
    }, 500);
  }

  findParent(path, content) {
    let path_array = entryPathToArray(path);
    let parent = content["sections"];
    if (path_array.length !== 0) {
      parent = parent[path_array[0]]["children"];
      for (let i = 1; i < path_array.length; i++) {
        parent = parent[path_array[i]]["children"];
      }
    }
    return parent;
  }

  _addFunction(new_entry) {
    let content = Object.assign({}, this.state.content);
    this.setState({ loadingButton: true });
    let id = null;

    setTimeout(() => {
      makeAPICall("POST", "/entry/entries", new_entry, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          id = response["message"]["id"];
          let parent = this.findParent(new_entry["entry_path"], content);
          parent[id] = Object.assign({}, new_entry, { entry_id: id });
        }
        this.setState({ content: content, loadingButton: false, addID: id });
      });
    }, 500);
  }

  addFunction(new_entry) {
    this.setState({ loadingButton: true });
    makeAPICall("POST", "/entry/entries", new_entry, (error, response) => {
      if (error) {
        console.error(response["message"]);
      } else {
        new_entry.entry_id = response["message"]["id"];
        new_entry.icon = response["message"]["icon"];
        this.addNewChild(new_entry)
      }
      this.setState({ loadingButton: false });
    });
  }

  patchEntry(entry, update) {
    makeAPICall(
      "PATCH", "/entry/entries",
      {...update, entry_id: entry.entry_id}, (haserror, response) => {
      if (haserror) {
        console.error(response["message"]);
      } else {
        this.updateEntryField(entry, update);
      }
    });
  }

  updateEntryField(entry, update) {
    let content = Object.assign({}, this.state.content);
    let parent = this.findParent(entry.entry_path, content);
    try {
      parent[''+entry.entry_id] = {...parent[''+entry.entry_id], ...update};
      this.setState({content});
    } catch (error) {
      
    }
  }

  addNewChild(child){
    let content = Object.assign({}, this.state.content);
    let parent = this.findParent(child.entry_path, content);
    try {
      parent[''+child.entry_id] = child;
      content.orderedSections = this.orderedSections(content.sections);
      this.setState({content});
    } catch (error) {
      
    }
  }

  reorderSections(groupId, fromId, toId){
    let content = this.state.content;
    let group = content.sections[groupId];
    const path = `/${groupId}/`;
    const entryFrom = {entry_id: fromId, entry_path: path};
    const entryTo = {entry_id: toId, entry_path: path};
    const fromIndex = group.children[fromId].index || fromId;
    const toIndex = group.children[toId].index || toId;
    this.patchEntry(entryFrom, {index: toIndex})
    this.patchEntry(entryTo, {index: fromIndex})
    group.children[fromId].index = toIndex;
    group.children[toId].index = fromIndex;
    this.setState({content});
  }

  reorderHeaders(groupId, sectionId, fromId, toId){
    let content = this.state.content;
    let section = content.sections[groupId].children[sectionId];
    const path = `/${groupId}/${sectionId}/`;
    const entryFrom = {entry_id: fromId, entry_path: path};
    const entryTo = {entry_id: toId, entry_path: path};
    const fromIndex = section.children[fromId].index || fromId;
    const toIndex = section.children[toId].index || toId;
    this.patchEntry(entryFrom, {index: toIndex})
    this.patchEntry(entryTo, {index: fromIndex})
    section.children[fromId].index = toIndex;
    section.children[toId].index = fromIndex;
    this.setState({content});
  }
  getWikiHeight(){
    const table = document.querySelector('#table-of-content-container');
    try {
      const height = window.innerHeight - table.offsetTop - 20;
      return height;
    } catch (error) {
      setTimeout(() => {
        return this.getWikiHeight();
      }, 300);
    }
  }


  changeTrack(chosen_track) {
    const user = {user_id: this.state.user_info.user_id, chosen_track}
    makeAPICall("PATCH", "/admin/users", user, (haserror, response) => {
      if (haserror) {
      } else {
        let user_info = this.state.user_info;
        user_info.chosen_track = chosen_track;
        this.setState({user_info});
      }
    });
  }


  changeTrackModal(){
    const closeModal = ()=>{
      this.setState({ showChangeTrackModal: false });
    }
    const changeTrack = (e)=>{
      e.preventDefault();
      const button = e.target.querySelector('button[type=submit]');
      button.disabled = true
      const track = new FormData(e.target).get("track");
      this.changeTrack(track);
      setTimeout(() => { button.disabled = false; closeModal(); }, 500);
    }
    return <ReactModal
        isOpen={this.state.showChangeTrackModal}
        id="new-framework-modal">
        <h3><FontAwesomeIcon icon={changeTrackIcon} color="#3e42a5"/>&nbsp;Change Track</h3>
        <form onSubmit={changeTrack} autoComplete="off" className="mv-form">
            <select name="track" defaultValue={this.state.user_info.chosen_track}>
              {this.state.tracks.map((track, i)=>
                <option key={1} value={track.value}>{track.name}</option>
              )}
            </select>&nbsp;
						<button type='submit' className="mv-btn btn-sm mv-blue">Save</button>
            <button className="mv-btn mv-black btn-sm" onClick={closeModal}>Cancel</button>
        </form>
      </ReactModal>
  }

  render() {
    let user_info = this.state.user_info;
    if (user_info === null || user_info.chosen_track === 0) {
      return <NoTrackView userReady={this.state.userReady} />;
    }
    const height = this.getWikiHeight();
    return (
      <div id="myvision" className="page">
        <div id="table-of-content-container" style={{height}}>
          <div style={{ margin: "0 4px"}}>
            <QuoteCarousel/>
            <hr style={{ margin: '12px 20px', borderColor: 'white'}}/>
            <div className="text-center">
              <label className="label-header-left-aligned text-center text-mvision-gradient">
                <FontAwesomeIcon icon={faCompass} color="#3e42a5"/>
                &nbsp;{user_info.first_name} LifeOS Wiki&nbsp;&nbsp;&nbsp;
              </label>
            </div>
            {this.state.contentReady &&
              <TableOfContent
                reorderSections={this.reorderSections}
                reorderHeaders={this.reorderHeaders}
                addFunction={this.addFunction}
                content={this.state.content}
              />
              }
          </div>
        </div>
        <div id="myvision-content-container">
          
          
          <div style={{ margin: "0 auto", width: "95%", height: "100%" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                <label>
                  <span style={{ fontWeight: "bold" }}>Current Track: </span>
                  <span style={{ color: "#A407B9" }}>{this.showTrackLabel()}</span>
                </label>
                <label 
                  onClick={()=>{this.setState({ showChangeTrackModal: true });}}
                  style={{ fontWeight: "bold", color: "#3C45A8", marginTop: -16, cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={changeTrackIcon} color="#3e42a5"/> 
                  &nbsp;Change Track
                </label>
              </div>
              <div id="my-vision-title">
                <label className="label-header-left-aligned text-mvision-gradient">MyVision&nbsp;&nbsp;</label>
                {this.state.contentReady &&
                  <SearchBar content={this.state.content.sections}/>
                }
              </div>
            </div>
            <hr className="horizontal-line" />
            <MobileTableOfContent content={this.state.content}/>
            {this.state.contentReady &&
              <MyVisionContent
                content={this.state.content}
                deleteMode={this.deleteMode}
                addFunction={this.addFunction}
                editFunction={this.editFunction}
                patchEntry={this.patchEntry}
                addID={this.state.addID}
                selectedGroup={this.state.selectedGroup}
              />
            }
          </div>
        </div>
        {this.state.deleteModeID ? (
          <DecisionModal
            description="Are you sure you want to delete this entry and its contents?"
            yesFunction={this.deleteFunction}
            noFunction={() => this.deleteMode(null, null)}
            loadingButton={this.state.loadingButton}
          />
        ) : null}
        {this.changeTrackModal()}
      </div>
    );
  }
}

export default MyVisionPage;
