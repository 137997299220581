import "./button.css";
import React from 'react';

function Button(props) {
  let loading = props.loading;
  return (
    <div
      className="button"
      onClick={
        loading
          ? () => {}
          : (e) => {
              props.onClick();
            }
      }
      style={{
        backgroundColor: props.buttonColor,
        color: props.textColor,
        fontSize: props.fontSize,
      }}
    >
      {loading ? (
        <div id="button-loading-icon"></div>
      ) : (
        <label
          className="button-label"
          style={{ fontWeight: props.fontWeight }}
        >
          {props.buttonName}
        </label>
      )}
    </div>
  );
}

export default Button;
