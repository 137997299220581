import React, { Component } from 'react';
import { faIndent as wikiIcon, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MobileTableOfContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.prepareData(),
    };
  }

  prepareData(){
    let sections = this.props.content.sections;
    let data = [];
    let groupKeys = [];
    try {
      groupKeys = Object.keys(sections);
    } catch (error) {
      return [];
    }
    for(let groupId of groupKeys){
      const group = sections[groupId];
      let groupData = {
        title: group.entry,
        icon: group.icon,
        id: group.entry_id,
        index: group.index || group.entry_id,
        sections: []
      }
      const sectionKeys = Object.keys(group.children);
      for(const sectionId of sectionKeys){
        const section = group.children[sectionId];
        let sectionData = {
          title: section.entry,
          icon: section.icon,
          id: section.entry_id,
          index: section.index || section.entry_id,
          headers: []
        }
        const headerKeys = Object.keys(section.children);
        for(const headerId of headerKeys){
          const header = section.children[headerId];
          sectionData.headers.push({
            title: header.entry,
            icon: header.icon,
            index: header.index || header.entry_id,
            id: header.entry_id,
          });
        }
        sectionData.headers.sort((a, b)=> a.index - b.index);
        groupData.sections.push(sectionData);
      }
      groupData.sections.sort((a, b)=> a.index - b.index);
      data.push(groupData);
    }
    data.sort((a, b)=> a.index - b.index);
    return data;
  }
  
  componentDidUpdate(prevProps){
    if (prevProps !== this.props) {
      this.updateData();
    }    
  }
  updateData(){
    const data = this.prepareData();
    this.setState({data});
  }

  closeWiki(){
    document.getElementById('wiki-mobile-container').style.display = 'none';
  }
  openWiki(){
    document.getElementById('wiki-mobile-container').style.display = 'block';
  }

  componentDidMount(){
  }


  render() {
    return (
      <>
        <button id="show-wiki" className="no-btn" onClick={()=>{this.openWiki()}}>
          <FontAwesomeIcon icon={wikiIcon} color="purple"/>
        </button>
        <div id="wiki-mobile-container">
          <div className="_mvision-scrollbar">
            <button className='no-btn' onClick={()=>{this.closeWiki()}}>
              <FontAwesomeIcon icon={faTimes} color='purple'/>
            </button>
            {this.state.data.map((group, groupIndex)=>
              <div key={'group-key-'+groupIndex}>
                <button className='mv-btn mv-blue btn-sm'>{group.title}</button>
                <div>
                {group.sections.map((item, index) => (
                  <div key={'section-key-'+index}>
                    <label>
                      <span>{String.fromCodePoint(item.icon)}</span>&nbsp;
                      <span>{item.title}</span>
                    </label>
                    <div>
                    {item.headers.map((header, index) => (
                      <div key={'section-key-'+index}>
                        <label>
                          <span>{String.fromCodePoint(header.icon)}</span>&nbsp;
                          <span><a onClick={()=>{this.closeWiki()}} href={"#" + header.id}>{header.title}</a></span>
                        </label>
                      </div>
                    ))}
                    </div>
                  </div>
                ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MobileTableOfContent;
