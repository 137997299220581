import "./modal.css";
import Button from "../button/button";
import React from 'react';

function DecisionModal(props) {
  return (
    <div id="modal" className="modal-background">
      <div id="modal-content">
        <label className="label-header">Are you sure?</label>
        <hr className="horizontal-line" />
        <div>
          <label id="modal-description">{props.description}</label>
        </div>
        <div id="modal-button-container">
          <div className="modal-button">
            <Button
              loading={props.loadingButton}
              onClick={props.yesFunction}
              buttonName="Yes"
              buttonColor="#3c45a8"
              textColor="#fffffe"
            />
          </div>
          <div className="modal-button">
            <Button
              onClick={props.noFunction}
              buttonName="No"
              buttonColor="black"
              textColor="#fffffe"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DecisionModal;
