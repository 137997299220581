import {
  faChevronUp, faChevronDown, faScroll, 
	faCheckSquare as checkedIcon,
	faSquare as uncheckedIcon,
	faPlus as addContentIcon,
	faFilter as filterIcon,
	faSortAlphaDesc as orderIcon,
	faEdit as editIcon,
	faTrash as deleteIcon,
	faEllipsisVertical as optionsIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import './learning-manager.css';
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import { removeClass } from "../../../global/global-functions";
Modal.setAppElement('#root');

const blankContent = {
	id: '',
	name: '',
	status: 0,
	type: '',
	impact: 3,
	duration: '',
	authors: [],
	notes: '',
}
export default class LearningManager extends Component {
	constructor(props) {
		super(props);
		this.newContentInput = this.newContentInput.bind(this);		
		this.state = {
			showNewContentModal: false,
			showAuthorModal: false,
			showDeleteConfirm: false,
			filter: [],
			order: {
				field: 'duration',
				order: 'asc',
			},
			types: [
				{id: -1, name: 'All'},
				{id: 1, name: 'Book'},
				{id: 2, name: 'Video'},
				{id: 3, name: 'Course'},
			],
			durations: [
				{id: 1, name: '15 mins'},
				{id: 2, name: '30 mins'},
				{id: 3, name: '45 mins'},
				{id: 4, name: '1 hour'},
				{id: 5, name: '1 day'},
				{id: 6, name: '1 week'},
				{id: 7, name: '1 month'},
			],
			_content: [],
			content: [
				{
					id: 1,
					name: 'Unlocking Creative Genius',
					status: 1,
					type: 1,
					impact: 4,
					duration: 1,
					authors: [{id: 1, name: 'Dalio'}],
					notes: 'unlock your inner genius',
					date_added: new Date()
				},
				{
					id: 2,
					"name": "The Art of Thinking Clearly",
					"status": 1,
					type: 2,
					"impact": 3,
					"duration": 2,
					authors: [{id: 2, name: 'Dobelli'}],
					"notes": "solve problems more effectively",
					date_added: new Date()
				},
				{
					id: 3,
					"name": "The 4-Hour Workweek",
					"status": 0,
					type: 2,
					"impact": 5,
					"duration": 3,
					authors: [{id: 3, name: 'Ferriss'}],
					"notes": "achieve financial freedom",
					date_added: new Date()
				},
				{
					id: 4,
					"name": "The Lean Startup",
					"status": 0,
					type: 3,
					"impact": 4,
					"duration": 4,
					authors: [{id: 4, name: 'Ries'}],
					"notes": "learn from your mistakes",
					date_added: new Date()
				},
				{
					"id": 5,
					"name": "The Power of Habit",
					"status": 1,
					"type": 1,
					"impact": 4,
					"duration": 2,
					"authors": [{
						"id": 5,
						"name": "Charles Duhigg"
					}],
					"notes": "understand and change your habits",
					"date_added": new Date()
				},
				{
					"id": 6,
					"name": "Thinking, Fast and Slow",
					"status": 1,
					"type": 1,
					"impact": 5,
					"duration": 3,
					"authors": [{
						"id": 6,
						"name": "Daniel Kahneman"
					}],
					"notes": "understand your two minds",
					"date_added": new Date()
				},
				{
					"id": 7,
					"name": "Meditations",
					"status": 0,
					"type": 1,
					"impact": 4,
					"duration": 1,
					"authors": [{
						"id": 7,
						"name": "Marcus Aurelius"
					}],
					"notes": "live a more mindful life",
					"date_added": new Date()
				},
				{
					"id": 8,
					"name": "The Alchemist",
					"status": 1,
					"type": 1,
					"impact": 5,
					"duration": 1,
					"authors": [{
						"id": 8,
						"name": "Paulo Coelho"
					}],
					"notes": "follow your dreams",
					"date_added": new Date()
				},
				{
					"id": 9,
					"name": "The Power of Positive Thinking",
					"status": 0,
					"type": 1,
					"impact": 4,
					"duration": 1,
					"authors": [{
						"id": 9,
						"name": "Norman Vincent Peale"
					}],
					"notes": "change your mindset",
					"date_added": new Date()
				},
				{
					"id": 10,
					"name": "The Seven Habits of Highly Effective People",
					"status": 1,
					"type": 2,
					"impact": 5,
					"duration": 3,
					"authors": [{
						"id": 10,
						"name": "Stephen Covey"
					}],
					"notes": "achieve personal and professional success",
					"date_added": new Date()
				},
				{
					"id": 11,
					"name": "How to Win Friends and Influence People",
					"status": 0,
					"type": 2,
					"impact": 4,
					"duration": 2,
					"authors": [{
						"id": 11,
						"name": "Dale Carnegie"
					}],
					"notes": "build better relationships",
					"date_added": new Date()
				},
				{
					"id": 12,
					"name": "The Charisma Myth",
					"status": 0,
					"type": 2,
					"impact": 5,
					"duration": 2,
					"authors": [{
						"id": 12,
						"name": "Olivia Fox Cabane"
					}],
					"notes": "develop your natural charisma",
					"date_added": new Date()
				},
				{
					"id": 13,
					"name": "The Dip",
					"status": 1,
					"type": 3,
					"impact": 4,
					"duration": 1,
					"authors": [{
						"id": 13,
						"name": "Seth Godin"
					}],
					"notes": "know when to quit",
					"date_added": new Date()
				},
			],
			newContent: Object.assign({}, blankContent),
			updating: Object.assign({}, blankContent),
			deleting: null,
			newAuthor: '',
		}
	}
	collapseToggle(){

	}
	getTypeName(id){
		try {
			return this.state.types.filter(type=>type.id===id)[0].name;
		} catch (error) {
			return '';
		}
	}
	getDurationName(id){
		try {
			return this.state.durations.filter(duration=>duration.id===id)[0].name;
		} catch (error) {
			return '';
		}
	}
	durationComponent(item){
		return <select defaultValue={item.duration} onChange={this.newContentInput} prop='duration'>
			<option>Duration</option>
			{this.state.durations.map(t=>
				<option key={t.id} value={t.id}>{t.name}</option>
			)}
		</select>;
	}
	mediaComponent(item){
		return <select defaultValue={item.type} prop='type' onChange={this.newContentInput}>
			<option value=''>Types</option>
			{this.state.types.filter(t=>t.id>-1).map(t=>
				<option key={t.id} value={t.id}>{t.name}</option>
			)}
		</select>;
	}
	statusComponent(row){
		const icon = row.status===1 ? checkedIcon : uncheckedIcon;
		const value = row.status===1 ? 0 : 1;
		return <>
			<FontAwesomeIcon onClick={()=>{
					this.setState({
						updating: {...this.state.updating, status: value}
					})
				}} className="status-icon" icon={icon}/>		
		</>;
	}
	impactComponent(row){
		const callback = (n) => {
			this.setState({
				updating: {...this.state.updating, impact: n}
			})
		}
		return <div className="stars">
			{[...Array(5).keys()].map((n)=>
				<span
					key={n}
					className={(row.impact -n > 0)?'':'off'}
					onClick={()=>callback(n+1)}>⭐</span>
			)}
		</div>
	}
	canSaveNew(){
		const {newContent} = this.state;
		return newContent.name !== '' && newContent.type !== '';
	}
	updateNewContent(prop, value){
		const parse = ['type', 'duration'].includes(prop);
		let updating = this.state.updating;
		updating[prop] = parse ? parseInt(value):value;
		this.setState({updating});
	}
	newContentInput(e){
		const element = e.target;
		this.updateNewContent(element.getAttribute('prop'), element.value);
	}
	prepareContent(){
		let content =  this.state.content;
		content = this.filterContent(content);
		const {field, order} = this.state.order;
		if(field === '' || order === ''){
			return content;
		}
		return this.sortContent(content, field, order);

	}
	filterContent(content){
		this.state.filter.forEach(filter => {
			const {field, values} = filter;
			if(values.includes(-1) || values.length < 1){
			}
			else{
				content = content.filter(c=>values.includes(c[field]))
			}
		});
		return content;
	}
	sortContent(content, field, order){
		const numeric = (a, b)=> a[field] - b[field];
		const numericDesc = (a, b)=> b[field] - a[field];
		const alpha = (a, b)=>{
			const aField = a[field].toUpperCase();
			const bField = b[field].toUpperCase();
			if (aField < bField) { return -1; }
			if (aField > bField) { return 1; }
			return 0;
		}
		const alphaDesc = (a, b)=>{
			const aField = b[field].toUpperCase();
			const bField = a[field].toUpperCase();
			if (aField < bField) { return -1; }
			if (aField > bField) { return 1; }
			return 0;
		}
		let sortFunction = numeric;
		if(order=='desc'){			
			sortFunction = numericDesc;
		}
		return content.sort(sortFunction);
	}
	addNewRow(){
		let {newContent, content} = this.state;
		let _newContent = Object.assign({}, newContent);
		_newContent.id = new Date().getDate();
		_newContent.type = parseInt(_newContent.type);
		_newContent.duration = parseInt(_newContent.duration);
		content.push(_newContent);
		newContent = Object.assign({}, blankContent);
		this.setState({content, newContent});
	}

	filterDropdown(){
		const callback = (field, value)=>{
			let filter = this.state.filter;
			if(filter.filter(e=>e.field===field).length === 0){
				filter.push({field: field, values: [value]});
			}
			else{
				for (let index = 0; index < filter.length; index++) {
					let filterElement = filter[index];
					if(filterElement.field==field){
						if(filterElement.values.includes(value)){
							filterElement.values = filterElement.values.filter(v=>v!=value);
						}
						else{
							filterElement.values.push(value);
						}
					}
				}
			}
			this.setState({filter});
		}
		return (
		  <Dropdown className="dropdown-wrapper">
			<DropdownTrigger>
				<FontAwesomeIcon color="purple" icon={filterIcon}/>
			</DropdownTrigger>
			<DropdownContent>
			  <div className="content-container">
				<table>
					<tbody>
						<tr>
							<td>
								<h2>Status</h2>
								<label><input type="checkbox" onChange={()=>{callback('status', 1)}}/> Complete</label>
								<label><input type="checkbox" onChange={()=>{callback('status', 0)}}/> Incomplete</label>
							</td>
							<td>
								<h2>Type</h2>
								{this.state.types.filter(type=>type.id>-1).map(type=>
									<label key={type.id}><input type="checkbox" onChange={()=>{
										callback('type', type.id)
									}}/> {type.name}</label>
								)}
							</td>
							<td>
								<h2>Impact</h2>
								{[...Array(5).keys()].map((n)=>
									<label key={n}>
										<input type="checkbox" onChange={()=>{callback('impact', n+1)}}/> {'⭐'.repeat(n+1)}
									</label>
								)}
							</td>
							<td>
								<h2>Duration</h2>
								{this.state.durations.map(duration=>
									<label key={duration.id}><input type="checkbox" onChange={()=>{
										callback('duration', duration.id)
									}}/> {duration.name}</label>
								)}
							</td>
						</tr>
					</tbody>
				</table>

			  </div>
			</DropdownContent>
		  </Dropdown>
		);
	}

	orderDropdown(){
		const callback = (input, field)=>{
			const order = {
				field: field, order: input.target.value
			}
			this.setState({order})
		}
		return (
		  <Dropdown className="dropdown-wrapper">
			<DropdownTrigger>
				<FontAwesomeIcon color="purple" icon={orderIcon}/>
			</DropdownTrigger>
			<DropdownContent>
			  <div className="content-container">
				<h2>Date added</h2>
				<label><input onChange={(e)=>{callback(e, 'date_added')}} type="radio" name="order" value='desc'/> Recent to Latest</label>
				<label><input onChange={(e)=>{callback(e, 'date_added')}} type="radio" name="order" value='asc'/> Latest to Recent</label>
				<h2>Impact</h2>
				<label><input onChange={(e)=>{callback(e, 'impact')}} type="radio" name="order" value='desc'/> High to Low</label>
				<label><input onChange={(e)=>{callback(e, 'impact')}} type="radio" name="order" value='asc'/> Low to High</label>
				<h2>Time</h2>
				<label><input onChange={(e)=>{callback(e, 'duration')}} type="radio" name="order" value='desc'/> Most to Least</label>
				<label><input onChange={(e)=>{callback(e, 'duration')}} type="radio" name="order" value='asc'/> Least to Most</label>
			  </div>
			</DropdownContent>
		  </Dropdown>
		);
	}
	optionDropdown(item){
		const editCallback = ()=>{
			removeClass('dropdown--active');
			let updating = Object.assign({}, item);
			updating.authors = [];
			item.authors.forEach(author => {
				updating.authors.push(author);
			});
			const showNewContentModal = true;
			this.setState({updating, showNewContentModal});
		}
		const deleteCallback = ()=>{
			removeClass('dropdown--active');
			const deleting = item.id;
			this.setState({ showDeleteConfirm: true, deleting });
		}
		return (
		  <Dropdown className="dropdown-wrapper">
			<DropdownTrigger>
				<span>
					<FontAwesomeIcon color="purple" icon={optionsIcon}/>
				</span>
			</DropdownTrigger>
			<DropdownContent>
			  <div className="content-container">
				<ul>
					<li onClick={editCallback}>
						<span>Edit</span>
						<div><FontAwesomeIcon color='purple' icon={editIcon}/></div>
					</li>
					<li onClick={deleteCallback}>
						<span>Delete</span>
						<div><FontAwesomeIcon color='purple' icon={deleteIcon}/></div>
					</li>
				</ul>
			  </div>
			</DropdownContent>
		  </Dropdown>
		);
	}

	authorComponent(item, form=false){
		return <>
			{item.authors.map((author,i)=>
				<><span key={i} className="tag blue">{author.name}</span>&nbsp;</>
			)}
			{form && this.authorComponent_()}
		</>
	}

	authorComponent_(){
		const addAuthor = ()=>{
			let {authors} = this.state.updating;
			authors.push({id: new Date().getTime(), name: this.state.newAuthor});
			this.setState({updating: {...this.state.updating, authors: authors}})
		};
		return <>
			<input className="poppins" onChange={(e)=>{this.setState({newAuthor: e.target.value})}} type="text"></input>
			<button disabled={this.state.newAuthor === ''} type="button" className="mv-btn mv-blue btn-xs" onClick={addAuthor}>Add Author</button>
		</>;
	}

	upsertModal(){
		const closeModal = ()=>{
			this.setState({
				showNewContentModal: false,
				updating: Object.assign({}, blankContent)
			})}
		const upsert = ()=>{
			let {content, updating} = this.state;
			if(updating.id){
				for (let index = 0; index < content.length; index++) {
					const element = content[index];
					if(element.id === updating.id){
						content[index] = Object.assign({}, updating);
					}
				}
			}
			else{
				updating.id = new Date().getTime();
				content.push(Object.assign({}, updating));
			}
			updating = Object.assign({}, blankContent);
			this.setState({content});
			this.setState({showNewContentModal: false});
		}
		return <ReactModal
			id='new-content-modal'
			isOpen={this.state.showNewContentModal}>
			<form>
				<div className="input-group">
					<label>Name: </label>
					<input type="text" prop='name' defaultValue={this.state.updating.name} onChange={this.newContentInput}/>
				</div>
				<div className="input-group inline">
					<label>Completed: </label>&nbsp;
					{this.statusComponent(this.state.updating)}
				</div>
				<div className="input-group">
					<label>Media Type: </label>
					{this.mediaComponent(this.state.updating)}
				</div>
				<div className="input-group">
					<label>Impact: </label>
					{this.impactComponent(this.state.updating)}
				</div>
				<div className="input-group">
					<label>Duration: </label>
					{this.durationComponent(this.state.updating)}
				</div>
				<div className="input-group">
					<label>Author(s): </label>
					{this.authorComponent(this.state.updating, true)}
				</div>
				<div className="input-group">
					<label>Notes: </label>
					<textarea prop='notes' defaultValue={this.state.updating.notes} rows={4} onChange={this.newContentInput}></textarea>
				</div>
				<div className="input-group inline">
					<button onClick={upsert} type="button" className="mv-btn mv-blue btn-xs">Add</button>
					<button type="button" className="mv-btn mv-blue-light btn-xs" onClick={closeModal}>Cancel</button>
				</div>
			</form>
		</ReactModal>;

	}

	deleteConfirm(){
		const callback = ()=>{
			this.delete();
			this.setState({ showDeleteConfirm: false });
		}
		return <ReactModal
			id='delete-confirm'
			isOpen={this.state.showDeleteConfirm}>
				<div className="container">
					<h1>Are you sure?</h1>
					<hr/>
					<p>Are you sure you want to delete this entry?</p>
					<div>
						<button onClick={()=>callback()} className="mv-btn mv-blue">Yes</button>
						<button onClick={()=>{this.setState({ showDeleteConfirm: false });}} className="mv-btn mv-black">No</button>
					</div>
				</div>
			</ReactModal>
	}

	delete(){
		let {content, deleting} = this.state;
		for (let index = 0; index < content.length; index++) {
			const element = content[index];
			if(element.id === deleting){
				content.splice(index, 1);
				break;
			}
		}
		deleting = 0;
		this.setState({ deleting, content });
	}

	render() {
		const {showNewContentModal} = this.state;
		const content = this.prepareContent();
		return (
			<div className="learnig-manager-wrapper">
				<div className="header">
					<h1>Learning Management System &nbsp; &nbsp;</h1>
				</div>
				<div id='learnig-manager-container'>
					<span className="section-title">
						&nbsp;
						<div className="right">
							<div id="filter-dropdown">{this.filterDropdown()}</div>
							&nbsp;
							&nbsp;
							<div id="order-dropdown">{this.orderDropdown()}</div>
							&nbsp;
							&nbsp;
							<span>
								<FontAwesomeIcon
									color="purple"
									icon={addContentIcon}
									onClick={()=>{
										const updating = Object.assign({}, blankContent);
										this.setState({ showNewContentModal: !showNewContentModal, updating });
									}}/>
							</span>
						</div>
					</span>
					<div id="learning-manager-table">
						<table>
							<thead>
								<tr>
									<th>Content Name</th>
									<th>Status</th>
									<th>Media<br/>Type</th>
									<th width='1'>Impact</th>
									<th width='1'>Duration</th>
									<th>Author<br/>Last Name</th>
									<th>Description Notes</th>
									<th width='1'></th>
								</tr>
							</thead>
							<tbody>
								{content.map((item, i)=>
									<tr key={i}>
										<td className="left">{item.name}</td>
										<td>{this.statusComponent(item)}</td>
										<td><span className="tag">{this.getTypeName(item.type)}</span></td>
										<td>{this.impactComponent(item)}</td>
										<td>{this.getDurationName(item.duration)}</td>
										<td className="left">{this.authorComponent(item)}</td>
										<td className="left">{item.notes}</td>
										<td>
											<div id="option-dropdown">{this.optionDropdown(item)}</div>
										</td>
									</tr>
								)}
								{content.length > 0 || 
									<tr className="empty-lms">
										<td colSpan={8}>
											<br/>
											Click on the plus button above to create an entry
											<br/>
											<br/>
										</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
				{this.upsertModal()}
				{this.deleteConfirm()}				
			</div>
		)
	}
}