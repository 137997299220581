import React from "react";
import "./quote.css";

function Quote(props) {
  return (
    <div>
      <label id="quote" style={{textAlign: props.align}}>
        <cite>
        "{props.quote}"<br /> - <span className="author">{props.author}</span>
        </cite>
      </label>
      {props.showLine ? <hr id="quote-line"/> : null}
    </div>
  );
}

export default Quote;
