import React from "react";
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';

const emojis = ['0x1F601', '0x1F916', '0x2764', '0x1F4AF', '0x1F4A5', 
  '0x1F4AB', '0x1F44C', '0x1F919', '0x270D', '0x1F9E0', '0x1F441', '0x1F3C4', 
  '0x1F3C4', //'U+200D', 'U+2642', 'U+FE0F', 
  '0x1F334', '0x1F30E', '0x1F9ED', '0x1F3DD', 
  '0x1F6A8', '0x2708', '0x1F680', '0x23F3', '0x1F319', '0x2B50', '0x1F30C', 
  '0x26A1', '0x1F525', '0x1F30A', '0x1F389', '0x1F3C6', '0x1F3C5', '0x1F3AF', 
  '0x1F52E', '0x1F579', '0x1F9E9', '0x265F', '0x1F3A8', '0x1F451', '0x1F393', 
  '0x1F3B5', '0x1F3A4', '0x1F4F1', '0x1F50C', '0x1F4BB', '0x1F3AC', '0x1F50D', 
  '0x1F4DA', '0x1F4DC', '0x1F4B0', '0x2712', '0x1F4C8', '0x1F513', '0x2699', 
  '0x1F9F2', '0x1F52D', '0x26A0', '0x1F504', '0x267E', '0x2753', '0x2757', 
  '0x2705', 
  // '0x1F1FA', 
  // 'U+1F1F2'
]

class EmojiPicker extends React.Component {
  hideFromButton(element){
    try {
      setTimeout(() => {
        const div = element.parentNode.parentNode.parentNode.parentNode;
        div.querySelector('button.trigger').click();
      }, 100);
    } catch (error) {}
  }
  render(){
    return (
      <Dropdown className="emoji-picker-wrapper">
        <DropdownTrigger>
          <button className="no-btn trigger">
            <span className={this.props.className}>
              {String.fromCodePoint(this.props.emoji)}
            </span>
          </button>
        </DropdownTrigger>
        <DropdownContent>
          <div className="content-container">
            <p className="emoji-section">Icons</p>
            <div className="emojis-container mvision-scrollbar">
              {emojis.map((emoji, index)=>
                <button
                  key={this.props.id+'-emoji-'+index}
                  disabled={this.props.emoji === emoji}
                  onClick={(element)=>{
                    this.hideFromButton(element.currentTarget);
                    this.props.onEmojiClick(emoji)}}
                  className="emoji">{String.fromCodePoint(emoji)}</button>
              )}
            </div>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

export default EmojiPicker;