import React, { Component } from 'react';
import ReactDragListView from 'react-drag-listview'
import { faArrowsUpDown as dragIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

class Headers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.prepareData(),
    };
  }

  prepareData(){
    let data = this.props.headers;
    data.sort((a, b)=>a.index-b.index);
    return data;
  }
  
  updateData(){
    const data = this.prepareData();
    this.setState({data});
  }

  componentDidUpdate(prevProps){
    if (prevProps !== this.props) {
      this.updateData();
    }    
  }

  reorderHeaders(from, to){
    const group = this.props.group;
    const section = this.props.section;
    const fromId = this.state.data[from].id;
    const toId = this.state.data[to].id;
    this.props.reorderHeaders(group, section, fromId, toId);
  }

  render() {
    const dragProps = {
      nodeSelector: 'a',
      handleSelector: '.drag-icon-container'
    };

    return (
      <ReactDragListView
        {...dragProps} 
        lineClassName="drag-line"
        onDragEnd={(fromIndex, toIndex)=>{
          this.reorderHeaders(fromIndex, toIndex);
        }}
        >
        {this.state.data.map((item, index) => (
          <a href={"#" + item.id} key={item.id}>
          <label className="section-label">
            <div style={{display: 'flex', alignItems: 'center'}}>
              &nbsp;
              <span className='section-icon'>
              {String.fromCodePoint(item.icon)}&nbsp;
              </span>
              &nbsp;
              <span className='title'>{item.title}</span>
              <div className='drag-icon-container'>
                <FontAwesomeIcon icon={dragIcon}/>
              </div>
            </div>
          </label>
        </a>
          
        ))}
      </ReactDragListView>
    );
  }
}

export default Headers;
