import React from "react";
import { Navigate } from "react-router-dom";
import "./landing.css";
import Logo from "./Logo_V2.png";
import {
    faEye, faLaptop, faLightbulb, faChevronCircleLeft, faChevronCircleRight, faBars
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "nuka-carousel"
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import {removeClass} from '../../global/global-functions'


class AboutPage extends React.Component{

    render(){
        return (
            <div id="whole">
                <div>
                    <div className="topnav" id="myTopnav">

                        <a className="home" href="/"><img id="logo" src={Logo} alt="MVision Logo" height={60} /></a>
                        <a href="/login" id="login">Login</a>
                        <Dropdown className="landing-links">
                            <DropdownTrigger><FontAwesomeIcon icon={faBars}/></DropdownTrigger>
                            <DropdownContent>
                                <ul>
                                    <li><a href="/#product">Our Product</a></li>
                                    <li><a href="/schools">For Schools</a></li>
                                    {/* <li><a href="/about">About Us</a></li> */}
                                    <li><a href="/contact">Contact Us</a></li>
                                </ul>
                            </DropdownContent>
                        </Dropdown>
                        <a href="/contact">Contact Us</a>
                        <a href="#about" className="active">About Us</a>
                        <a href="/schools">For Schools</a>
                        <a href="/#product">Our Product</a>
                        <a href="javascript:void(0);" className="icon" onclick="myFunction()">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div id="schools" className="xl">
                    <div id="header">
                        <br/>
                        <h1>Our Story</h1>
                        <br/>
                        <h2 style={{fontSize: 25}}>
                        MVision is a digital tool for young leaders & shapers to methodically 
                        ideate, plan, and Manifest their fulfilled life Vision.
                        <br/>
                        <br/>
                        The idea for the platform originated when our founder, Jack, 
                        came across studies showing the effectiveness of writing down goals. 
                        Growing up he attempted to track his life vision in his notes app. 
                        Soon after, he was discouraged by the limiting features which led to 
                        the initial idea  and creation of MVision alongside his brother, Jeff.
                        </h2>
                        <br/>
                    </div>
                    <div id="school-section-container">
                        <br/>
                        <Carousel
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleRight}/>
                                </button>
                            )}
                            wrapAround autoplay autoplayInterval={15000}>
                            <div className="about-section full-team">
                                <div className="person">
                                    <img src="/jack.jpg"></img>
                                    <h1>Jack Potvin</h1>
                                    <h2>Co-Founder</h2>
                                    <span>
                                    Serial Entrepreneur, Product @ Sportsvisio (3.1M Venture-Backed), 
                                    Founder & Executive Director @ Project Happy
                                    </span>
                                </div>
                                <div className="divider"></div>
                                <div className="person">
                                    <img src="/jeff.jpg"></img>
                                    <h1>Jeff Potvin</h1>
                                    <h2>Co-Founder</h2>
                                    <span>
                                    Previously Investment Banking Summer 
                                    Analyst @ FT Partners, Incoming Private 
                                    Equity Analyst @ Parthenon Capital
                                    </span>
                                </div>
                                <div className="divider"></div>
                                <div className="person">
                                    <img src="/carlos.jpg"></img>
                                    <h1>Carlos Suescún</h1>
                                    <h2>Lead Developer</h2>
                                    <span>
                                    Over a decade of Fullstack development experience, 
                                    5 years advancing the education sector
                                    </span>
                                </div>
                            </div>
                            <div className="about-section full-advisors">
                                <div className="person">
                                    <img src="/taylor.jpg"></img>
                                    <h1>Taylor Clark</h1>
                                    <h2>Technical Advisor</h2>
                                    <span>
                                    Former Senior Engineer @ Google, Founder and CTO Pair (aquired), 
                                    SVP of Technology @ Care Coordination
                                    </span>
                                </div>
                                <div className="divider"></div>
                                <div className="person">
                                    <img src="/ian.jpg"></img>
                                    <h1>Ian Knowles</h1>
                                    <h2>Strategic Advisor</h2>
                                    <span>
                                    Accomplished Serial Founder, Investor, & Board Member.
                                    <br/>
                                    <br/>
                                    Co-Founder and Partner of LB Equity, Founder @ 
                                    Spruce & Norton (investment holding company)
                                    </span>
                                </div>
                            </div>
                        </Carousel>
                        <div id="header">
                            <br/>
                            <br/>
                            <br/>
                            <h1>Our Vision</h1>
                            <br/>
                            <h2>
                            Why | “Written goals have a way of transforming wishes 
                            into wants; cant's into cans; dreams into plans; and 
                            plans into reality!” - Michael Korda.
                            <br/>
                            <br/>
                            What | MVision is a digital tool for young leaders & 
                            shapers to methodically ideate, plan, and Manifest their 
                            fulfilled life Vision.
                            <br/>
                            <br/>
                            How | We're helping young people “put it all together”, 
                            by inspiring lasting positive habits through low-entry-barrier 
                            & fun technology.
                            <br/>
                            <br/>
                            Charting more purposeful & directed lives for 
                            thousands of youth ♾❤️
                            </h2>
                            <br/>
                        </div>
                        <br/>
                    </div>
                    <br/>
                </div>
                <div id="schools" className="xs">
                    <div id="header">
                        <br/>
                        <Carousel
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleRight}/>
                                </button>
                            )}
                            wrapAround autoplay autoplayInterval={5000}>
                            <div  className="text-slide">
                                <h1>Our Story</h1>
                                <br/>
                                <h3>
                                MVision is a digital tool for young leaders & shapers to methodically 
                                ideate, plan, and Manifest their fulfilled life Vision. 
                                The idea for the platform originated when our founder, Jack, 
                                came across studies showing the effectiveness of writing down goals. 
                                Growing up he attempted to track his life vision in his notes app. 
                                Soon after, he was discouraged by the limiting features which led to 
                                the initial idea  and creation of MVision alongside his brother, Jeff.
                                </h3>
                            </div>
                            <div className="text-slide">
                                <h1>Our Vision</h1>
                                <br/>
                                <h3>
                                Why | “Written goals have a way of transforming wishes 
                                into wants; cant's into cans; dreams into plans; and 
                                plans into reality!” - Michael Korda.
                                <br/>
                                <br/>
                                What | MVision is a digital tool for young leaders & 
                                shapers to methodically ideate, plan, and Manifest their 
                                fulfilled life Vision.
                                <br/>
                                <br/>
                                How | We're helping young people “put it all together”, 
                                by inspiring lasting positive habits through low-entry-barrier 
                                & fun technology.
                                <br/>
                                <br/>
                                Charting more purposeful & directed lives for 
                                thousands of youth ♾❤️
                                </h3>
                            </div>
                        </Carousel>
                        <br/>
                    </div>
                    <div id="school-section-container">
                        <br/>
                        <br/>
                        <br/>
                        <Carousel
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleRight}/>
                                </button>
                            )}
                            wrapAround>
                            <div className="person">
                                <img src="/jack.jpg"></img>
                                <h1>Jack Potvin</h1>
                                <h2>Co-Founder</h2>
                                <span>
                                Serial Entrepreneur, Product @ Sportsvisio (3.1M Venture-Backed), 
                                Founder & Executive Director @ Project Happy
                                </span>
                            </div>
                            <div className="person">
                                <img src="/jeff.jpg"></img>
                                <h1>Jeff Potvin</h1>
                                <h2>Co-Founder</h2>
                                <span>
                                Previously Investment Banking Summer 
                                Analyst @ FT Partners, Incoming Private 
                                Equity Analyst @ Parthenon Capital
                                </span>
                            </div>
                            <div className="person">
                                <img src="/carlos.jpg"></img>
                                <h1>Carlos Suescún</h1>
                                <h2>Lead Developer</h2>
                                <span>
                                Over a decade of Fullstack development experience, 
                                5 years advancing the education sector
                                </span>
                            </div>
                            <div className="person">
                                <img src="/taylor.jpg"></img>
                                <h1>Taylor Clark</h1>
                                <h2>Technical Advisor</h2>
                                <span>
                                Former Senior Engineer @ Google, Founder and CTO Pair (aquired), 
                                SVP of Technology @ Care Coordination
                                </span>
                            </div>
                            <div className="person">
                                <img src="/ian.jpg"></img>
                                <h1>Ian Knowles</h1>
                                <h2>Strategic Advisor</h2>
                                <span>
                                Accomplished Serial Founder, Investor, & Board Member.
                                <br/>
                                <br/>
                                Co-Founder and Partner of LB Equity, Founder @ 
                                Spruce & Norton (investment holding company)
                                </span>
                            </div>
                        </Carousel>
                        <br/>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default AboutPage;