import React from "react";
import "./profile.css";
import UserContext from "../../reuseables/user-context/user-context";
import ProfileMenu from './profile-menu';
import { makeAPICall, getAvatarsUrl } from "../../global/global-functions";
import ReactDragListView from 'react-drag-listview'
import { 
  faTrash as deleteIcon,
  faSave as saveIcon,
  faTimes as cancelIcon,
  faPlus as newFrameworkIcon,
  faArrowsUpDown as dragIcon,
  faPencil as editIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./profile.css";
import ReactModal from 'react-modal';
import EmojiPicker from "./../myvision/content/emoji-picker";

const avatarUrlRoot = getAvatarsUrl();

class StaticsPage extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      staticList: [],
      sectionList: [],
      headerList: [],
      selected: {},
      showNewFrameworkModal: false,
      showEditForm: false,
      showDeleteConfirm: false
      // contentReady: false
    };
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;
    this.loadEntries();
    if (user === null) {
      makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
        if (haserror) {
          // console.log(response["message"]);
        } else {
          let userInfo = response["message"];
          setUser(userInfo);
          this.setState({ userInfo });
        }
      });
    } else {
      this.setState({ userInfo: user });
    }
  }

  sortEntryList(a, b){
    return (a.index || a.entry_id) - (a.index || a.entry_id);
  }

  loadEntries(){
    makeAPICall("GET", "/entry/entries", undefined, (haserror, response) => {
      if (haserror) return;
      const data = [...response["message"]];
      let staticList = [];
      let sectionList = [];
      let headerList = [];
      try {
        staticList = data[0];
        staticList.sort((a,b)=>(a.index || a.entry_id) - (b.index || b.entry_id));
      } catch (error) {}
      try {
        sectionList = data[1];
      } catch (error) {}
      try {
        headerList = data[2];
      } catch (error) {}
      this.setState({ staticList, sectionList, headerList });
    });
  }

  saveEntry(entry) {
    makeAPICall("POST", "/entry/entries", entry, (error, response) => {
      if (error) {
      } else {
        this.loadEntries();
      }
    });
  }


	deleteConfirm(){
		const callback = ()=>{
      const id = this.state.selected.entry_id;
			this.delete(id);
			this.setState({ showDeleteConfirm: false, selected: {} });
		}
		return <ReactModal
			id='delete-confirm'
			isOpen={this.state.showDeleteConfirm}>
      <div className="container">
        <h1>Are you sure?</h1>
        <hr/>
        <p>Are you sure you want to delete this entry?</p>
        <div>
          <button onClick={()=>callback()} className="mv-btn mv-blue">Yes</button>
          <button onClick={()=>{this.setState({ showDeleteConfirm: false });}} className="mv-btn mv-black">No</button>
        </div>
      </div>
    </ReactModal>
	}

  delete(id){
    const body = {
      entry_id: id,
      entry_path: '/',
    };
    makeAPICall("DELETE", "/entry/entries", body, (haserror, response) => {
      if (haserror) {
      } else {
        this.removeEntryFromLists(id);
      }
    });
  }

  removeEntryFromLists(id){
    let {staticList} = this.state;
    for (let index = 0; index < staticList.length; index++) {
      const element = staticList[index];
      if(element.entry_id === id){
        staticList.splice(index, 1);
        this.setState({ staticList });
        return true;
      }
    }
    let {sectionList} = this.state;
    for (let index = 0; index < sectionList.length; index++) {
      const element = sectionList[index];
      if(element.entry_id === id){
        sectionList.splice(index, 1);
        this.setState({ sectionList });
        return true;
      }
    }
    let {headerList} = this.state;
    for (let index = 0; index < headerList.length; index++) {
      const element = headerList[index];
      if(element.entry_id === id){
        headerList.splice(index, 1);
        this.setState({ headerList });
        return true;
      }
    }

  }

  patchEntry(entry, update) {
    makeAPICall(
      "PATCH", "/entry/entries",
      {...update, entry_id: entry.entry_id}, (haserror, response) => {
      if (haserror) {
      } else {
        let {staticList, sectionList, headerList} = this.state;
        for (let i = 0; i < staticList.length; i++) {
          if(staticList[i].entry_id===entry.entry_id){
            staticList[i] = {...staticList[i], ...update};
            break;
          }
        }
        for (let i = 0; i < sectionList.length; i++) {
          if(sectionList[i].entry_id===entry.entry_id){
            sectionList[i] = {...sectionList[i], ...update};
            break;
          }
        }
        for (let i = 0; i < headerList.length; i++) {
          if(headerList[i].entry_id===entry.entry_id){
            headerList[i] = {...headerList[i], ...update};
            break;
          }
        }
        this.setState({ selected: {}, staticList, sectionList, headerList });
      }
    });
  }

  avatar(info){
    if(!info)
      return (<></>);
    if (info["profile_picture"]) {
      return (
        <img src={avatarUrlRoot+info["profile_picture"]} alt="user icon" />
      );
    }
    return (
      <label className="no-avatar" style={{backgroundImage: 'url(/logo_white.png)'}}>
        <span>
          <br/>
          <br/>
          {info["first_name"][0] + info["last_name"][0]}
        </span>
      </label>
    );
  }
  prepareEdit(selectedEntry){
    const selected = Object.assign({}, selectedEntry);
    this.setState({ selected });
  }

  saveSelected(){
    const {selected} = this.state;
    this.patchEntry(selected, {entry: selected.entry});
  }

  updateIcon(entry, icon){
    this.patchEntry(entry, {icon});
  }

  newFrameworkModal(){
    const closeModal = ()=>{
      this.setState({ showNewFrameworkModal: false });
    }
    const createFrameWork = (e)=>{
      e.preventDefault();
      const button = e.target.querySelector('button[type=submit]');
      button.disabled = true
      const framework = new FormData(e.target).get("framework");
      this.preSaveNewFramework(framework);
      setTimeout(() => { button.disabled = false; closeModal(); }, 500);
    }
    return <ReactModal
        isOpen={this.state.showNewFrameworkModal}
        id="new-framework-modal">
        <h3>New framework</h3>
        <form onSubmit={createFrameWork} autoComplete="off">
            <input type='text' name='framework'/>
						<button type='submit' className="mv-btn btn-sm mv-blue">Add</button>
            <button className="mv-btn mv-black btn-sm" onClick={closeModal}>Cancel</button>
        </form>
      </ReactModal>
  }

  preSaveNewFramework(value){
    const  entry = {
      entry: value,
      entry_path: '/',
      entry_level: 1,
      entry_weight: 0,
      children: {},
    };
    this.saveEntry(entry);
  }

  editForm(){
    const {selected, staticList, sectionList} = this.state;
    const isSection = selected.entry_level === 2;
    const parentList = isSection ? staticList : sectionList;
    const closeModal = ()=>{
      this.setState({ showEditForm: false });
    }
    const saveForm = (e)=>{
      e.preventDefault();
      const button = e.target.querySelector('button[type=submit]');
      button.disabled = true
      const formData = Object.fromEntries(new FormData(e.target));
      const parent = parentList.filter(e=>e.entry_id === parseInt(formData.parent))[0];
      const patch = {
        entry: formData.name,
        entry_path: `${parent.entry_path}${parent.entry_id}/`
      }
      this.patchEntry(selected, patch);
      setTimeout(() => { button.disabled = false; closeModal(); }, 500);
    }
    let selectedParent = 0;
    try {
      selectedParent = selected.entry_path.split('/')[selected.entry_level-1];
    } catch (error) {}
    return <ReactModal
        isOpen={this.state.showEditForm}
        id="edit-entry-modal">
        <h3>Editing {isSection?'Section':'Header'}</h3>
        <form autoComplete="off" onSubmit={saveForm}>
          <label>Name</label>
          <input type='text' name='name' defaultValue={selected.entry}/>
          <label>{isSection?'Framework':'Section'}</label>
          <select name="parent" defaultValue={selectedParent}>
            {parentList.map(e=><option key={e.entry_id} value={e.entry_id}>{e.entry}</option>)}
          </select>
          <div>
            <button type='submit' className="mv-btn btn-sm mv-blue">Save</button>
            <button className="mv-btn mv-black btn-sm" onClick={closeModal}>Cancel</button>
          </div>
        </form>
      </ReactModal>
  }

  prepareSectionEdit(entry){
    this.setState({
      selected: entry,
      showEditForm: true,
    });
  }

  preReorder(fromIndex, toIndex){
    let {staticList} = this.state;
    const from = staticList[fromIndex];
    const to = staticList[toIndex];
    let newFrom = Object.assign({}, from);
    let newTo = Object.assign({}, to);
    newFrom.index = (to.index || to.entry_id);
    newTo.index = (from.index || from.entry_id);
    this.patchEntry(newFrom, {index: newFrom.index});
    this.patchEntry(newTo, {index: newTo.index});
    staticList[fromIndex] = newTo;
    staticList[toIndex] = newFrom;
    this.setState({ staticList });
  }

  prepareDelete(entry){
    this.prepareEdit(entry)
    this.setState({ showDeleteConfirm: true });
  }

  render() {
    const {userInfo, staticList, sectionList, headerList, selected} = this.state;
    const update = (e) => {
      const entry = e.target.value;
      selected.entry = entry;
      this.setState({ selected });
    }
    return (
      <div id="statics-container">
        <div className="parent0">
          <ProfileMenu
            current='statics'
            avatar={this.avatar(userInfo)}
            userInfo={userInfo}
          />
          <div className="statics-content">
            <h2>
              My Frameworks &nbsp;
              {/* <FontAwesomeIcon icon={frameworksIcon} color="#3C45A8"/> */}
            </h2>
            <div>
              <ReactDragListView
                nodeSelector="li"
                handleSelector="span.drag"
                onDragEnd={(fromIndex, toIndex)=>{this.preReorder(fromIndex, toIndex);}}
                lineClassName="drag-line">
                <ul>
                  {staticList.map(entry=>
                    <li key={'static'+entry.entry_id} className="static">
                      <div>
                        <input onChange={(e)=>{update(e)}} readOnly={selected.entry_id!==entry.entry_id} type="text" defaultValue={entry.entry}/>
                      </div>
                      <div>
                        {selected.entry_id===entry.entry_id ?
                        <>
                          <span onClick={()=>{this.saveSelected()}} className="save">
                            <FontAwesomeIcon icon={saveIcon} color="#3C45A8"/>
                          </span>
                          <span onClick={()=>{this.setState({ selected: {} });}} className="cancel">
                            <FontAwesomeIcon icon={cancelIcon} color="#3C45A8"/>
                          </span>
                        </>
                        :
                        <>
                          <span onClick={()=>this.prepareEdit(entry)} className="edit">
                            <FontAwesomeIcon icon={editIcon} color="#3C45A8"/>
                          </span>
                          <span onClick={()=>{this.prepareDelete(entry)}} className="delete">
                            <FontAwesomeIcon icon={deleteIcon} color="#3C45A8"/>
                          </span>
                          <span className="drag">
                            <FontAwesomeIcon icon={dragIcon} color="#3C45A8"/>
                          </span>
                        </>
                        }
                      </div>
                    </li>
                  )}
                </ul>
              </ReactDragListView>
              <span className="new-static">
                <a href='#' onClick={()=>{this.setState({ showNewFrameworkModal: true });}}>
                  <FontAwesomeIcon icon={newFrameworkIcon}/>
                  &nbsp;
                  New Framework</a>
              </span>
            </div>
            <h2>
              My Sections &nbsp;
            </h2>
            <div>
              <ul>
                {sectionList.map(entry=>
                  <li key={'static'+entry.entry_id} className="static">
                    <div>
                      <EmojiPicker
                        onEmojiClick={(emoji)=>{this.updateIcon(entry, emoji)}}
                        className='section-icon' emoji={entry.icon}/>
                      <input readOnly={true} type="text" value={entry.entry}/>
                    </div>
                    <div>
                      <span onClick={()=>this.prepareSectionEdit(entry)} className="edit">
                        <FontAwesomeIcon icon={editIcon} color="#3C45A8"/>
                      </span>
                          <span onClick={()=>{this.prepareDelete(entry)}} className="delete">
                        <FontAwesomeIcon icon={deleteIcon} color="#3C45A8"/>
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <h2>
              My Headers &nbsp;
            </h2>
            <div>
              <ul>
                {headerList.map(entry=>
                  <li key={'static'+entry.entry_id} className="static">
                    <div>
                      <EmojiPicker
                        onEmojiClick={(emoji)=>{this.updateIcon(entry, emoji)}}
                        className='section-icon' emoji={entry.icon}/>
                      <input readOnly={true} type="text" value={entry.entry}/>
                    </div>
                    <div>
                      <span onClick={()=>this.prepareSectionEdit(entry)} className="edit">
                        <FontAwesomeIcon icon={editIcon} color="#3C45A8"/>
                      </span>
                          <span onClick={()=>{this.prepareDelete(entry)}} className="delete">
                        <FontAwesomeIcon icon={deleteIcon} color="#3C45A8"/>
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            {this.newFrameworkModal()}
            {this.editForm()}
            {this.deleteConfirm()}
          </div>
        </div>
      </div>
    );
  }
}

export default StaticsPage;
