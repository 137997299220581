import React from "react";
import {
  faFire,
  faCake,
  faCircleHalfStroke
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./mymetrics.css";
import MyMetricsLineChart from './line-chart';
import {
  makeAPICall,
} from "../../global/global-functions";

class MyMetricsPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      metricsReady: false,
      myMetrics : {
        total_logins: 0,
        total_sections: 0,
        total_headers: 0,
        weekly_streak: 0,
        big_wins_completed: 0,
        big_wins_ahead: 0,
        stones_completed: 0,
        stones_ahead: 0,
        chart_data: {
          values: [],
          categories: [],
        }
      },
    }

  }
  componentDidMount(){
    makeAPICall("GET", "/user/metrics", undefined, (haserror, response) => {
      if (haserror) {
        console.log(response["message"]);
      } else {
        const myMetrics = response["message"];
        this.setState({ myMetrics });
      }
      this.setState({metricsReady: true});
    });
  }
  render() {
    const metrics = this.state.myMetrics;
    return (
      <div id="my-metrics-container">
        <div className="header">
          <h1>MyMetrics &nbsp; &nbsp;</h1>
        </div>
        <div className="parent">
          <div className="div0">
            <div className="container">
              <div className="div1">
                <div className="container">
                  <div className='icon'>
                    <FontAwesomeIcon icon={faFire}/>
                  </div>
                  <span>Weekly<br/>Streak</span>
                  <div className='count'>{metrics.weekly_streak}</div>
                </div>
              </div>
              <div className="div2">
                <div className="container">
                  <div className="div1">
                    <div className="icon">
                      <FontAwesomeIcon icon={faCake} color="#3c45a8"/>
                    </div>
                    <span>Big Wins<br/>Completed</span>
                    <div className='count'>{metrics.big_wins_completed}</div>
                  </div>
                  <div className="div2">
                    <div className="icon">
                      <FontAwesomeIcon color="transparent" icon={faCake}/>
                    </div>
                    <span>Big Wins<br/>Ahead</span>
                    <div className='count'>{metrics.big_wins_ahead}</div>
                  </div>
                  <div className="div3">
                    <div className="icon">
                      <FontAwesomeIcon icon={faCircleHalfStroke} color="#3c45a8"/>
                    </div>
                    <span>Stepping Stones<br/>Completed</span>
                    <div className='count'>{metrics.stones_completed}</div>
                  </div>
                  <div className="div4">
                    <div className="icon">
                      <FontAwesomeIcon color="transparent" icon={faCake}/>
                    </div>
                    <span>Stepping Stones<br/>Ahead</span>
                    <div className='count'>{metrics.stones_ahead}</div>
                  </div>
                  <div className="div5">
                    <div className="hr"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div3">
            {this.state.metricsReady && 
              <MyMetricsLineChart data={metrics.chart_data}/>
            }
          </div>
          <div className="div4">
            <div className="container">
              <div className="icon">
                <FontAwesomeIcon icon={faClock} color="#3c45a8"/>
              </div>
              <span>Life Time Statistics</span>
              <br/>
              <label>Total logins</label>
              <div className="count">{metrics.total_logins}</div>
              <label>Number of sections</label>
              <div className="count">{metrics.total_sections}</div>
              <label>Numbers of headers</label>
              <div className="count">{metrics.total_headers}</div>
            </div>
          </div>
          <div className="div5"></div>
        </div>
      </div>
    );
  }
}

export default MyMetricsPage;
