import React from "react";
import { makeAPICall } from "../../global/global-functions";
import Button from "../../reuseables/button/button";
import Quote from "../../reuseables/quote/quote";
import { Navigate } from "react-router-dom";
import "./series.css";

let videoList = [
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "1",
    "Title": "Laurence D. Fink, Chairman and Chief Executive Officer, BlackRock, Inc.",
    "Link": "https://www.youtube.com/watch?v=q_j-jRttfV0&list=LL&index=1"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "2",
    "Title": "Michael Dell, Founder, Chairman and CEO, Dell Inc.",
    "Link": "https://www.youtube.com/watch?v=uaBvJlHevAQ&list=LL&index=2&t=2429s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "3",
    "Title": "Jeff Bezos, CEO and Founder, Amazon",
    "Link": "https://www.youtube.com/watch?v=zN1PyNwjHpc&list=LL&index=3&t=1259s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "4",
    "Title": "Donald J. Trump, Chairman and President, The Trump Organization",
    "Link": "https://www.youtube.com/watch?v=_vrrfRnqqus&list=LL&index=4"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "5",
    "Title": "Stephen A. Schwarzman, Chairman, CEO & Co-Founder, The Blackstone Group L.P.",
    "Link": "https://www.youtube.com/watch?v=7kThTbLUQdU&list=LL&index=6&t=5s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "6",
    "Title": "James Gorman, Chairman & CEO, Morgan Stanley",
    "Link": "https://www.youtube.com/watch?v=NmjG7PfVQRU&list=LL&index=7"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "7",
    "Title": "Fred Smith, Chairman, President and CEO, FedEx Corporation",
    "Link": "https://www.youtube.com/watch?v=C2xfYtWj8jQ&list=LL&index=8"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "8",
    "Title": "Dominic Barton, Global Managing Director, McKinsey & Company - Wharton Leadership Lecture",
    "Link": "https://www.youtube.com/watch?v=hG-1fppLjdY&list=LL&index=17"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "9",
    "Title": "Gwynne Shotwell, President and COO of SpaceX",
    "Link": "https://www.youtube.com/watch?v=1b-vAeYTxRA&list=LL&index=32&t=56s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "10",
    "Title": "Business NH Magazine Business Leader of the Year 2016: Dick Anagnost",
    "Link": "https://www.youtube.com/watch?v=90oHzKM5oYg&list=LL&index=42"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "11",
    "Title": "GSB Alumni Entrepreneur Bootcamp: Early Stage Sales",
    "Link": "https://www.youtube.com/watch?v=3PQIjCyIdWo&list=LL&index=50"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "12",
    "Title": "The walk from \"no\" to \"yes\" | William Ury",
    "Link": "https://www.youtube.com/watch?v=Hc6yi_FtoNo&list=LL&index=63"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "13",
    "Title": "Conducting Effective Negotiations",
    "Link": "https://www.youtube.com/watch?v=rCmvMDrCWjs&list=LL&index=64&t=39s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "14",
    "Title": "Mark Cuban at USC | Full Interview",
    "Link": "https://www.youtube.com/watch?v=fs9Gr8Gj6Cg&list=LL&index=81&t=3s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "15",
    "Title": "TIME Person of the Year: Elon Musk",
    "Link": "https://www.youtube.com/watch?v=PbVSZvC7UxY&list=LL&index=146"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "16",
    "Title": "View From The Top: General (Ret.) Stanley McChrystal",
    "Link": "https://www.youtube.com/watch?v=GgrQYS-q5f4&list=LL&index=229"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "17",
    "Title": "View From The Top: Carlos Brito, CEO, Anheuser-Busch InBev",
    "Link": "https://www.youtube.com/watch?v=xhFxw4mBre0&list=LL&index=230&t=1927s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "18",
    "Title": "CEO Carlos Ghosn of Renault-Nissan Alliance on Innovation",
    "Link": "https://www.youtube.com/watch?v=r2gZ_23z92o&list=LL&index=231&t=724ss"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "19",
    "Title": "Ron Johnson: Trust in Your Imagination and Instinct",
    "Link": "https://www.youtube.com/watch?v=e_HByVsugQY&list=LL&index=232&t=30s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "20",
    "Title": "MasterCard CEO Ajay Banga on Taking Risks in Your Life and Career",
    "Link": "https://www.youtube.com/watch?v=9-tjY-DG5-0&list=LL&index=233"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "21",
    "Title": "Blackstone's Stephen Schwarzman on Hiring Phenomenal People",
    "Link": "https://www.youtube.com/watch?v=3jGc8biSYHA&list=LL&index=236&t=2s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "22",
    "Title": "Stewart Butterfield, Cofounder and CEO of Slack",
    "Link": "https://www.youtube.com/watch?v=EhxX06-LsZs&list=LL&index=237"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "23",
    "Title": "Diane Von Fürstenberg, Founder & Chairman, DVF",
    "Link": "https://www.youtube.com/watch?v=1N999tM1H3Q&list=LL&index=238"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "24",
    "Title": "Ken Frazier, CEO of Merck & Co, Inc.",
    "Link": "https://www.youtube.com/watch?v=ytU0Re2QVoI&list=LL&index=239"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "25",
    "Title": "Dara Khosrowshahi, CEO, Uber",
    "Link": "https://www.youtube.com/watch?v=M8aCKi3dsVg&list=LL&index=240"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "26",
    "Title": "Ruth Porat, CFO at Alphabet and Google",
    "Link": "https://www.youtube.com/watch?v=tq1kvyC-6ek&list=LL&index=241"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "27",
    "Title": "Phil Knight, MBA ’62, Co-founder and Chairman Emeritus, Nike",
    "Link": "https://www.youtube.com/watch?v=OHTosaWWKvg&list=LL&index=242"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "28",
    "Title": "What It Takes to Be a Remarkable Leader: John Doerr, Venture Capitalist",
    "Link": "https://www.youtube.com/watch?v=LDWURusr02k&list=LL&index=248"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "29",
    "Title": "Oprah Winfrey on Career, Life, and Leadership",
    "Link": "https://www.youtube.com/watch?v=6DlrqeWrczs&list=LL&index=252&t=2214s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "30",
    "Title": "Indra Nooyi, PepsiCo Chairman and CEO & Doug McMillon, Walmart President and CEO",
    "Link": "https://www.youtube.com/watch?v=xl32J4TCS0E&list=LL&index=253"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "31",
    "Title": "What makes a good CEO?",
    "Link": "https://www.youtube.com/watch?v=IBzV_c0tk3I&list=LL&index=255"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "32",
    "Title": "Career Pathways to Executive Management (the full video)",
    "Link": "https://www.youtube.com/watch?v=etnIIZRgI4g&list=LL&index=256"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "33",
    "Title": "David Solomon, CEO, Goldman Sachs",
    "Link": "https://www.youtube.com/watch?v=Wr0NT4KelVY&list=LL&index=259"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "34",
    "Title": "People, Leadership & Startups - Bill Campbell, Board Director of Intuit Inc. and Apple Inc.",
    "Link": "https://www.youtube.com/watch?v=GHLg1wDuc10&list=LL&index=262"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "35",
    "Title": "CS50 Lecture by Mark Zuckerberg - 7 December 2005",
    "Link": "https://www.youtube.com/watch?v=xFFs9UgOAlE&list=LL&index=263&t=2s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "36",
    "Title": "Eric Schmidt at Columbia's Campbell Leadership Lecture",
    "Link": "https://www.youtube.com/watch?v=MK6HkkPPrFU&list=LL&index=268&t=3857s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "37",
    "Title": "Ginni Rometty, Chairman, President, and CEO of IBM",
    "Link": "https://www.youtube.com/watch?v=eZ2HLKzkE4A&list=LL&index=271"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "38",
    "Title": "\"Are You Destined to Deal?\" With Goldman Sachs Managing Director Jim Donovan",
    "Link": "https://www.youtube.com/watch?v=RpUJfW4WTKw&list=LL&index=273&t=1s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "39",
    "Title": "Sheryl Sandberg and Adam Grant discuss 'Option B' at Wharton",
    "Link": "https://www.youtube.com/watch?v=Qym57NxPwg4&list=LL&index=278&t=1914s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "40",
    "Title": "Mitt Romney at Emory's Goizueta Business School",
    "Link": "https://www.youtube.com/watch?v=f_wuYjV1RZs&list=LL&index=285"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "41",
    "Title": "Mitt Romney in Conversation with John Dickerson",
    "Link": "https://www.youtube.com/watch?v=VKyDo4_CMX8&list=LL&index=286"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "42",
    "Title": "Ray Dalio, Founder and Chairman, Bridgewater Associates",
    "Link": "https://www.youtube.com/watch?v=KBLb0H3A1CY&list=LL&index=289"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "43",
    "Title": "Fireside chat with Google co-founders, Larry Page and Sergey Brin with Vinod Khosla",
    "Link": "https://www.youtube.com/watch?v=Wdnp_7atZ0M&list=LL&index=294"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "44",
    "Title": "Handling Complexity with Professor Richard Jolly | London Business School",
    "Link": "https://www.youtube.com/watch?v=HPL5g3F_wbA&list=LL&index=299&t=3s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "45",
    "Title": "The Sales Acceleration Formula | Mark Roberge | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=RG_eyn0fRXs&list=LL&index=312"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "46",
    "Title": "Blitzscaling 08: Eric Schmidt on Structuring Teams and Scaling Google",
    "Link": "https://www.youtube.com/watch?v=hcRxFRgNpns&list=LL&index=334&t=37s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "47",
    "Title": "PandoMonthly: Fireside Chat With Elon Musk",
    "Link": "https://www.youtube.com/watch?v=uegOUmgKB4E&list=LL&index=335&t=4s"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "48",
    "Title": "Managing Client Relationships as an Investment Banker, Lawyer or Consultant",
    "Link": "https://www.youtube.com/watch?v=z8kqCIxXTEw&list=LL&index=397&t=1ss"
  },
  {
    "Topic": "Executive leadership/management",
    "series_id": "1",
    "video_id": "49",
    "Title": "Growth Through Acquisitions | Wharton Scale School",
    "Link": "https://www.youtube.com/watch?v=p0Bx6UtherQ&list=LL&index=398&t=2ss"
  },
  {
    "Topic": "Finance",
    "series_id": "2",
    "video_id": "50",
    "Title": "The Myth of Private Equity | Jeffrey C. Hooke | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=0q2wFCmoP6o&list=LL&index=44&t=3s"
  },
  {
    "Topic": "Finance",
    "series_id": "2",
    "video_id": "51",
    "Title": "Private Equity: Industry Review - Ed Mathias, The Carlyle Group",
    "Link": "https://www.youtube.com/watch?v=Pn4O4scLEuc&list=LL&index=82&t=8s"
  },
  {
    "Topic": "Finance",
    "series_id": "2",
    "video_id": "52",
    "Title": "Ray Dalio: Money, Power, and the Collapse of Empires | Lex Fridman Podcast #251",
    "Link": "https://www.youtube.com/watch?v=TISMidxdZoc&list=LL&index=1644"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "53",
    "Title": "Don Valentine, Sequoia Capital: \"Target Big Markets\"",
    "Link": "https://www.youtube.com/watch?v=nKN-abRJMEw&list=LL&index=219&t=3ss"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "54",
    "Title": "20. Guest Lecture by Stephen Schwarzman",
    "Link": "https://www.youtube.com/watch?v=kTN6T9yiIyA&list=LL&index=2200"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "55",
    "Title": "John Doerr: 700 Investments, 192 IPOs, 375,000+ Jobs Created",
    "Link": "https://www.youtube.com/watch?v=exSZDVXlBnc&list=LL&index=249"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "56",
    "Title": "19. Investment Banks",
    "Link": "https://www.youtube.com/watch?v=2yycGEFCNYE&list=LL&index=254"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "57",
    "Title": "The Simple Path to Wealth | JL Collins | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=T71ibcZAX3I&list=LL&index=272"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "58",
    "Title": "TiEcon 2017 Grand Keynote - Vinod Khosla",
    "Link": "https://www.youtube.com/watch?v=vWb8CrhAUkA&list=LL&index=295"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "59",
    "Title": "William Ackman: Everything You Need to Know About Finance and Investing in Under an Hour | Big Think",
    "Link": "https://www.youtube.com/watch?v=WEDIj9JBTC8&list=LL&index=302"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "60",
    "Title": "Why Don't People like Capitalism? | Alain de Botton | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=BaNZ-TMGN3E&list=LL&index=307"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "61",
    "Title": "The mathematician who cracked Wall Street | Jim Simons",
    "Link": "https://www.youtube.com/watch?v=U5kIdtMJGc8&list=LL&index=313&t=1196s"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "62",
    "Title": "How to Identify M&A Targets | Transaction Advisors Institute",
    "Link": "https://www.youtube.com/watch?v=x2R8rP9eFLk&list=LL&index=337&t=7s"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "63",
    "Title": "Venture Capital - Silicon Valley Ponzi Scheme - Chamath Palihapitiya",
    "Link": "https://www.youtube.com/watch?v=NVVsdlHslfI&list=LL&index=386&t=16s"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "64",
    "Title": "Private Equity: The Consolidation Play and Due Diligence - John Poerink, Linley Capital",
    "Link": "https://www.youtube.com/watch?v=thyxopgzG4k&list=LL&index=388&t=3ss"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "65",
    "Title": "Explained | The Stock Market | FULL EPISODE | Netflix",
    "Link": "https://www.youtube.com/watch?v=ZCFkWDdmXG8&list=LL&index=394"
  },
  {
    "Topic": "Finance/Investing",
    "series_id": "2",
    "video_id": "66",
    "Title": "Convertible Note Terms: How Convertible Notes Work",
    "Link": "https://www.youtube.com/watch?v=HNYfNn6c2B4&list=LL&index=406&t=634s"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "67",
    "Title": "Loving History!",
    "Link": "https://www.youtube.com/watch?v=ltImdAunBFM&list=LL&index=235"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "68",
    "Title": "Yuval Noah Harari: The 2021 60 Minutes interview",
    "Link": "https://www.youtube.com/watch?v=EIVTf-C6oQo&list=LL&index=267"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "69",
    "Title": "Sapiens: A Brief History of Humankind by Yuval Noah Harari",
    "Link": "https://www.youtube.com/watch?v=1GnBamLaqqE&list=LL&index=282"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "70",
    "Title": "A Brief History of Quantum Mechanics - with Sean Carroll",
    "Link": "https://www.youtube.com/watch?v=5hVmeOCJjOU&list=LL&index=3233"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "71",
    "Title": "A Visual History of Human Knowledge | Manuel Lima | TED Talks",
    "Link": "https://www.youtube.com/watch?v=BQZKs75RMqM&list=LL&index=3777"
  },
  {
    "Topic": "History",
    "series_id": "3",
    "video_id": "72",
    "Title": "The history of our world in 18 minutes | David Christian",
    "Link": "https://www.youtube.com/watch?v=yqc9zX04DXs&list=LL&index=408"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "73",
    "Title": "Derek Bobbitt at SBT 2022, \"Web3: Blockchain & The Next Generation of the Internet\"",
    "Link": "https://youtu.be/6af07RmE5_o"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "74",
    "Title": "Jack Potvin at SBT 2022, \"Hacking Happiness\"",
    "Link": "https://youtu.be/pxun-O2jgVk"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "75",
    "Title": "What is an API?",
    "Link": "https://youtu.be/s7wmiS2mSXY"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "76",
    "Title": "Amazon Senior Product Manager Interview - Flawless Product Strategy Answer by Amazon PM: Car Feature",
    "Link": "https://www.youtube.com/watch?v=QwseA0aqQgE&list=LL&index=9&t=16s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "77",
    "Title": "Meta Product Manager Interview",
    "Link": "https://www.youtube.com/watch?v=dt7OrazE6SQ&list=LL&index=10&t=587s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "78",
    "Title": "Google Product Manager Interview",
    "Link": "https://www.youtube.com/watch?v=9YgeKeGkVBM&list=LL&index=11&t=920s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "79",
    "Title": "Project Happy, Holloway Competition 2022",
    "Link": "https://www.youtube.com/watch?v=rhzgRZJOHA8&list=LL&index=12"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "80",
    "Title": "The Effective Engineer | Edmond Lau | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=BnIz7H5ruy0&list=LL&index=14"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "81",
    "Title": "A philosophy of Software Design | John Ousterhout | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=bmSAYlu0NcY&list=LL&index=16&t=1840s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "82",
    "Title": "OpenAI CEO Sam Altman | AI for the Next Era",
    "Link": "https://www.youtube.com/watch?v=WHoWGNQRXb0&list=LL&index=18"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "83",
    "Title": "Schrödinger's Cat",
    "Link": "https://www.youtube.com/watch?v=IOYyCHGWJq4&list=LL&index=600"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "84",
    "Title": "In the Age of AI (full documentary) | FRONTLINE",
    "Link": "https://www.youtube.com/watch?v=5dZ_lvDgevk&list=LL&index=65"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "85",
    "Title": "Peter Diamandis on Why A.I. Will Save the World | Impact Theory",
    "Link": "https://www.youtube.com/watch?v=f6qdAwENFRk&list=LL&index=67&t=1818s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "86",
    "Title": "AlphaGo - The Movie | Full award-winning documentary",
    "Link": "https://www.youtube.com/watch?v=WXuK6gekU1Y&list=LL&index=85"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "87",
    "Title": "How the Best Hackers Learn Their Craft",
    "Link": "https://www.youtube.com/watch?v=6vj96QetfTg&list=LL&index=86&t=1907s"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "88",
    "Title": "The Art of Code - Dylan Beattie",
    "Link": "https://www.youtube.com/watch?v=6avJHaC3C2U&list=LL&index=888"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "89",
    "Title": "Virtual Reality Engineer Explains One Concept in 5 Levels of Difficulty | WIRED",
    "Link": "https://www.youtube.com/watch?v=akveRNY6Ulw&list=LL&index=92"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "90",
    "Title": "Hacker Explains One Concept in 5 Levels of Difficulty | WIRED",
    "Link": "https://www.youtube.com/watch?v=d9PqVcgT1kQ&list=LL&index=93"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "91",
    "Title": "Quantum Computing Expert Explains One Concept in 5 Levels of Difficulty | WIRED",
    "Link": "https://www.youtube.com/watch?v=OWJCfOvochA&list=LL&index=944"
  },
  {
    "Topic": "How to break into coding/tech",
    "series_id": "4",
    "video_id": "92",
    "Title": "Richard Feynman: Can Machines Think?",
    "Link": "https://www.youtube.com/watch?v=ipRvjS7q1DI&list=LL&index=1000"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "93",
    "Title": "Harvard i-lab | Startup Secrets: Roadmap to Success",
    "Link": "https://www.youtube.com/watch?v=RW3g2TroY3g&list=LL&index=45"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "94",
    "Title": "Harvard i-lab | The Ideation Framework with Josh Wexler",
    "Link": "https://www.youtube.com/watch?v=0zCtHDPRlog&list=LL&index=46"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "95",
    "Title": "Harvard i-lab | Increasing Your Creative Capacity",
    "Link": "https://www.youtube.com/watch?v=vI8nXdi-ir8&list=LL&index=48"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "96",
    "Title": "How to build a successful tech startup according to Paypal founder Max Levchin",
    "Link": "https://www.youtube.com/watch?v=sqWJsSoWJ5w&list=LL&index=77&t=2ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "97",
    "Title": "The single biggest reason why start-ups succeed | Bill Gross",
    "Link": "https://www.youtube.com/watch?v=bNpx7gpSqbY&list=LL&index=79"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "98",
    "Title": "Harvard i-lab | Startup Secrets: Turning Products into Companies",
    "Link": "https://www.youtube.com/watch?v=092JQrye9IM&list=LL&index=80&t=2096s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "99",
    "Title": "Mark Zuckerberg at Startup School 2013",
    "Link": "https://www.youtube.com/watch?v=MGsalg2f9js&list=LL&index=179"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "100",
    "Title": "Mark Zuckerberg at Startup School 2012",
    "Link": "https://www.youtube.com/watch?v=5bJi7k-y1Lo&list=LL&index=180"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "101",
    "Title": "The Art of Product Management with Sachin Rekhi (ENG’05 W’05)",
    "Link": "https://www.youtube.com/watch?v=huTSPanUlQM&list=LL&index=181&t=12s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "102",
    "Title": "How to Raise Money with Marc Andreessen, Ron Conway, and Parker Conrad (HtSaS 2014: 9)",
    "Link": "https://www.youtube.com/watch?v=0JBOSmRo8js&list=LL&index=188&t=51s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "103",
    "Title": "Geoff Ralston - Parting Advice",
    "Link": "https://www.youtube.com/watch?v=s8ER6DIlAvY&list=LL&index=189&t=5ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "104",
    "Title": "Ali Rowghani - How to Lead",
    "Link": "https://www.youtube.com/watch?v=7HDO1p3VdYg&list=LL&index=190&t=6ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "105",
    "Title": "Carolynn Levy - Modern Startup Funding",
    "Link": "https://www.youtube.com/watch?v=9qWZALyGSmg&list=LL&index=191&t=4ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "106",
    "Title": "Kevin Hale - How to Pitch Your Startup",
    "Link": "https://www.youtube.com/watch?v=17XZGUX_9iM&list=LL&index=192&t=2s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "107",
    "Title": "Adora Cheung - How to Prioritize Your Time",
    "Link": "https://www.youtube.com/watch?v=XcCmMOWuAF4&list=LL&index=193&t=2ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "108",
    "Title": "Kevin Hale - Startup Pricing 101",
    "Link": "https://www.youtube.com/watch?v=jwXlo9gy_k4&list=LL&index=194&t=4s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "109",
    "Title": "Kevin Hale - How to Improve Conversion Rates",
    "Link": "https://www.youtube.com/watch?v=PGqX9fpweyc&list=LL&index=195&t=1s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "110",
    "Title": "Kirsty Nathoo - Managing Startup Finances",
    "Link": "https://www.youtube.com/watch?v=LBC16jhiwak&list=LL&index=196&t=4s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "111",
    "Title": "Gustaf Alströmer - Growth for Startups",
    "Link": "https://www.youtube.com/watch?v=6lY9CYIY4pQ&list=LL&index=197&t=4s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "112",
    "Title": "Kat Mañalac - How to Launch (Again and Again)",
    "Link": "https://www.youtube.com/watch?v=3xU050kMbHM&list=LL&index=198&t=5s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "113",
    "Title": "Anu Hariharan and Adora Cheung - How Investors Measure Startups Q&A",
    "Link": "https://www.youtube.com/watch?v=zcG-kXH4mPk&list=LL&index=199&t=5s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "114",
    "Title": "Michael Seibel - How to Plan an MVP",
    "Link": "https://www.youtube.com/watch?v=1hHMwLxN6EM&list=LL&index=200&t=3s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "115",
    "Title": "Ilya Volodarsky - Analytics for Startups",
    "Link": "https://www.youtube.com/watch?v=LLerCc7MOQo&list=LL&index=201&t=4s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "116",
    "Title": "Adora Cheung - How to Set KPIs and Goals",
    "Link": "https://www.youtube.com/watch?v=lL6GdUHIBsM&list=LL&index=202&t=5s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "117",
    "Title": "How the pre-money SAFE (Simple Agreement for Equity) Works",
    "Link": "https://www.youtube.com/watch?v=nMhlqu7hqPw&list=LL&index=203"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "118",
    "Title": "Marc Andreessen on Big Breakthrough Ideas and Courageous Entrepreneurs",
    "Link": "https://www.youtube.com/watch?v=JYYsXzt1VDc&list=LL&index=283"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "119",
    "Title": "Sequoia Capital's Doug Leone on Luck & Taking Risks",
    "Link": "https://www.youtube.com/watch?v=4cl8X02Xd1I&list=LL&index=284"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "120",
    "Title": "Vinod Khosla on How to Build the Future",
    "Link": "https://www.youtube.com/watch?v=TYt5yuiGk9E&list=LL&index=297"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "121",
    "Title": "Elon Musk: The future we're building -- and boring | TED",
    "Link": "https://www.youtube.com/watch?v=zIwLWfaAg-8&list=LL&index=3211"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "122",
    "Title": "How to Win by Daniel Gross",
    "Link": "https://www.youtube.com/watch?v=LH1bewTg-P4&list=LL&index=324&t=2s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "123",
    "Title": "How to Get Meetings with Investors and Raise Money by Aaron Harris",
    "Link": "https://www.youtube.com/watch?v=Jzz4AEIddzY&list=LL&index=325&t=8s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "124",
    "Title": "Understanding SAFEs and Priced Equity Rounds by Kirsty Nathoo",
    "Link": "https://www.youtube.com/watch?v=Dk6JNTDec9I&list=LL&index=326&t=3s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "125",
    "Title": "Fundraising Fundamentals By Geoff Ralston",
    "Link": "https://www.youtube.com/watch?v=gcevHkNGrWQ&list=LL&index=327&t=4s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "126",
    "Title": "How to Apply and Succeed at Y Combinator by Dalton Caldwell",
    "Link": "https://www.youtube.com/watch?v=8yiOcCPvyNE&list=LL&index=328&t=7ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "127",
    "Title": "Gustaf Alstromer - How to Get Users and Grow",
    "Link": "https://www.youtube.com/watch?v=T9ikpoF2GH0&list=LL&index=329&t=26s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "128",
    "Title": "Michael Seibel - Building Product",
    "Link": "https://www.youtube.com/watch?v=C27RVio2rOs&list=LL&index=330&t=4ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "129",
    "Title": "David Rusenko - How To Find Product Market Fit",
    "Link": "https://www.youtube.com/watch?v=0LNQxT9LvM0&list=LL&index=331&t=3ss"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "130",
    "Title": "Sam Altman - How to Succeed with a Startup",
    "Link": "https://www.youtube.com/watch?v=0lJKucu6HJc&list=LL&index=332&t=197s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "131",
    "Title": "Kevin Hale - How to Evaluate Startup Ideas",
    "Link": "https://www.youtube.com/watch?v=DOtCl5PU8F0&list=LL&index=333&t=3s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "132",
    "Title": "How to Find Product Market Fit - Stanford CS183F: Startup School",
    "Link": "https://www.youtube.com/watch?v=_6pl5GG8RQ4&list=LL&index=3700"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "133",
    "Title": "How to Create a Company | Elon Musk's 5 Rules",
    "Link": "https://www.youtube.com/watch?v=Qa_4c9zrxf0&list=LL&index=3744"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "134",
    "Title": "Peter Thiel: Going from Zero to One",
    "Link": "https://www.youtube.com/watch?v=rFZrL1RiuVI&list=LL&index=376"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "135",
    "Title": "Chamath Palihapitiya - how we put Facebook on the path to 1 billion users",
    "Link": "https://www.youtube.com/watch?v=raIUQP71SBU&list=LL&index=391&t=47s"
  },
  {
    "Topic": "How to start a business/startup",
    "series_id": "5",
    "video_id": "136",
    "Title": "Aaron Harris on Fundraising and Meeting with Investors",
    "Link": "https://www.youtube.com/watch?v=fNV3JIK0z-k&list=LL&index=404"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "137",
    "Title": "“Volunteerism, Student Entrepreneurship, & Happiness” - Jack Potvin (Interlakes High Assembly 2022)",
    "Link": "https://www.youtube.com/watch?v=ivqWTlEcdRo&list=LL&index=13&t=350s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "138",
    "Title": "SBT 2022 Intro + Jay Lucas Keynote, \"The Playbook to Revitalize America\"",
    "Link": "https://www.youtube.com/watch?v=zBJ3wRVmfjc&list=LL&index=26&t=340s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "139",
    "Title": "Challenging Conventions: Dick Anagost at TedxAmoskeagMillyard",
    "Link": "https://www.youtube.com/watch?v=uvCQlS78XuA&list=LL&index=41&t=44s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "140",
    "Title": "Daniel Goleman on Focus: The Secret to High Performance and Fulfilment",
    "Link": "https://www.youtube.com/watch?v=HTfYv3IEOqM&list=LL&index=43"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "141",
    "Title": "How To Eliminate Self Doubt Forever & The Power of Your Unconscious Mind | Peter Sage | TEDxPatras",
    "Link": "https://www.youtube.com/watch?v=v1ojZKWfShQ&list=LL&index=47"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "142",
    "Title": "How to Make the Best First Impressions",
    "Link": "https://www.youtube.com/watch?v=Om7jApT9cVc&list=LL&index=49"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "143",
    "Title": "Living in flow - the secret of happiness with Mihaly Csikszentmihalyi at Happiness & Its Causes 2014",
    "Link": "https://www.youtube.com/watch?v=TzPky5Xe1-s&list=LL&index=51"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "144",
    "Title": "Martin Seligman 'Flourishing - a new understanding of wellbeing' at Happiness & Its Causes 2012",
    "Link": "https://www.youtube.com/watch?v=e0LbwEVnfJA&list=LL&index=52"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "145",
    "Title": "The How of Happiness with Sonja Lyubomirsky, PhD, at Happiness and Its Causes 2016",
    "Link": "https://www.youtube.com/watch?v=F7JDbP_x8So&list=LL&index=533"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "146",
    "Title": "Happiness Habits - with Sonja Lyubomirsky",
    "Link": "https://www.youtube.com/watch?v=qYmLTG03ZDo&list=LL&index=544"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "147",
    "Title": "The surprising science of happiness | Dan Gilbert",
    "Link": "https://www.youtube.com/watch?v=4q1dgn_C0AU&list=LL&index=555"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "148",
    "Title": "God is not a Good Theory (Sean Carroll)",
    "Link": "https://www.youtube.com/watch?v=ew_cNONhhKI&list=LL&index=577"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "149",
    "Title": "A Monk's Guide to Happiness - with Gelong Thubten",
    "Link": "https://www.youtube.com/watch?v=c1gY7RWE48U&list=LL&index=61&t=3s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "150",
    "Title": "The power of listening | William Ury | TEDxSanDiego",
    "Link": "https://www.youtube.com/watch?v=saXfavo1OQo&list=LL&index=62"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "151",
    "Title": "How meditation can change your life and mind | Sam Harris, Jon Kabat-Zinn & more | Big Think",
    "Link": "https://www.youtube.com/watch?v=jCJdl6Vs7wg&list=LL&index=66"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "152",
    "Title": "How to be Happier - with Tal Ben-Shahar",
    "Link": "https://www.youtube.com/watch?v=HNE03HxWcFs&list=LL&index=68"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "153",
    "Title": "Muhammad Ali - \"I'll Show You How Great I Am\" speech",
    "Link": "https://www.youtube.com/watch?v=1LSewbS5eQI&list=LL&index=700"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "154",
    "Title": "Alone, Together: How Technology Separates Us | Henry Williams | TEDxTheMastersSchool",
    "Link": "https://www.youtube.com/watch?v=Bp05RuefBqM&list=LL&index=71&t=407ss"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "155",
    "Title": "Connected, but alone? | Sherry Turkle",
    "Link": "https://www.youtube.com/watch?v=t7Xr3AsBEK4&list=LL&index=72&t=591ss"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "156",
    "Title": "The habits of happiness | Matthieu Ricard",
    "Link": "https://www.youtube.com/watch?v=vbLEf4HR74E&list=LL&index=73&t=1029s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "157",
    "Title": "The 2021 Atlas Society Gala: Peter Thiel's Speech",
    "Link": "https://www.youtube.com/watch?v=YK3Tzx-S264&list=LL&index=76"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "158",
    "Title": "Clayton Christensen | How Will You Measure Your Life? | LinkedIn Speaker Series",
    "Link": "https://www.youtube.com/watch?v=5DwYcNr0Nuw&list=LL&index=78"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "159",
    "Title": "Leonard Susskind: My friend Richard Feynman",
    "Link": "https://www.youtube.com/watch?v=6Waurx8e-1o&list=LL&index=95"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "160",
    "Title": "Jordan Peterson on the 'backlash against masculinity' - BBC News",
    "Link": "https://www.youtube.com/watch?v=ShS4uEY2Jw8&list=LL&index=97"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "161",
    "Title": "Randy Pausch Last Lecture: Achieving Your Childhood Dreams",
    "Link": "https://www.youtube.com/watch?v=ji5_MqicxSo&list=LL&index=99&t=2708ss"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "162",
    "Title": "How to Speak",
    "Link": "https://www.youtube.com/watch?v=Unzc731iCUY&list=LL&index=101"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "163",
    "Title": "What are the most important moral problems of our time? | Will MacAskill",
    "Link": "https://www.youtube.com/watch?v=WyprXhvGVYk&list=LL&index=1233"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "164",
    "Title": "An Appetite for Wonder: Richard Dawkins in Conversation with Adam Rutherford",
    "Link": "https://www.youtube.com/watch?v=omsUZ3u5TX4&list=LL&index=130&t=3602s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "165",
    "Title": "Exponential Wisdom Episode 107: First Principles Thinking",
    "Link": "https://www.youtube.com/watch?v=_FSHu_2Qmpg&list=LL&index=143&t=38s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "166",
    "Title": "Neuroscientist REVEALS How To Reprogram Your Mind WHILE YOU SLEEP For Success! | Moran Cerf",
    "Link": "https://www.youtube.com/watch?v=PE0TedFPgH8&list=LL&index=1511"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "167",
    "Title": "Dean Kamen: The emotion behind invention",
    "Link": "https://www.youtube.com/watch?v=rNgqQNovWTc&list=LL&index=178"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "168",
    "Title": "How to Be an Adaptation Machine | Dean Kamen on Impact Theory",
    "Link": "https://www.youtube.com/watch?v=kPyDQINmJ8Y&list=LL&index=183"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "169",
    "Title": "The Nuts and Bolts of Better Brains: Harnessing the Power of Neuroplasticity",
    "Link": "https://www.youtube.com/watch?v=59ODYOaUbX4&list=LL&index=210"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "170",
    "Title": "Why Discipline Equals Freedom",
    "Link": "https://www.youtube.com/watch?v=j3UkRFyaALI&list=LL&index=211"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "171",
    "Title": "Why Discipline Must Come From Within - Jocko Willink",
    "Link": "https://www.youtube.com/watch?v=9OF06n1jNkM&list=LL&index=212"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "172",
    "Title": "How to Trick Your Brain to Like Doing Hard Things – Atomic Habits by James Clear",
    "Link": "https://www.youtube.com/watch?v=o7w5r5PfBKo&list=LL&index=213"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "173",
    "Title": "Life begins at 40: the biological and cultural roots of the midlife crisis | The Royal Society",
    "Link": "https://www.youtube.com/watch?v=eSWwIQzKsbY&list=LL&index=214"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "174",
    "Title": "The illusion of being connected | Gen. McChrystal | TEDxMidAtlantic",
    "Link": "https://www.youtube.com/watch?v=PVzcGhZ8Aeg&list=LL&index=228"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "175",
    "Title": "Explaining the Sunshine Initiative",
    "Link": "https://www.youtube.com/watch?v=Oaj0mEQhXGs&list=LL&index=234"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "176",
    "Title": "Stanford Graduate School of Business Graduation Remarks by Phil Knight, MBA '62",
    "Link": "https://www.youtube.com/watch?v=nRN9FwWQY8w&list=LL&index=243"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "177",
    "Title": "Think Fast, Talk Smart: Communication Techniques",
    "Link": "https://www.youtube.com/watch?v=HAnw168huqA&list=LL&index=246&t=2135s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "178",
    "Title": "Oprah Winfrey Harvard Commencement speech | Harvard Commencement 2013",
    "Link": "https://www.youtube.com/watch?v=GMWFieBGR7c&list=LL&index=251"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "179",
    "Title": "Remembering the Life and Career of Bill Campbell | Fortune",
    "Link": "https://www.youtube.com/watch?v=9AmHesyfoMQ&list=LL&index=264"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "180",
    "Title": "Building a Life - Howard H. Stevenson (2013)",
    "Link": "https://www.youtube.com/watch?v=wLn28DrSF68&list=LL&index=274"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "181",
    "Title": "Sheryl Sandberg, COO of Facebook, on Using Your Voice For Good",
    "Link": "https://www.youtube.com/watch?v=YZXUcYGprDY&list=LL&index=2799"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "182",
    "Title": "Life Lessons from the Front | Mitt Romney",
    "Link": "https://www.youtube.com/watch?v=HlP9X5Pig-8&list=LL&index=287"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "183",
    "Title": "Mitt Romney on Leadership: Know Your Values",
    "Link": "https://www.youtube.com/watch?v=1QNHJbq9EWM&list=LL&index=288&t=2126s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "184",
    "Title": "Principles for Success from Ray Dalio: Founder of the World’s Largest Hedge Fund",
    "Link": "https://www.youtube.com/watch?v=Y1OpbDWp8KY&list=LL&index=290"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "185",
    "Title": "How You Choose What To Do | Larry Page and Eric Schmidt | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=srI6QYfi-HY&list=LL&index=293"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "186",
    "Title": "Top 10 Life Lessons (Last Lecture Series)",
    "Link": "https://www.youtube.com/watch?v=62rgESCyB2g&list=LL&index=296&t=2055s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "187",
    "Title": "Vinod Khosla, MBA '80: Failure does not matter. Success matters.",
    "Link": "https://www.youtube.com/watch?v=HZcXup7p5-8&list=LL&index=298"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "188",
    "Title": "How to become 37.78 times better at anything | Atomic Habits summary (by James Clear)",
    "Link": "https://www.youtube.com/watch?v=PZ7lDrwYdZc&list=LL&index=301"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "189",
    "Title": "Sam Harris basically unlocks the secret to life",
    "Link": "https://www.youtube.com/watch?v=891MOyYBadY&list=LL&index=303"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "190",
    "Title": "Yuval Noah Harari | 21 Lessons for the 21st Century | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=Bw9P_ZXWDJU&list=LL&index=305"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "191",
    "Title": "The art of asking the right questions | Tim Ferriss, Warren Berger, Hope Jahren & more | Big Think",
    "Link": "https://www.youtube.com/watch?v=tYnGiWlwcj4&list=LL&index=3066"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "192",
    "Title": "Why You Shouldn't Go to Harvard | Malcolm Gladwell Highlights | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=7J-wCHDJYmo&list=LL&index=309"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "193",
    "Title": "Outliers: Why Some People Succeed and Some Don't",
    "Link": "https://www.youtube.com/watch?v=EcMKLwVlpJk&list=LL&index=318"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "194",
    "Title": "How To Multiply Your Time | Rory Vaden | TEDxDouglasville",
    "Link": "https://www.youtube.com/watch?v=y2X7c9TUQJ8&list=LL&index=322&t=914s"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "195",
    "Title": "The Nature of Reality: A Dialogue Between a Buddhist Scholar and a Theoretical Physicist",
    "Link": "https://www.youtube.com/watch?v=pLbSlC0Pucw&list=LL&index=338"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "196",
    "Title": "Programming your mind for success | Carrie Green | TEDxManchester",
    "Link": "https://www.youtube.com/watch?v=MmfikLimeQ8&list=LL&index=339"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "197",
    "Title": "Jeff Bezos - Regret Minimization Framework",
    "Link": "https://www.youtube.com/watch?v=jwG_qR6XmDQ&list=LL&index=340"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "198",
    "Title": "Why the majority is always wrong | Paul Rulkens | TEDxMaastricht",
    "Link": "https://www.youtube.com/watch?v=VNGFep6rncY&list=LL&index=341"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "199",
    "Title": "Larry Brilliant makes the case for optimism",
    "Link": "https://www.youtube.com/watch?v=4w7v1uO5IJI&list=LL&index=356"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "200",
    "Title": "Speak like a leader | Simon Lancaster | TEDxVerona",
    "Link": "https://www.youtube.com/watch?v=bGBamfWasNQ&list=LL&index=363"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "201",
    "Title": "Extreme Ownership | Jocko Willink | TEDxUniversityofNevada",
    "Link": "https://www.youtube.com/watch?v=ljqra3BcqWM&list=LL&index=364"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "202",
    "Title": "Making Humans a Multiplanetary Species",
    "Link": "https://www.youtube.com/watch?v=H7Uyfqi_TE8&list=LL&index=369&t=2ss"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "203",
    "Title": "Apple - Think Different - Full Version",
    "Link": "https://www.youtube.com/watch?v=5sMBhDv4sik&list=LL&index=3722"
  },
  {
    "Topic": "Mentality/Mindset",
    "series_id": "6",
    "video_id": "204",
    "Title": "Elon Musk about Simulation and God.",
    "Link": "https://www.youtube.com/watch?v=_vrHfYUvFSQ&list=LL&index=373"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "205",
    "Title": "Natural Gas 101",
    "Link": "https://youtu.be/-njmj0diWu8"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "206",
    "Title": "Jason Plant at SBT 2022, \"The Circular Innovation Economy is Just Getting Started\"",
    "Link": "https://youtu.be/GFoYvGBs45U"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "207",
    "Title": "Does the Universe Create Itself?",
    "Link": "https://youtu.be/I8p1yqnuk8Y"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "208",
    "Title": "I built $60 VR Haptic Gloves to feel Virtual Reality.",
    "Link": "https://www.youtube.com/watch?v=ZTzn37Usa-U&list=LL&index=56&t=5ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "209",
    "Title": "Einstein and the Quantum: Entanglement and Emergence",
    "Link": "https://www.youtube.com/watch?v=ntxC5KMC4y0&list=LL&index=59"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "210",
    "Title": "Mysteries of Modern Physics by Sean Carroll",
    "Link": "https://www.youtube.com/watch?v=rBpR0LBsUfM&list=LL&index=844"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "211",
    "Title": "Nancy Kanwisher: A neural portrait of the human mind",
    "Link": "https://www.youtube.com/watch?v=5Yj3nGv0kn8&list=LL&index=102"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "212",
    "Title": "2. Neuroanatomy",
    "Link": "https://www.youtube.com/watch?v=bAkuNXtgrLA&list=LL&index=103"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "213",
    "Title": "Stanford's Sapolsky On Depression in U.S. (Full Lecture)",
    "Link": "https://www.youtube.com/watch?v=NOAgplgTxfc&list=LL&index=107"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "214",
    "Title": "The biology of our best and worst selves | Robert Sapolsky",
    "Link": "https://www.youtube.com/watch?v=ORthzIOEf30&list=LL&index=108&t=10s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "215",
    "Title": "1. Introduction to Human Behavioral Biology",
    "Link": "https://www.youtube.com/watch?v=NNnIGh9g6fA&list=LL&index=109"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "216",
    "Title": "Does Consciousness Influence Quantum Mechanics?",
    "Link": "https://www.youtube.com/watch?v=CT7SiRiqK-Q&list=LL&index=112&t=822s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "217",
    "Title": "The Insane Biology of: Hammerhead Sharks",
    "Link": "https://www.youtube.com/watch?v=lgTbQlfOSKE&list=LL&index=116"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "218",
    "Title": "The Insane Biology of: The Octopus",
    "Link": "https://www.youtube.com/watch?v=mFP_AjJeP-M&list=LL&index=117"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "219",
    "Title": "Puffer Fish Constructs A Masterpiece of Love - BBC Earth",
    "Link": "https://www.youtube.com/watch?v=VQr8xDk_UaY&list=LL&index=118"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "220",
    "Title": "FINGERPRINTS of the COSMOS - Randall Carlson - A Fireside Talk",
    "Link": "https://www.youtube.com/watch?v=s3oc7neB6oU&list=LL&index=122"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "221",
    "Title": "Roger Penrose: Physics of Consciousness and the Infinite Universe | Lex Fridman Podcast #85",
    "Link": "https://www.youtube.com/watch?v=orMtwOz6Db0&list=LL&index=132"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "222",
    "Title": "Richard Dawkins: Evolution, Intelligence, Simulation, and Memes | Lex Fridman Podcast #87",
    "Link": "https://www.youtube.com/watch?v=5f-JlzBuUUU&list=LL&index=133"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "223",
    "Title": "Physics’ greatest mystery: Michio Kaku explains the God Equation | Big Think",
    "Link": "https://www.youtube.com/watch?v=B1GO1HPLp7Y&list=LL&index=140"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "224",
    "Title": "The Future in 2050 with Dr. Michio Kaku & Alex Klokus | #𝐒𝐀𝐋𝐓𝐍𝐘",
    "Link": "https://www.youtube.com/watch?v=_3I2UgAJkgo&list=LL&index=141"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "225",
    "Title": "DO THIS First Thing In The Morning To END INFLAMMATION & Never Get Sick! | Wim Hof",
    "Link": "https://www.youtube.com/watch?v=TM6WKeZ43s4&list=LL&index=142"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "226",
    "Title": "The Moth: When in Rome - Moran Cerf",
    "Link": "https://www.youtube.com/watch?v=xfRkEUT7rgc&list=LL&index=150"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "227",
    "Title": "Time: Do the past, present, and future exist all at once? | Big Think",
    "Link": "https://www.youtube.com/watch?v=5vzymaIabWI&list=LL&index=152&t=560ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "228",
    "Title": "2021's Biggest Breakthroughs in Math and Computer Science",
    "Link": "https://www.youtube.com/watch?v=9uASADiYe_8&list=LL&index=154&t=71s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "229",
    "Title": "2021's Biggest Breakthroughs in Physics",
    "Link": "https://www.youtube.com/watch?v=FMM7GWnAv0A&list=LL&index=158&t=2ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "230",
    "Title": "Peter H. Diamandis on “Abundance”",
    "Link": "https://www.youtube.com/watch?v=p0yLcJsv9kQ&list=LL&index=1666"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "231",
    "Title": "Entropy",
    "Link": "https://www.youtube.com/watch?v=870y6GUKbwc&list=LL&index=1744"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "232",
    "Title": "Neil deGrasse Tyson: My Man, Sir Isaac Newton | Big Think",
    "Link": "https://www.youtube.com/watch?v=danYFxGnFxQ&list=LL&index=187"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "233",
    "Title": "The Physics and Philosophy of Time - with Carlo Rovelli",
    "Link": "https://www.youtube.com/watch?v=-6rWqJhDv7M&list=LL&index=204"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "234",
    "Title": "Does Math Reveal Reality?",
    "Link": "https://www.youtube.com/watch?v=VN19VOMHxkk&list=LL&index=2055"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "235",
    "Title": "Memory, Consciousness & Coma [Full Talk], Sadhguru at Harvard Medical School",
    "Link": "https://www.youtube.com/watch?v=w7irEcQHChw&list=LL&index=207"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "236",
    "Title": "2016 Isaac Asimov Memorial Debate: Is the Universe a Simulation?",
    "Link": "https://www.youtube.com/watch?v=wgSZA3NPpBs&list=LL&index=208"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "237",
    "Title": "The Believing Brain: Evolution, Neuroscience, and the Spiritual Instinct",
    "Link": "https://www.youtube.com/watch?v=p0_-7FmrDq8&list=LL&index=209"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "238",
    "Title": "The Neuroscience of Consciousness – with Anil Seth",
    "Link": "https://www.youtube.com/watch?v=xRel1JKOEbI&list=LL&index=222"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "239",
    "Title": "The Richness of Time",
    "Link": "https://www.youtube.com/watch?v=1FJWvEbeBps&list=LL&index=223"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "240",
    "Title": "WSU: Space, Time, and Einstein with Brian Greene",
    "Link": "https://www.youtube.com/watch?v=CKJuC5CUMgU&list=LL&index=224"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "241",
    "Title": "Everything is Connected -- Here's How: | Tom Chi | TEDxTaipei",
    "Link": "https://www.youtube.com/watch?v=rPh3c8Sa37M&list=LL&index=2266"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "242",
    "Title": "1. Introduction to the Human Brain",
    "Link": "https://www.youtube.com/watch?v=ba-HMvDn_vU&list=LL&index=260&t=1969s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "243",
    "Title": "Neuroscientist and Philosopher Sam Harris | Full Interview | Code 2021",
    "Link": "https://www.youtube.com/watch?v=k5052kQo-TI&list=LL&index=280"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "244",
    "Title": "Elon Musk's Unbelievably Simple 12-minute Killer Break Down on Climate Change",
    "Link": "https://www.youtube.com/watch?v=xKCuDxpccYM&list=LL&index=291"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "245",
    "Title": "Physics: The Magic that Worked | Neil Turok | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=IC0OpWZMqfU&list=LL&index=300"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "246",
    "Title": "A Scientist's Journey Through Psychopathy | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=lOjykLQAdaE&list=LL&index=308"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "247",
    "Title": "Boy Scout Tried To Build a Nuclear Reactor in His Backyard",
    "Link": "https://www.youtube.com/watch?v=55D7qcME_no&list=LL&index=311&t=22ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "248",
    "Title": "Elon Musk: The Scientist Behind the CEO (and How He Teaches Himself) Documentary",
    "Link": "https://www.youtube.com/watch?v=q-g7BPdSmP4&list=LL&index=315&t=64s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "249",
    "Title": "Mathematics is the sense you never knew you had | Eddie Woo | TEDxSydney",
    "Link": "https://www.youtube.com/watch?v=PXwStduNw14&list=LL&index=317"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "250",
    "Title": "Math's Fundamental Flaw",
    "Link": "https://www.youtube.com/watch?v=HeQX2HjkcNo&list=LL&index=320&t=2ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "251",
    "Title": "Science can answer moral questions | Sam Harris",
    "Link": "https://www.youtube.com/watch?v=Hj9oB4zpHww&list=LL&index=3466"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "252",
    "Title": "Neil Turok Public Lecture: The Astonishing Simplicity of Everything",
    "Link": "https://www.youtube.com/watch?v=f1x9lgX8GaE&list=LL&index=3522"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "253",
    "Title": "The Most Successful Scientific Theory Ever: The Standard Model",
    "Link": "https://www.youtube.com/watch?v=Unl1jXFnzgo&list=LL&index=354&t=4s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "254",
    "Title": "8 ways the world could suddenly end: Stephen Petranek at TEDxMidwest",
    "Link": "https://www.youtube.com/watch?v=HEfpxiUIZPs&list=LL&index=359"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "255",
    "Title": "\"Spooky\" physics | Leo Kouwenhoven | TEDxDelft",
    "Link": "https://www.youtube.com/watch?v=wZzHnZzm_58&list=LL&index=360"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "256",
    "Title": "A Theory You've Never Heard Of | Michael Robinson | TEDxUniversityofHartford",
    "Link": "https://www.youtube.com/watch?v=gn4bvjMh4vc&list=LL&index=3611"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "257",
    "Title": "The dream we haven't dared to dream | Dan Pallotta",
    "Link": "https://www.youtube.com/watch?v=hxsnKwmW0dk&list=LL&index=362"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "258",
    "Title": "Are we living in the Matrix? | Navjeet Swatch | TEDxQESchool",
    "Link": "https://www.youtube.com/watch?v=d1lq1-vIEX8&list=LL&index=365"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "259",
    "Title": "Are We Living in a Simulation? | Oleg Maslov | TEDxSPbU",
    "Link": "https://www.youtube.com/watch?v=b5c7Rq4ZQaA&list=LL&index=366"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "260",
    "Title": "You are a Simulation & Physics Can Prove It: George Smoot at TEDxSalford",
    "Link": "https://www.youtube.com/watch?v=Chfoo9NBEow&list=LL&index=3677"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "261",
    "Title": "Why does the universe exist? | Jim Holt",
    "Link": "https://www.youtube.com/watch?v=zORUUqJd81M&list=LL&index=3711"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "262",
    "Title": "Why humans run the world | Yuval Noah Harari",
    "Link": "https://www.youtube.com/watch?v=nzj7Wg4DAbs&list=LL&index=375&t=891ss"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "263",
    "Title": "The Genius of Einstein: The Science, His Brain, the Man",
    "Link": "https://www.youtube.com/watch?v=DPPnrDdNoUU&list=LL&index=387&t=2s"
  },
  {
    "Topic": "Science",
    "series_id": "7",
    "video_id": "264",
    "Title": "The Kardashev Scale With Michio Kaku: Can We Become a Type 1 Civilization?",
    "Link": "https://www.youtube.com/watch?v=PxwPfPWrOCA&list=LL&index=3899"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "265",
    "Title": "Wanderers - a short film by Erik Wernquist [Official Version]",
    "Link": "https://www.youtube.com/watch?v=YH3c1QZzRK4&list=LL&index=83"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "266",
    "Title": "Carl Sagan, Stephen Hawking and Arthur C. Clarke - God, The Universe and Everything Else (1988)",
    "Link": "https://www.youtube.com/watch?v=HKQQAv5svkk&list=LL&index=87"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "267",
    "Title": "Carl Sagan's 1994 \"Lost\" Lecture: The Age of Exploration",
    "Link": "https://www.youtube.com/watch?v=6_-jtyhAVTc&list=LL&index=89&t=128ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "268",
    "Title": "The Theory of Everything - Stephen Hawking - Audiobook",
    "Link": "https://www.youtube.com/watch?v=3mXwMhMIGMA&list=LL&index=90&t=3861s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "269",
    "Title": "Astrophysicist Explains Gravity in 5 Levels of Difficulty | WIRED",
    "Link": "https://www.youtube.com/watch?v=QcUey-DVYjk&list=LL&index=911"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "270",
    "Title": "Could We Terraform Mars?",
    "Link": "https://www.youtube.com/watch?v=FshtPsOTCP4&list=LL&index=96&t=719s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "271",
    "Title": "Cosmology Lecture 1",
    "Link": "https://www.youtube.com/watch?v=P-medYaqVak&list=LL&index=98"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "272",
    "Title": "Brian Greene - Do Parallel Universes Exist?",
    "Link": "https://www.youtube.com/watch?v=rXWFyP1JKEs&list=LL&index=110"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "273",
    "Title": "The Mathematics of our Universe",
    "Link": "https://www.youtube.com/watch?v=KT5Sk-62-pg&list=LL&index=111"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "274",
    "Title": "How Big Is The Universe?",
    "Link": "https://www.youtube.com/watch?v=pn3euL8Tbfw&list=LL&index=1133"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "275",
    "Title": "Why We May Be Surrounded by Older Alien Civilizations",
    "Link": "https://www.youtube.com/watch?v=LrrNu_m_9K4&list=LL&index=114"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "276",
    "Title": "What Actually Expands In An Expanding Universe?",
    "Link": "https://www.youtube.com/watch?v=9DrBQg_n2Uo&list=LL&index=115"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "277",
    "Title": "X Talks | Leonard Susskind",
    "Link": "https://www.youtube.com/watch?v=TJLsNHHqAt0&list=LL&index=119&t=32ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "278",
    "Title": "Is There One All Powerful SUPERFORCE Controlling The Universe?",
    "Link": "https://www.youtube.com/watch?v=uBk-Wst_7aA&list=LL&index=129&t=390s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "279",
    "Title": "What Was The Big Bang?",
    "Link": "https://www.youtube.com/watch?v=XSCrSkK2HcQ&list=LL&index=134"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "280",
    "Title": "Why Is The Universe Perfect?",
    "Link": "https://www.youtube.com/watch?v=lR9r7_MweK8&list=LL&index=138&t=1290s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "281",
    "Title": "String Theory and the End of Space and Time with Robbert Dijkgraaf",
    "Link": "https://www.youtube.com/watch?v=0T--WC4D1C0&list=LL&index=139&t=280s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "282",
    "Title": "What Actually Caused The Universe To Be Born? | Cosmic Vistas | Spark",
    "Link": "https://www.youtube.com/watch?v=Ox7YMwe7Cls&list=LL&index=144&t=6s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "283",
    "Title": "Kip Thorne - What is the Large-Scale Structure of the Universe?",
    "Link": "https://www.youtube.com/watch?v=UY3_OlDJRMo&list=LL&index=145&t=83ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "284",
    "Title": "Looking back in time with the James Webb Space Telescope",
    "Link": "https://www.youtube.com/watch?v=sMxdeUJ0v2c&list=LL&index=147"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "285",
    "Title": "Why the James Webb Space Telescope looks like that",
    "Link": "https://www.youtube.com/watch?v=I2GhFSInBqA&list=LL&index=148"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "286",
    "Title": "How NASA’s Webb Telescope Will Transform Our Place in the Universe",
    "Link": "https://www.youtube.com/watch?v=shPwW11MEHg&list=LL&index=149"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "287",
    "Title": "Things We’ve Never Seen: The James Webb Space Telescope Explores the Cosmos",
    "Link": "https://www.youtube.com/watch?v=WtjkNsnC--A&list=LL&index=153"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "288",
    "Title": "The Fermi Paradox II — Solutions and Ideas – Where Are All The Aliens?",
    "Link": "https://www.youtube.com/watch?v=1fQkVqno-uI&list=LL&index=159&t=46ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "289",
    "Title": "The Fermi Paradox — Where Are All The Aliens? (1/2)",
    "Link": "https://www.youtube.com/watch?v=sNhhvQGsMEc&list=LL&index=160&t=4ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "290",
    "Title": "Why Alien Life Would be our Doom - The Great Filter",
    "Link": "https://www.youtube.com/watch?v=UjtOGPJ0URM&list=LL&index=161&t=2ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "291",
    "Title": "How to Build a Data-Driven B2B Sales Process",
    "Link": "https://www.youtube.com/watch?v=PFooAclITJg&list=LL&index=167"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "292",
    "Title": "Rebooting the Cosmos: Is the Universe the Ultimate Computer?",
    "Link": "https://www.youtube.com/watch?v=atMuFCpxnUQ&list=LL&index=168"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "293",
    "Title": "NASA 1958 - 2100 (Timelapse of past & future technology)",
    "Link": "https://www.youtube.com/watch?v=2qaDEt7PCMI&list=LL&index=169"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "294",
    "Title": "THE FIRST 10,000 DAYS ON MARS (Timelapse)",
    "Link": "https://www.youtube.com/watch?v=G3hPH_bc0Ww&list=LL&index=171"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "295",
    "Title": "The Connected Universe | Nassim Haramein | TEDxUCSD",
    "Link": "https://www.youtube.com/watch?v=xJsl_klqVh0&list=LL&index=227"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "296",
    "Title": "How Elon Musk Will Bring Humanity To Mars",
    "Link": "https://www.youtube.com/watch?v=C8Mop2ztYXc&list=LL&index=276"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "297",
    "Title": "Sara Blakely, Founder and CEO, Spanx",
    "Link": "https://www.youtube.com/watch?v=TPURpzGPMxQ&list=LL&index=277"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "298",
    "Title": "How NASA is answering the question: Are we alone? | Shawn Domagal-Goldman | TEDxMidAtlantic",
    "Link": "https://www.youtube.com/watch?v=Lp7BL-UI0Rw&list=LL&index=316&t=548ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "299",
    "Title": "Terraforming Mars with Neil deGrasse Tyson",
    "Link": "https://www.youtube.com/watch?v=7XdkKMhAdnA&list=LL&index=344"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "300",
    "Title": "Parallel Worlds Probably Exist. Here’s Why",
    "Link": "https://www.youtube.com/watch?v=kTXTPe3wahc&list=LL&index=347&t=999ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "301",
    "Title": "Physicist Sean Carroll Explains Parallel Universes to Joe Rogan",
    "Link": "https://www.youtube.com/watch?v=1_ZsDBMPQMI&list=LL&index=348"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "302",
    "Title": "Is our universe the only universe? - Brian Greene",
    "Link": "https://www.youtube.com/watch?v=Vx2RcUQNh6Q&list=LL&index=349&t=2s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "303",
    "Title": "Have you ever imagined how interstellar travel could work? | Ryan Weed | TEDxDanubia",
    "Link": "https://www.youtube.com/watch?v=EBebWBjpWIQ&list=LL&index=350"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "304",
    "Title": "Why Do You Remember The Past But Not The Future?",
    "Link": "https://www.youtube.com/watch?v=F0b8b_ykPQk&list=LL&index=351&t=125s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "305",
    "Title": "Michio Kaku: The Universe in a Nutshell (Full Presentation) | Big Think",
    "Link": "https://www.youtube.com/watch?v=0NbBjNiw4tk&list=LL&index=353"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "306",
    "Title": "Fermi's Paradox and the Psychology of Galactic Empires | Matthew O´Dowd | TEDxTUWien",
    "Link": "https://www.youtube.com/watch?v=3lTvVTnjU5U&list=LL&index=355&t=1067s"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "307",
    "Title": "Why Invest In Making Life Multi-Planetary? Elon Musk",
    "Link": "https://www.youtube.com/watch?v=7SECSxUbXTA&list=LL&index=3688"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "308",
    "Title": "Elon Musk's Master Plan To Colonize Mars",
    "Link": "https://www.youtube.com/watch?v=rriHgO9VoEk&list=LL&index=3900"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "309",
    "Title": "Wormholes Explained – Breaking Spacetime",
    "Link": "https://www.youtube.com/watch?v=9P6rdqiybaw&list=LL&index=393&t=27ss"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "310",
    "Title": "TRUE Limits Of Humanity – The Final Border We Will Never Cross",
    "Link": "https://www.youtube.com/watch?v=uzkD5SeuwzM&list=LL&index=3955"
  },
  {
    "Topic": "Space",
    "series_id": "8",
    "video_id": "311",
    "Title": "What Do Alien Civilizations Look Like? The Kardashev Scale",
    "Link": "https://www.youtube.com/watch?v=rhFK5_Nx9xY&list=LL&index=3999"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "312",
    "Title": "How Indian Engineers Helped Stephen Hawking 'talk'",
    "Link": "https://www.youtube.com/watch?v=FcmKs26SYCI&list=LL&index=1244"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "313",
    "Title": "Stephen Hawking's Voice and the Machine That Powers It",
    "Link": "https://www.youtube.com/watch?v=OTmPw4iy0hk&list=LL&index=125"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "314",
    "Title": "Elon Musk: SpaceX, Mars, Tesla Autopilot, Self-Driving, Robotics, and AI | Lex Fridman Podcast #252",
    "Link": "https://www.youtube.com/watch?v=DxREm3s1scA&list=LL&index=135"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "315",
    "Title": "Quantum Computers, Explained With Quantum Physics",
    "Link": "https://www.youtube.com/watch?v=jHoEjvuPoB8&list=LL&index=136"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "316",
    "Title": "Semiconductor production process explained",
    "Link": "https://www.youtube.com/watch?v=c9arR8T0Qts&list=LL&index=137&t=5s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "317",
    "Title": "Elon Musk: Tesla Autopilot | Lex Fridman Podcast #18",
    "Link": "https://www.youtube.com/watch?v=dEv99vxKjVI&list=LL&index=155&t=4s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "318",
    "Title": "Elon Musk: Neuralink, AI, Autopilot, and the Pale Blue Dot | Lex Fridman Podcast #49",
    "Link": "https://www.youtube.com/watch?v=smK9dgdTl40&list=LL&index=156&t=759s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "319",
    "Title": "The ancient 'computer' that simply shouldn't exist - BBC REEL",
    "Link": "https://www.youtube.com/watch?v=qqlJ50zDgeA&list=LL&index=157&t=332ss"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "320",
    "Title": "David Eagleman: Can a Computer Simulate Consciousness? | Big Think",
    "Link": "https://www.youtube.com/watch?v=oj7FNCahWD8&list=LL&index=1622"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "321",
    "Title": "Computer Consciousness",
    "Link": "https://www.youtube.com/watch?v=GRkOLBr4Gpk&list=LL&index=163"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "322",
    "Title": "Speaker Series: Dean Kamen",
    "Link": "https://www.youtube.com/watch?v=WJvbaUNan6I&list=LL&index=165"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "323",
    "Title": "Exploring Exponential Technologies | Peter Diamandis | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=HJpKxnZ2JeY&list=LL&index=170"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "324",
    "Title": "TIMELAPSE OF FUTURE TECHNOLOGY: 2022 - 4000+",
    "Link": "https://www.youtube.com/watch?v=wD6hyGXRcgk&list=LL&index=172"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "325",
    "Title": "TIMELAPSE OF ARTIFICIAL INTELLIGENCE (2028 – 3000+)",
    "Link": "https://www.youtube.com/watch?v=63yr9dlI0cU&list=LL&index=173"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "326",
    "Title": "Dean Kamen’s FIRST Robotics Competition (Full Segment) | Real Sports w/ Bryant Gumbel | HBO",
    "Link": "https://www.youtube.com/watch?v=18OCZz8yKtU&list=LL&index=175&t=2s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "327",
    "Title": "Segway Scooter & Insulin Pump Inventor Dean Kamen: Why He Collects Old Technology",
    "Link": "https://www.youtube.com/watch?v=m11BYTvGgjg&list=LL&index=176"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "328",
    "Title": "Dean Kamen Shares His Insights on Invention",
    "Link": "https://www.youtube.com/watch?v=QTBlWni5beU&list=LL&index=1777"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "329",
    "Title": "Dean Kamen at TEDMED 2010",
    "Link": "https://www.youtube.com/watch?v=fA5UritjP3c&list=LL&index=184"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "330",
    "Title": "Big Think Interview With Dean Kamen | Big Think",
    "Link": "https://www.youtube.com/watch?v=5DGE1NSe3CQ&list=LL&index=1855"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "331",
    "Title": "Meet Dean Kamen, inventor extraordinaire",
    "Link": "https://www.youtube.com/watch?v=9gYvDmea5kU&list=LL&index=1866"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "332",
    "Title": "2021 Innovation Summit",
    "Link": "https://www.youtube.com/watch?v=ZsyAd_0OD-I&list=LL&index=215"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "333",
    "Title": "Tesla as the World’s Biggest Robot Company:' Elon Musk on AI and U.S. Innovation | WSJ",
    "Link": "https://www.youtube.com/watch?v=lSD_vpfikbE&list=LL&index=2166"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "334",
    "Title": "2021 Innovation Summit Day 2",
    "Link": "https://www.youtube.com/watch?v=ch4K1Fxzh4w&list=LL&index=2177"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "335",
    "Title": "What is Virtual Real Estate and Why Do I Care?",
    "Link": "https://www.youtube.com/watch?v=bfMY5cj3pAA&list=LL&index=218"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "336",
    "Title": "NewSchools Summit 2011: John Doerr and Mark Zuckerberg on innovation and education",
    "Link": "https://www.youtube.com/watch?v=n03zAOadyMA&list=LL&index=245&t=1363s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "337",
    "Title": "Jack Dorsey: The Future Has Already Arrived",
    "Link": "https://www.youtube.com/watch?v=AckvbL5Tfic&list=LL&index=247"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "338",
    "Title": "Elon Musk: Tesla Motors CEO, Stanford GSB 2013 Entrepreneurial Company of the Year",
    "Link": "https://www.youtube.com/watch?v=MBItc_QAUUM&list=LL&index=258"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "339",
    "Title": "2015 GSV Hall of Fame - Ben Horowitz on Bill Campbell",
    "Link": "https://www.youtube.com/watch?v=psqOxqA8EeE&list=LL&index=261"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "340",
    "Title": "Fireside Chat With Ben Horowitz of Andreessen Horowitz and Bill Campbell of Intuit",
    "Link": "https://www.youtube.com/watch?v=7JoQB2V9Ww4&list=LL&index=265"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "341",
    "Title": "Intuit CEO Conversations with Brad Smith and Bill Campbell",
    "Link": "https://www.youtube.com/watch?v=EFWG51nGmGA&list=LL&index=266"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "342",
    "Title": "CHM Revolutionaries: An Evening with Google's Executive Chairman Eric Schmidt",
    "Link": "https://www.youtube.com/watch?v=NfalakTPsnE&list=LL&index=269&t=3s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "343",
    "Title": "LearnStorm Khan Academy | Eric Schmidt & Sal Khan | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=itxoq8VxKIU&list=LL&index=270"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "344",
    "Title": "Tesla Technoking and SpaceX Chief Engineer Elon Musk | Full Interview | Code 2021",
    "Link": "https://www.youtube.com/watch?v=HZP67-DECVw&list=LL&index=275&t=3264s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "345",
    "Title": "Mitt Romney at Silicon Slopes Tech Summit 2018",
    "Link": "https://www.youtube.com/watch?v=s_L4bHN9iv0&list=LL&index=281"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "346",
    "Title": "What Do You Want to Know About Google? | Larry Page Q&A | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=4Mzlp6mIaC4&list=LL&index=292&t=47s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "347",
    "Title": "The Hype Over Quantum Computers, Explained",
    "Link": "https://www.youtube.com/watch?v=u1XXjWr5frE&list=LL&index=310&t=229ss"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "348",
    "Title": "Elon Musk - CEO of Tesla Motors and SpaceX | Entrepreneurship | Khan Academy",
    "Link": "https://www.youtube.com/watch?v=vDwzmJpI4io&list=LL&index=314&t=4ss"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "349",
    "Title": "Mark Zuckerberg & Yuval Noah Harari in Conversation",
    "Link": "https://www.youtube.com/watch?v=Boj9eD0Wug8&list=LL&index=319"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "350",
    "Title": "Elon Musk: Elon Musk's Vision for the Future [Entire Talk]",
    "Link": "https://www.youtube.com/watch?v=SVk1hb0ZOrE&list=LL&index=336&t=7s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "351",
    "Title": "Blockchain: Massively Simplified | Richie Etwaru | TEDxMorristown",
    "Link": "https://www.youtube.com/watch?v=k53LUZxUF50&list=LL&index=342"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "352",
    "Title": "The first 20 hours -- how to learn anything | Josh Kaufman | TEDxCSU",
    "Link": "https://www.youtube.com/watch?v=5MgBikgcWnY&list=LL&index=343"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "353",
    "Title": "What Are You Doing With Your Life? The Tail End",
    "Link": "https://www.youtube.com/watch?v=JXeJANDKwDc&list=LL&index=345"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "354",
    "Title": "10 Reasons to Look Forward to the Future | Johan Norberg | Google Zeitgeist",
    "Link": "https://www.youtube.com/watch?v=QqEUUagTp2I&list=LL&index=357&t=1s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "355",
    "Title": "Can we make quantum technology work? | Leo Kouwenhoven | TEDxAmsterdam",
    "Link": "https://www.youtube.com/watch?v=aUuaWVHhx-U&list=LL&index=358"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "356",
    "Title": "Quantum Computers Explained – Limits of Human Technology",
    "Link": "https://www.youtube.com/watch?v=JhHMJCUmq28&list=LL&index=392&t=2s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "357",
    "Title": "Tech",
    "Link": "https://www.youtube.com/watch?v=zVsHzS70krg&list=LL&index=396"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "358",
    "Title": "Can You Upload Your Mind & Live Forever?",
    "Link": "https://www.youtube.com/watch?v=4b33NTAuF5E&list=LL&index=400&t=739ss"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "359",
    "Title": "Education Reimagined | Sal Khan | Talks at Google",
    "Link": "https://www.youtube.com/watch?v=YkNmxJh_5WE&list=LL&index=401&t=4ss"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "360",
    "Title": "Creating Diversity in Tech: Elevating People of Color",
    "Link": "https://www.youtube.com/watch?v=m530jmutbls&list=LL&index=402&t=83s"
  },
  {
    "Topic": "Tech",
    "series_id": "9",
    "video_id": "361",
    "Title": "Ed Catmull and Larry Ellison on Steve Jobs at D10 (Full)",
    "Link": "https://www.youtube.com/watch?v=B7p2u_hMqK4&list=LL&index=4033"
  }
]

function VideoCard(navigate, index, video) {
  return (
    <div className="column-3" key={index}>
      <div className="row-2 row-2-img-container">
        <img
          src={`https://i.ytimg.com/vi/${video["link"]}/0.jpg`}
          alt="thumbnail"
          height={120}
          width={150}
        />
      </div>
      <div className="row-2">
        <label
          className="centered-italized-small-text"
          style={{ fontWeight: "bold", fontStyle: "none" }}
        >
          {video["Title"]}
        </label>
        
        <div style={{ marginBottom: "5px" }}>
          <Button
            buttonName="Watch"
            buttonColor="#A407B9"
            textColor="#FFFFFE"
            fontWeight="100"
            fontSize="0.8rem"
            onClick={() => navigate(video["video_id"])}
          />
        </div>
      </div>
    </div>
  );
}

class VideosPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      video_id: null,
    };

    this.NavigateToVideo = this.NavigateToVideo.bind(this);
    this.series_id = null;
  }

  componentDidMount() {
    let path_array = window.location.pathname.split('/');
    this.series_id = path_array[path_array.length - 1]
    makeAPICall("GET", `/series/series/${this.series_id}`, undefined, (haserror, response) => {
      if (haserror) {
        console.log(response["message"]);
      } else {
        this.setState({ content: response["message"] });
      }
    });
  }

  NavigateToVideo(video_id) {
    this.setState({ video_id: video_id });
  }

  render() {
    let content = this.state.content;
    let video_id = this.state.video_id;
    if (video_id){
      return <Navigate to={`/inspire/videos/${this.series_id}/${video_id}`} />;
    }
    return (
      <div className="page">
        <div>
          <Quote
            showLine={true}
            quote="We need to bring learning to people instead of people to learning."
            author="Elliot Masie"
            align="center"
          />
        </div>
        <div className="list-container">
          {videoList.map((video, index) => {
            return VideoCard(this.NavigateToVideo, index, video);
          })}
        </div>
      </div>
    );
  }
}

export default VideosPage;
