import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button from "../../reuseables/button/button";
import "./series.css";
import { makeAPICall } from "../../global/global-functions";
import { Navigate } from "react-router-dom";

let realSeriesList = [
  {
    "Topic": "Executive leadership/management",
    "# of Videos": "49",
    "series_id": "1"
  },
  {
    "Topic": "Finance/Investing",
    "# of Videos": "14",
    "series_id": "2"
  },
  {
    "Topic": "History",
    "# of Videos": "6",
    "series_id": "3"
  },
  {
    "Topic": "How to break into coding/tech",
    "# of Videos": "17",
    "series_id": "4"
  },
  {
    "Topic": "How to start a business/startup",
    "# of Videos": "44",
    "series_id": "5"
  },
  {
    "Topic": "Mentality/Mindset",
    "# of Videos": "68",
    "series_id": "6"
  },
  {
    "Topic": "Science",
    "# of Videos": "58",
    "series_id": "7"
  },
  {
    "Topic": "Space",
    "# of Videos": "47",
    "series_id": "8"
  },
  {
    "Topic": "Tech",
    "# of Videos": "50",
    "series_id": "9"
  }
]

function SeriesContainer(navigate, index, series) {
  return (
    <div className="column-3" key={index}>
      <div className="row-2 row-2-img-container">
        {/* <img
          src={`https://i.ytimg.com/vi/${series["url_id"]}/0.jpg`}
          alt="thumbnail"
          height={120}
          width={150}
        /> */}
        <FontAwesomeIcon icon={faFolderOpen} fontSize={"4rem"} />
      </div>
      <div className="row-2">
        <label
          className="row-3"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          {series["Topic"]}
        </label>
        <label className="row-3 centered-italized-small-text">
          {series["# of Videos"]} Videos
        </label>

        <div style={{ width: "100%", marginBottom: "5px" }}>
          <Button
            buttonName="VIEW"
            buttonColor="#3C45A8"
            textColor="#FFFFFE"
            fontWeight="100"
            fontSize="0.8rem"
            onClick={() => navigate(series["series_id"])}
          />
        </div>
      </div>
    </div>
  );
}
class VideoSeriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      series_id: null,
    };

    this.NavigateToSeries = this.NavigateToSeries.bind(this);
  }

  componentDidMount() {
    makeAPICall("GET", "/series/series", undefined, (haserror, response) => {
      if (haserror) {
        console.log(response["message"]);
      } else {
        this.setState({ content: response["message"] });
      }
    });
  }

  NavigateToSeries(series_id) {
    this.setState({ series_id: series_id });
  }

  render() {
    let content = this.state.content;
    let series_id = this.state.series_id;
    if (series_id){
      return <Navigate to={`/inspire/videos/${series_id}`} />;
    }
    return (
      <div className="page" id="video-series">
        <div className="search-container">
          <input
            className="search-input "
            type="text"
            placeholder="Enter a name or keyword..."
          />
          <hr className="horizontal-line" />
        </div>
        <div className="list-container">
          {realSeriesList.map((series, index) => SeriesContainer(this.NavigateToSeries, index, series))}
        </div>
      </div>
    );
  }
}

export default VideoSeriesPage;
//{ seriesList.map((series, index) => SeriesContainer(this.NavigateToSeries, index, series)) }
