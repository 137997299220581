import React from "react";
import {
  makeAPICall,
  validEmail,
  validPassword,
  validPhone,
} from "../../../global/global-functions";
import Button from "../../../reuseables/button/button";
import Static from "../static/static";

import "./signup.css";

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validForm: {
        email: true,
        password: true,
        password_2: true,
        first_name: true,
        last_name: true,
        school: true,
        phone: true,
        exists: true,
        another_school: true,
      },
      anotherSchool: false,
      success: true,
      buttonLoading: false,
      redirecting: false,
      showConfirm: false,
      confirming: false,
      confirmCode: '',
    };
    this.updateForm = this.updateForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.confirmAccount = this.confirmAccount.bind(this);
    this.login = this.login.bind(this);

    this.newUser = {
      email: "",
      password: "",
      password_2: "",
      first_name: "",
      last_name: "",
      school: "",
      phone: "",
      another_school: "",
    };
  }

  updateForm(event) {
    let key = event.target.name;
    let value = event.target.value.trim();
    this.newUser[key] = value;
    if (key === 'school') {
      this.setState({ anotherSchool: value==='-1' });
    }
  }

  confirmAccount(){
    this.setState({ confirming: true });
    const confirmCode = this.state.confirmCode;
    const apiUrl = `/user/confirm/${confirmCode}`;
    makeAPICall("GET", apiUrl, undefined, (haserror, response) => {
      if(response.status === 200){
        this.login();
      }
      else{
      }
    });
  }

  login() {
    makeAPICall(
      "POST", "/user/validations", this.newUser, (haserror, response) => {
        if (haserror) {
        } else {
          localStorage.setItem(
            "mvision_user_token",
            response["Authorization"]
          );
          const user = response['message'];
          localStorage.setItem('user', JSON.stringify(user));
          const userIsAdmin = this.newUser.email==='jackpotvin50@gmail.com';
          localStorage.setItem('userIsAdmin', userIsAdmin?1:0);
          if(userIsAdmin){
            window.open('/admin/user','_self');
          }
          else{
            window.open('/login','_self');
          }
        }
      }
    );
  }

  validateForm() {
    let validForm = {};
    validForm["email"] = validEmail(this.newUser["email"]);
    validForm["password"] = validPassword(this.newUser["password"]);
    validForm["password_2"] =
      this.newUser["password_2"].length > 0 &&
      this.newUser["password"] === this.newUser["password_2"];
    validForm["first_name"] = this.newUser["first_name"].length > 0;
    validForm["last_name"] = this.newUser["last_name"].length > 0;
    validForm["school"] = this.newUser["school"].length > 0;
    validForm["phone"] = validPhone(this.newUser["phone"]);

    if(this.state.anotherSchool){
      validForm['another_school'] = this.newUser["another_school"].length > 0;
      this.newUser['school'] = this.newUser['another_school'];
    }

    this.setState({ validForm: { ...validForm } });
    return Object.values(validForm).reduce((a, b) => {
      return a && b;
    });
  }

  createAccount() {
    let valid = this.validateForm();

    if (valid) {
      this.setState({ buttonLoading: true });
      makeAPICall("POST", "/user/users", this.newUser, (haserror, response, status) => {
        if (response.status === 'failed') {
          if (status === 409) {
            this.setState({ validForm: { ...this.state.validForm, exists: true }, success: false, buttonLoading: false });
          }
        }
        else {
          this.setState({ success: true, redirecting: true, buttonLoading: false, showConfirm: true });
          // localStorage.setItem("newUserEmail", this.state.validForm.email);
          // setTimeout(() => {
          //   window.open('/login', '_self');
          // }, 2000);
        }
      });
    }
  }

  render() {
    return (
      <div id="signup-page">
        <div className="auth-static">
          <Static />
        </div>
        <div className="auth-form">
          {this.state.showConfirm || 
          <div className="auth-form-container">
            <label className="label-header">Create an account</label>
            <div className="auth-form-input">
              <div className="auth-45-div">
                
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  onChange={this.updateForm}
                />
                {this.state.validForm["first_name"] ? null : (
                  <label className="input-error-message">
                    Please enter your first name.
                  </label>
                )}
              </div>
              <div className="auth-45-div">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  onChange={this.updateForm}
                />
                {this.state.validForm["last_name"] ? null : (
                  <label className="input-error-message">
                    Please enter your last name.
                  </label>
                )}
              </div>
            </div>
            <div className="auth-form-input">
              <div className="auth-45-div">
                <input type="email" name="email" placeholder="Email" onChange={this.updateForm} />
                {this.state.validForm["email"] ? null : (
                  <label className="input-error-message">
                    Please enter a valid email address.
                  </label>
                )}
                {(!this.state.success && this.state.validForm["exists"]) && 
                  <label className="input-error-message">
                    User already exists.
                  </label>
                }
              </div>
              <div className="auth-45-div">
                <input type="tel" name="phone" placeholder="Phone Number" onChange={this.updateForm} />
                {this.state.validForm["phone"] ? null : (
                  <label className="input-error-message">
                    Please enter a valid phone number.
                  </label>
                )}
              </div>
            </div>
            <div className="auth-form-input">
              <div className="auth-100-div">
                <select name="school" placeholder="School" onChange={this.updateForm}>
                  <option value="">-- Select School --</option>
                  <option>Harvard University</option>
                  <option>Yale University</option>
                  <option>Princeton University</option>
                  <option>Massachusetts Institute of Technology</option>
                  <option>Columbia University</option>
                  <option>University of Chicago</option>
                  <option>University of Pennsylvania</option>
                  <option>Cornell University</option>
                  <option>Johns Hopkins University</option>
                  <option>University of California, Los Angeles</option>
                  <option>University of Michigan, Ann Arbor</option>
                  <option>University of Virginia</option>
                  <option>University of North Carolina at Chapel Hill</option>
                  <option>University of Texas at Austin</option>
                  <option>University of Washington</option>
                  <option>Cornell University</option>
                  <option>Duke University</option>
                  <option>Northwestern University</option>
                  <option>Carnegie Mellon University</option>
                  <option value={-1}>-- Other --</option>
                </select>
                {this.state.validForm["school"] ? null : (
                  <label className="input-error-message">
                    Please enter the name of the school you attend or attended.
                  </label>
                )}
              </div>
            </div>
            {this.state.anotherSchool &&
              <div className="auth-form-input">
                <div className="auth-100-div">

                  <input
                    type="text"
                    name="another_school"
                    placeholder="School Name"
                    onChange={this.updateForm}
                  />
                  {this.state.validForm["another_school"] ? null : (
                    <label className="input-error-message">
                      Please enter your school name
                    </label>
                  )}
                </div>
              </div>
            }
            <div className="auth-form-input">
              <div className="auth-100-div">

                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.updateForm}
                />
                {this.state.validForm["password"] ? null : (
                  <label className="input-error-message">
                    Password not strong enough! Ensure the password has 1
                    UPPERCASE, 1 lowercase, 1 special and 1 numeric character
                    and is at least 8 characters long.
                  </label>
                )}
              </div>
            </div>
            <div className="auth-form-input">
              <div className="auth-100-div">

                <input
                  type="password"
                  name="password_2"
                  placeholder="Confirm Password"
                  onChange={this.updateForm}
                />
                {this.state.validForm["password_2"] ? null : (
                  <label className="input-error-message">
                    Password entered does not match the password above.
                  </label>
                )}
              </div>
            </div>

            {this.state.validForm["error"] ? null : (
              <label className="input-error-message">
                {this.state.validForm["error"]}
              </label>
            )}
            {this.state.redirecting ? (
              <label id="auth-link">
                <a href="/login"><span>Your user has been created. Redirecting...</span></a>
              </label>
            ) : null}

            <div style={{ margin: "15px 0" }}>
              <Button
                buttonName="Create Account"
                buttonColor="#3C45A8"
                textColor="#FFFFFF"
                onClick={this.createAccount}
                loading={this.state.buttonLoading}
                disabled={this.state.redirecting}
              />
            </div>
            <label id="auth-link">
              Have an account?{" "}
              <a href="/login">
                <span>Log into the account</span>
              </a>
            </label>
          </div>
          }
          {this.state.showConfirm &&     
          <div className="auth-form-container">
            <label className="label-header">Confirm account</label>
            <span style={{textAlign:'center', display:'block'}}>Account created.</span>
            <span style={{textAlign:'center', display:'block'}}>Please check your email to confirm account</span>
            <div className="auth-form-input">
              <div className="auth-100-div">
                <input
                  type="text"
                  name="confirmation"
                  placeholder="Confirmation code"
                  onChange={(e)=>{
                    this.setState({ confirmCode: e.target.value });
                  }}
                />
              </div>
            </div><div style={{ margin: "15px 0" }}>
              <Button
                buttonName="Confirm Account"
                buttonColor="#3C45A8"
                textColor="#FFFFFF"
                onClick={this.confirmAccount}
                loading={this.state.confirming}
                disabled={this.state.confirming || this.state.confirmCode.length > 0}
              />
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default SignUpPage;
