import "./shaper.css";

function ShaperProfilePage() {

    return (
        <div id='shaper-profile-page'>
            <h1>Shaper Profile Library</h1>
            <i>“If you cannot see where you are going, ask someone who has been there before”<br/>–  J Loren Norris</i>
            <hr/>
            <div id='track-container'>
                <div className='track primary'>
                    <h1>Entrepreneur Track</h1>
                    <i>5 Shaper Examples Available</i>
                    <button
                        onClick={event =>  window.location.href='/inspire/shaper'}
                        className="mv-btn mv-light-purple btn-sm no-border">
                        View
                    </button>
                </div>
                <div className='track secondary'>
                    <h1>Entrepreneur Track</h1>
                    <i>5 Shaper Examples Available</i>
                    <button
                        onClick={event =>  window.location.href='/inspire/shaper'}
                        className="mv-btn mv-light-gray no-border purple-grad btn-sm">
                        View
                    </button>
                </div>
                <div className='track primary'>
                    <h1>Entrepreneur Track</h1>
                    <i>5 Shaper Examples Available</i>
                    <button
                        onClick={event =>  window.location.href='/inspire/shaper'}
                        className="mv-btn mv-light-purple btn-sm no-border">
                        View
                    </button>
                </div>
                <div className='track secondary'>
                    <h1>Entrepreneur Track</h1>
                    <i>5 Shaper Examples Available</i>
                    <button
                        onClick={event =>  window.location.href='/inspire/shaper'}
                        className="mv-btn mv-light-gray no-border purple-grad btn-sm">
                        View
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export default ShaperProfilePage;