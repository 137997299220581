import './static.css'
import React, { Component } from 'react';
import Image from 'react';
let Logo = require('./logo_trans1.png');
let Logo1 = require('./mvision logo_high res on white.jpg');
 

function Static(props) {
  return (
    <div className="auth-static-design">
      <div className="content">
        <div className='logo'>
          <img src={Logo1} id = "Logo" alt="MVision Logo" height={180} />
        </div>
      </div>
      <h1 className="app-tagline" id='text1'>Welcome to MVision LifeOS</h1>
      <h2 className="app-tagline" id='text2'>Your Personal Operating System for <br></br>Achieving Maximized Life</h2>
    </div>
  );
}

export default Static
