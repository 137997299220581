import React, { Component } from "react";
import "./user.css";
import { makeAPICall } from "../../global/global-functions";
import {
  faToggleOff, faToggleOn, faSave, faKey, faPen, faClose, faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import DecisionModal from "../../reuseables/yes-no-modal/modal";

const newUser = [
  {name: 'first_name', label: 'First name', type: 'text'},
  {name: 'last_name', label: 'Last name', type: 'text'},
  {name: 'school', label: 'School', type: 'text'},
  {name: 'email', label: 'Email', type: 'text'},
  {name: 'password', label: 'password', type: 'text'},
  {name: 'phone', label: 'Phone', type: 'text'},
]

Modal.setAppElement('#root');

class UserAdmin extends Component {
    constructor(props) {
        super(props);
    }
    state = {
      filter: '',
      newUser: {
        first_name: '',
        last_name: '',
        school: '',
        email: '',
        phone: '',
        subscription: 0,
      },
      dataReady: false,
      editingUserId: 0,
      deletingUserId: 0,
      newPassword: '',
      newUserError: '',
      data: [],
      showNewUserModal: false,
      showPassUpdateModal: false,
    };

    componentDidMount() {
      let currentUser = localStorage.getItem('user');
      currentUser = JSON.parse(currentUser);
      if(currentUser.email !== 'jackpotvin50@gmail.com'){
        window.open('/myvision','_self');
      }
      this.getUserList();
    }

    getUserList(){
      makeAPICall("GET", "/admin/users", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          const data = response["message"];
          this.setState({ data });
        }
        this.setState({dataReady: true});
      });
    }

    saveChanges(user, reset=true) {
      makeAPICall("PATCH", "/admin/users", user, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
        }
        if(reset){
          this.setState({editingUserId: 0, showNewUserModal: false, showPassUpdateModal: false})
        }
      });
    }

    saveNewUser() {
      makeAPICall("POST", "/admin/users", this.state.newUser, (haserror, response) => {
        if (haserror) {
          this.setState({newUserError: response["message"]})
        } else {
          this.getUserList();
          this.setState({editingUserId: 0, showNewUserModal: false, newUserError: ''});
        }
      });
    }

    subscriptionComponent(userId, currentValue){
      const icon = currentValue ? faToggleOn : faToggleOff;
      return <div className={"user-subscription state-"+currentValue}>
        <FontAwesomeIcon onClick={
          ()=>{
            this.updateField(userId, 'subscription', !currentValue);
            this.saveChanges({user_id: userId, subscription: !currentValue}, false);
          }
        } icon={icon}/>
      </div>;
    }

    actionButtons(user){
      if(this.state.editingUserId===user.user_id){
        return <div className="action-container">
          <FontAwesomeIcon
            onClick={()=>{
              this.saveChanges(user)}}
            icon={faSave}/>&nbsp;
          <FontAwesomeIcon
            onClick={()=>{
              this.setState({editingUserId: 0})}}
            icon={faClose}/>
        </div>
      }
      return <div className="action-container">
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({editingUserId: user.user_id})}}
          icon={faPen}/>
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({editingUserId: user.user_id, newPassword: '', showPassUpdateModal: true})}}
          icon={faKey}/>
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({deletingUserId: user.user_id})}}
          icon={faTrash}/>
      </div>
    }

    inputText(user, field){
      const readOnly = this.state.editingUserId !== user.user_id;
      return <input
        onChange={(e)=>{this.updateField(user.user_id, field, e.target.value)}}
        value={user[field]}
        readOnly={readOnly}/>
    }

    updateField(id, field, value){
      let data = this.state.data;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if(element.user_id===id){
          data[index][field] = value;
        }
      }
      this.setState({data});
    }

    newUserComponent(){
      const update = (value, field) => {
        let newUser = this.state.newUser;
        newUser[field] = value;
        this.setState({newUser});
      }
      const style = {display: 'inline-block', width: 120}
      return <ReactModal
          id='new-user-modal'
          isOpen={this.state.showNewUserModal}
          contentLabel="Minimal Modal Example">
        <div id="new-user-form" style={{height: 220}}>
          {newUser.map((field, i)=>
            <div key={'input-'+i} className="field-group">
              <label style={style}>{field.label}: </label>
              <input type={field.type} defaultValue={this.state.newUser[field.name]} onChange={
                (e)=>{update(e.target.value, field.name)}}/>
            </div>
          )}
          <label style={{display: 'initial'}}>
            Subscription: &nbsp;&nbsp;
            <input type='checkbox' value={1} defaultChecked={this.state.newUser['subscription']===1} onChange={
              ()=>{update(this.state.newUser['subscription']===1?0:1, 'subscription')}}/>
          </label>
          <br/>
          {this.state.newUserError &&
            <label style={{
              color: 'red', backgroundColor: '#ffd1d1', padding: '2px 8px'}} className="error">{this.state.newUserError}</label>
          }
          <div className="actions">
            <button className="mv-btn mv-blue btn-xs" onClick={()=>{this.saveNewUser()}}>Save</button>
            <button className="mv-btn mv-blue-light btn-xs" onClick={()=>{this.toggleNewUserModal()}}>Cancel</button>
          </div>
        </div>
      </ReactModal>;
    }

    updatePassword(){
      return <ReactModal
        id='password-modal'
        isOpen={this.state.showPassUpdateModal}>
        <div className="new-user-form">
          <div className="field-group">
            <label>New password: </label>
            <input type='text' defaultValue={this.state.newPassword} onChange={
              (e)=>{this.setState({newPassword: e.target.value})}}/>
              <div className="actions">
                <button className="mv-btn mv-blue btn-xs" onClick={()=>{
                  this.saveChanges({user_id: this.state.editingUserId, password: this.state.newPassword}, true)
                }}>Save</button>
                <button className="mv-btn mv-blue-light btn-xs" onClick={()=>{
                  this.togglePasswordUpdateModal()}}>Cancel</button>
              </div>
          </div>
        </div>

      </ReactModal>
    }

    toggleNewUserModal(){
      let state = {showNewUserModal: !this.state.showNewUserModal}
      if(this.state.showNewUserModal){
        state.newUserError = '';
        state.newUser= {
          first_name: '',
          last_name: '',
          school: '',
          email: '',
          phone: '',
          subscription: 0,
        }
      }
      this.setState(state);
    }

    togglePasswordUpdateModal(){
      let update = {showPassUpdateModal: !this.state.showPassUpdateModal}
      if(this.state.showPassUpdateModal){
        update.editingUserId= 0;
      }
      this.setState(update);
    }

    deleteUser(){
      makeAPICall("DELETE", "/admin/users", {user_id: this.state.deletingUserId}, (haserror, response) => {
        if (haserror) {
          alert(response['message'])
        } else {
          this.getUserList();
        }
        this.setState({deletingUserId: 0});
      });
    }

    filterUsers(){
      const {data, filter} = this.state;
      if(filter===''){
        return data;
      }
      return data.filter(user=>
        user.first_name.toLowerCase().includes(filter) ||
        user.last_name.toLowerCase().includes(filter)
      )
    }

    render(){
      const data = this.filterUsers();
      return (
        <div id="user-admin">
          <h3>Users&nbsp;&nbsp;
            <button className="new-user mv-btn btn-sm no-border mv-blue" onClick={()=>{this.toggleNewUserModal()}}>Create new user</button>
          </h3>
          <div className="input-field">
            <label>Filter: </label>
            <input type="text" defaultValue={this.state.filter} onChange={(e)=>{this.setState({filter: e.currentTarget.value.toLowerCase()})}}/>
          </div>
          <br/>
          <div>
            <table className="datatable">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>School</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Subscription</th>
                  <th>Registered On</th>
                  <th width={80}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{this.inputText(row, 'first_name')}</td>
                    <td>{this.inputText(row, 'last_name')}</td>
                    <td>{this.inputText(row, 'school')}</td>
                    <td>{this.inputText(row, 'email')}</td>
                    <td>{this.inputText(row, 'phone')}</td>
                    <td className="center">
                      {this.subscriptionComponent(row.user_id, row.subscription)}
                    </td>
                    <td>{this.inputText(row, 'registered_on')}</td>
                    <td className="center">
                      {this.actionButtons(row)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {this.state.deletingUserId > 0 &&
            <DecisionModal
              description="Are you sure you want to delete this user?"
              yesFunction={()=>{this.deleteUser()}}
              noFunction={() => {this.setState({deletingUserId: 0})}}
              loadingButton={false}
            />
          }
          {this.newUserComponent()}
          {this.updatePassword()}
          <br/>
        </div>
      );        
    }

}

export default UserAdmin;
  