import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faGaugeHigh,
  faBriefcase,
  faFireFlameCurved,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "./logo-mvision.jpg";

import React from "react";
import "./navbar.css";
import { getUserInfo, getAvatarsUrl } from "../../global/global-functions";
import UserContext from "../../reuseables/user-context/user-context";

import ProfileDropDown from "./profile-dropdown";

const NAV_ROUTES = [
  {
    name: "MyVision",
    font: faBoltLightning,
    route: "/myvision",
  },
  {
    name: "MyMetrics",
    font: faGaugeHigh,
    route: "/mymetrics",
  },
  {
    name: "Inspire",
    font: faFireFlameCurved,
    prefix: "/inspire",
    route: "/inspire",
  },
  {
    name: "MyTools",
    font: faBriefcase,
    route: "/mytools",
  },
];

const OPTIONS_ROUTES = [
  {
    name: "Profile",
    route: "/profile",
  },
  {
    name: "Legal",
    route: "/legal",
  },
  {
    name: "Report a Bug",
    route: "/report",
  },
];

const avatarUrlRoot = getAvatarsUrl();

class NavBar extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      showProfileOptions: false,
    };
    this.toggleSubRoutes = this.toggleSubRoutes.bind(this);
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;
    if (user === null) {
      getUserInfo((haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let data = response["message"];
          setUser(data);
          this.setState({ userInfo: data });
        }
      });
    }else{
      this.setState({ userInfo: user });
    }
  }

  toggleSubRoutes(e) {
    let id = e.currentTarget.id.split("-")[2];
    const nav_items = document.getElementsByClassName("nav-item");

    for (let nav_item of nav_items) {
      if (nav_item.id !== `route-item-${id}`) {
        nav_item.childNodes.forEach((node) => {
          if (node.tagName === "DIV") {
            node.style.display = "none";
          }
        });
      } else {
        nav_item.childNodes.forEach((node) => {
          if (node.tagName === "DIV") {
            node.style.display = "block";
          }
        });
      }
    }
  }

  render() {
    let icon = null;
    if (this.state.userInfo) {
      if (this.state.userInfo["profile_picture"]) {
        icon = (
          <img src={avatarUrlRoot+this.state.userInfo["profile_picture"]} alt="user icon" />
          );
        } else {
          icon = (
          // <img src="https://www.w3schools.com/howto/img_avatar.png" alt="user icon" />
          <label className="no-avatar" style={{backgroundImage: 'url(/logo_white.png)'}}>
            <span>
              <br/>
              {this.state.userInfo["first_name"][0] + this.state.userInfo["last_name"][0]}
            </span>
          </label>
        );
      }
    }

    return (
      <div id="nav-bar">
        <div className="sub-nav">
          <div id="nav-bar-logo" className="nav-bar-column">
            <a href="/landing">
              <button className="logo-btn no-btn">
                <img id="logo" src={Logo} alt="MVision Logo" height={40} />
                <br/>
                <span>LifeOS | Manifest Your Life Vision</span>
              </button>
            </a>
          </div>
          <div id="nav-bar-routes" className="nav-bar-column">
            {NAV_ROUTES.map((route, index) => {
              let has_subroutes = typeof route["route"] !== "string";
              let url = has_subroutes
                ? route["prefix"] + route["route"][0][1]
                : route["route"];

              return (
                <div
                  key={index}
                  id={`route-div-${index}`}
                  onMouseEnter={this.toggleSubRoutes}
                >
                  <div className="nav-item" id={`route-item-${index}`}>
                    <Link to={url}>
                      <FontAwesomeIcon icon={route.font} />
                      <label className="route-label"> {route.name}</label>
                    </Link>
                    {has_subroutes ? (
                      <div className="nav-bar-sub-routes">
                        {route["route"].map((subroute, indx) => {
                          return (
                            <Link
                              key={indx}
                              to={route["prefix"] + subroute[1]}
                              className="nav-sub-item"
                            >
                              <label>{subroute[0]}</label>
                            </Link>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div id="nav-bar-profile" className="nav-bar-column">
          <ProfileDropDown user={this.state.userInfo} icon={icon}/>
        </div>
      </div>
    );
  }
}

export default NavBar;
