import React, { Component } from "react";
import "./content.css";
import { makeAPICall, capitalize } from "../../global/global-functions";
import {
  faToggleOff, faToggleOn, faSave, faImage, faPen, faClose, faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import DecisionModal from "../../reuseables/yes-no-modal/modal";

const newContent = [
  {name: 'title', label: 'Title', type: 'text'},
  {name: 'description', label: 'Description', type: 'text'},
  {name: 'vote_count', label: 'Vote count', type: 'number'},
  {name: 'author', label: 'Author', type: 'text'},
  {name: 'thumbnail', label: 'Thumbnail', type: 'text'},
]

Modal.setAppElement('#root');

class ContentAdmin extends Component {
    constructor(props) {
        super(props);
    }
    state = {
      pagination: {
          page: 1,
          perPage: 12
      },
      filter: '',
      newContent: {
        title: '',
        description: '',
        content_type_id: '',
        vote_count: 0,
        author: '',
        thumbnail: '',
        active: 1,
      },
      dataReady: false,
      editingContentId: 0,
      deletingContentId: 0,
      newImage: '',
      newUserError: '',
      data: [],
      content_types: [],
      showNewContentModal: false,
      showImageUpdateModal: false,
    };

    componentDidMount() {
      let currentUser = localStorage.getItem('user');
      currentUser = JSON.parse(currentUser);
      if(currentUser.email !== 'jackpotvin50@gmail.com'){
        window.open('/myvision','_self');
      }
      this.getContentList();
      this.getContentTypes();
    }

    getContentList(){
      makeAPICall("GET", "/admin/content", undefined, (haserror, response) => {
        if (haserror) {
          // console.log(response["message"]);
        } else {
          const data = response["message"];
          this.setState({ data });
        }
        this.setState({dataReady: true});
      });
    }
    getContentTypes(){
      makeAPICall("GET", "/admin/content-type", undefined, (haserror, response) => {
        if (haserror) {
          // console.log(response["message"]);
        } else {
          const content_types = response["message"];
          this.setState({ content_types });
        }
        this.setState({dataReady: true});
      });
    }

    saveChanges(user, reset=true, udpateData=false) {
      makeAPICall("PATCH", "/admin/content", user, (haserror, response) => {
        if (haserror) {
        } else {
        }
        if(reset){
          let update = {editingContentId: 0, showNewContentModal: false, showImageUpdateModal: false};
          if(udpateData){
            let data = this.state.data;
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              if(element.id === user.id){
                data[index] = {...data[index], ...user}
                break;
              }
            }
            update.data = data;
          }
          this.setState(update)
        }
      });
    }

    saveNewContent() {
      makeAPICall("POST", "/admin/content", this.state.newContent, (haserror, response) => {
        if (haserror) {
          this.setState({newUserError: response["message"]})
        } else {
          this.getContentList();
          this.setState({editingContentId: 0, showNewContentModal: false, newUserError: ''});
        }
      });
    }

    activeComponent(contentId, currentValue){
      const icon = currentValue ? faToggleOn : faToggleOff;
      return <div className={"user-subscription state-"+currentValue}>
        <FontAwesomeIcon onClick={
          ()=>{
            this.updateField(contentId, 'active', !currentValue);
            this.saveChanges({id: contentId, subscription: !currentValue}, false);
          }
        } icon={icon}/>
      </div>;
    }

    actionButtons(content){
      if(this.state.editingContentId===content.id){
        return <div className="action-container">
          <FontAwesomeIcon
            onClick={()=>{
              this.saveChanges(content)}}
            icon={faSave}/>&nbsp;
          <FontAwesomeIcon
            onClick={()=>{
              this.setState({editingContentId: 0})}}
            icon={faClose}/>
        </div>
      }
      return <div className="action-container">
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({editingContentId: content.id})}}
          icon={faPen}/>&nbsp;
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({editingContentId: content.id, newImage: content.thumbnail, showImageUpdateModal: true})}}
          icon={faImage}/>&nbsp;
        <FontAwesomeIcon
          onClick={()=>{
            this.setState({deletingContentId: content.id})}}
          icon={faTrash}/>
      </div>
    }

    inputText(content, field, type='text'){
      const readOnly = this.state.editingContentId !== content.id;
      return <input type={type}
        onChange={(e)=>{this.updateField(content.id, field, e.target.value)}}
        value={content[field]}
        readOnly={readOnly}/>
    }

    inputTextArea(content, field){
      const readOnly = this.state.editingContentId !== content.id;
      if(readOnly){
        return <input value={content[field]} readOnly={true}/>
      }
      return <textarea
        onChange={(e)=>{this.updateField(content.id, field, e.target.value)}}
        value={content[field]}
        readOnly={readOnly}/>
    }

    inputTextRel(content, relation, field){
      const readOnly = this.state.editingContentId !== content.id;
      if(readOnly){
        return <input
          value={content[relation][field]}
          readOnly={true}/>
      }
      else{
        return <select
          defaultValue={content.content_type_id}
          onChange={(e)=>{
            this.updateField(content.id, 'content_type_id', e.target.value)
          }}>
          <option>Select type</option>
          {this.state.content_types.map(t=>
            <option value={t.id} key={t.id}>{capitalize(t.name)}</option>
          )}
        </select>
      }
    }

    updateField(contentId, field, value){
      let data = this.state.data;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if(element.id==contentId){
          data[index][field] = value;
          if(field=='content_type_id'){
            data[index].content_type = this.state.content_types.filter(type=>type.id===value)[0];
          }
        }
      }
      this.setState({data});
    }

    newContentComponent(){
      const update = (value, field) => {
        let newContent = this.state.newContent;
        newContent[field] = value;
        this.setState({newContent});
      }
      const style = {display: 'inline-block', width: 120}
      return <ReactModal
          id='new-user-modal'
          isOpen={this.state.showNewContentModal}
          contentLabel="Minimal Modal Example">
        <div id="new-user-form" style={{height: 220}}>
          {newContent.map((field, i)=>
            <div key={'input-'+i} className="field-group">
              <label style={style}>{field.label}: </label>
              <input type={field.type} defaultValue={this.state.newContent[field.name]} onChange={
                (e)=>{update(e.target.value, field.name)}}/>
            </div>
          )}
          {this.state.newContent.thumbnail &&
            <>
              <img src={this.state.newContent.thumbnail} height={100}/>
              <br/>
            </>
          }
          <label style={{display: 'initial'}}>
            Content type: &nbsp;&nbsp;
            <select defaultValue={this.state.newContent.content_type_id} onChange={(e)=>{update(e.target.value, 'content_type_id')}}>
              <option>Select type</option>
              {this.state.content_types.map(t=><option key={t.id} value={t.id}>{capitalize(t.name)}</option>)}
            </select>
          </label>
          <br/>
          <label style={{display: 'initial'}}>
            Active: &nbsp;&nbsp;
            <input type='checkbox' value={1} defaultChecked={this.state.newContent.active===1} onChange={
              ()=>{update(this.state.newContent.active===1?0:1, 'active')}}/>
          </label>
          <br/>
          {this.state.newContentError &&
            <label style={{
              color: 'red', backgroundColor: '#ffd1d1', padding: '2px 8px'}} className="error">{this.state.newContentError}</label>
          }
          <div className="actions">
            <button className="mv-btn mv-blue btn-xs" onClick={()=>{this.saveNewContent()}}>Save</button>
            <button className="mv-btn mv-blue-light btn-xs" onClick={()=>{this. toggleNewContentModal()}}>Cancel</button>
          </div>
        </div>
      </ReactModal>;
    }

    updateImage(){
      // let currentImage = '';
      // for (let index = 0; index < this.state.data.length; index++) {
      //   const element = this.state.data[index];
      //   if(element.id === this.state.editingContentId){
      //     currentImage = element.thumbnail;
      //     break;
      //   }
      // }
      return <ReactModal
        id='image-modal'
        isOpen={this.state.showImageUpdateModal}>
        <div className="new-user-form">
          <div className="field-group">
            <label>Content Image: </label>
            <br/>
            <img src={this.state.newImage} height={100}/>
            <br/>
            <input type='text' defaultValue={this.state.newImage} onChange={
              (e)=>{this.setState({newImage: e.target.value})}}/>
              <div className="actions">
                <button className="mv-btn mv-blue btn-xs" onClick={()=>{
                  this.saveChanges({id: this.state.editingContentId, thumbnail: this.state.newImage}, true, true)
                }}>Save</button>
                <button className="mv-btn mv-blue-light btn-xs" onClick={()=>{
                  this.togglePasswordUpdateModal()}}>Cancel</button>
              </div>
          </div>
        </div>

      </ReactModal>
    }

    toggleNewContentModal(){
      let state = {showNewContentModal: !this.state.showNewContentModal}
      if(this.state.showNewContentModal){
        state.newContentError = '';
        state.newContent= {
          title: '',
          description: '',
          content_type_id: '',
          vote_count: 0,
          author: '',
          thumbnail: '',
          active: 1,
        }
      }
      this.setState(state);
    }

    togglePasswordUpdateModal(){
      let update = {showImageUpdateModal: !this.state.showImageUpdateModal}
      if(this.state.showImageUpdateModal){
        update.editingContentId= 0;
      }
      this.setState(update);
    }

    deleteUser(){
      makeAPICall("DELETE", "/admin/content", {id: this.state.deletingContentId}, (haserror, response) => {
        if (haserror) {
          alert(response['message'])
        } else {
          this.getContentList();
        }
        this.setState({deletingContentId: 0});
      });
    }

    filterContent(){
      const {data, filter} = this.state;
      if(filter===''){
        return data;
      }
      return data.filter(user=>
        user.title.toLowerCase().includes(filter) ||
        user.description.toLowerCase().includes(filter) ||
        user.author.toLowerCase().includes(filter) ||
        user.content_type.name.toLowerCase().includes(filter)
      )
    }

    getPage(){
      const {filter, pagination} = this.state;
      let data = this.state.data.filter(user=>
        user.title.toLowerCase().includes(filter) ||
        user.description.toLowerCase().includes(filter) ||
        user.author.toLowerCase().includes(filter) ||
        user.content_type.name.toLowerCase().includes(filter)
      )
      const pages = Math.ceil(data.length/pagination.perPage);
      const startingFrom = (pagination.page-1)*pagination.perPage;
      data = data.splice(startingFrom, pagination.perPage);
      return {data, pages}
    }
    setPage(page){
      let {pagination} = this.state;
      if(pagination.page===page){
          return false;
      }
      pagination.page = page;
      this.setState({pagination})
    }
    filter(value){
      const pagination = {
        page: 1,
        perPage: 12
      }
      this.setState({pagination, filter: value.toLowerCase()});
    }

    render(){
      const page = this.getPage();
      return (
        <div id="user-admin">
          <h3>Knowledge Library Content&nbsp;&nbsp;
            <button
              className="new-user mv-btn btn-sm no-border mv-blue"
              onClick={()=>{this.toggleNewContentModal()}}>Add new content</button>
          </h3>
          <div className="input-field">
            <label>Filter: </label>
            <input type="text" defaultValue={this.state.filter} onChange={(e)=>{this.filter(e.target.value)}}/>
          </div>
          <br/>
          {page.data.length>0 &&
            <div>
              <table className="datatable">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Votes</th>
                    <th>Author</th>
                    <th>Active</th>
                    <th width={80}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {page.data.map((row, index) => (
                    <tr key={index}>
                      <td>{this.inputText(row, 'title')}</td>
                      <td>{this.inputTextArea(row, 'description')}</td>
                      <td>{this.inputTextRel(row, 'content_type', 'name')}</td>
                      <td>{this.inputText(row, 'vote_count', 'number')}</td>
                      <td>{this.inputText(row, 'author')}</td>
                      <td className="center">
                        {this.activeComponent(row.id, row.active)}
                      </td>
                      <td className="center">
                        {this.actionButtons(row)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          {page.data.length < 1 &&
            <div className='no-data'>No data</div>
          }
          <br/>
          <div className='pagination'>
          {[...Array(page.pages+1).keys()].splice(1).map((n, index)=>
            <button
              key={'pagination-'+index}
              className='mv-btn mv-blue btn-xs'
              disabled={n===this.state.pagination.page}
              onClick={()=>{this.setPage(n)}}>{n}</button>
          )}
          </div>
          {this.state.deletingContentId > 0 &&
            <DecisionModal
              description="Are you sure you want to delete this content?"
              yesFunction={()=>{this.deleteUser()}}
              noFunction={() => {this.setState({deletingContentId: 0})}}
              loadingButton={false}
            />
          }
          {this.newContentComponent()}
          {this.updateImage()}
          <br/>
        </div>
      );        
    }

}

export default ContentAdmin;
  