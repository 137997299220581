import React from "react";
import "./landing.css";
import Logo from "./Logo_V2.png";
import LPage from "./LPage_Image.png";
import Carousel from "nuka-carousel"
import { faChevronCircleLeft, faChevronCircleRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {scrollAnchor} from '../../global/global-functions';
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import {removeClass} from '../../global/global-functions'

class LandingPage extends React.Component{

    hideDropDropdown(dropdown){
        setTimeout(() => {
            dropdown.hide();
        }, 200);
    }
    dropDowncomponent(){
        const ref = React.createRef();
        return <Dropdown className="landing-links" ref={ref}>
        <DropdownTrigger><FontAwesomeIcon icon={faBars}/></DropdownTrigger>
        <DropdownContent>
            <ul>
                <li><a onClick={()=>{this.hideDropDropdown(ref.current)}} href="#product">Our Product</a></li>
                <li><a href="/schools">For Schools</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/contact">Contact Us</a></li>
            </ul>
        </DropdownContent>
    </Dropdown>;
    }
    render(){
        return (
            <div id="whole">
                <div>
                    <div className="topnav" id="myTopnav">
                        <img id="logo" src={Logo} alt="MVision Logo" height={60} />
                        <a href="/login" id="login">Login</a>
                        {this.dropDowncomponent()}
                        <a href="/contact">Contact Us</a>
                        <a href="/about">About Us</a>
                        <a href="/schools">For Schools</a>
                        <a onClick={scrollAnchor} href="#product">Our Product</a>
                        <a href="javascript:void(0);" className="icon">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div className="landing-content">
                    <div className="left">
                        <h1>Welcome to MVision LifeOS</h1>
                        <h2>
                            A Beautiful Personal Operating System 
                            for achieving maximized life
                        </h2>
                        <br/>
                        <br/>
                        <br/>
                        <div>
                            <a id="button1" href="#product">Learn more</a>
                            <a id="button2" href="/signup">Get Started</a>
                        </div>
                    </div>
                    <div className="right">
                        <img src={LPage} alt="Random Image" height={300} />
                    </div>
                </div>

                <div id='product'>
                    <div id="about-content">
                        <div id="header">
                            <br/>
                            <h1>Our Product | MVision LifeOS</h1>
                            <br/>
                            <h2>
                                Develop your personal life vision 0 to 1, driving 
                                more purposeful & directed life
                            </h2>
                            <br/>
                        </div>
                        <div id="carousel-container">
                            <Carousel 
                                renderCenterLeftControls={({ previousSlide }) => (
                                    <button onClick={previousSlide} className="carousel-control">
                                        <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                    </button>
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <button onClick={nextSlide} className="carousel-control">
                                        <FontAwesomeIcon icon={faChevronCircleRight}/>
                                    </button>
                                )}
                                wrapAround autoplay autoplayInterval={5000}>
                                <img src="/capture1.jpg" />
                                <img src="/capture2.jpg" />
                                <img src="/capture3.jpg" />
                            </Carousel>
                        </div>
                        <div id="footer">
                            <hr/>
                            <h1>How it Works</h1>
                            <h2>
                                MVision is an easy-to-use software platform for young people 
                                to<br/>develop and begin to achieve their fulfilled life vision
                            </h2>
                            <br/>
                        </div>
                        <br/>
                        <div id="actions">
                            <a id="button2" href="/signup">Build My Vision <img className="infinity-emoji" src="/infinity-emoji.png" /></a>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;