import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { makeAPICall } from "../../global/global-functions";
import CircularButton from "../../reuseables/button/circularbutton";
import "./series.css";
import "./videos.js";
import "./series.js";

class PlayPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      video_info: {
        video_name: null,
        youtube_id: null,
      },
      last_comment_id: 0,
    };

    this.typedMessage = this.typedMessage.bind(this);
    this.getNewComments = this.getNewComments.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.new_message = "";
    this.newCommentInterval = null;
    this.series_id = null;
    this.video_id = null;
  }

  componentDidMount() {
    let path_array = window.location.pathname.split("/");
    let length = path_array.length;

    this.series_id = path_array[length - 2];
    this.video_id = path_array[length - 1];
    makeAPICall(
      "GET",
      `/series/series/${this.series_id}/${this.video_id}`,
      undefined,
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          this.setState({ video_info: response["message"] });
        }
      }
    );

    this.getNewComments();
    this.newCommentInterval = setInterval(this.getNewComments, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.newCommentInterval);
    this.newCommentInterval = null;
  }

  componentDidUpdate() {
    let last_comment_id = this.state.last_comment_id;

    if (last_comment_id !== 0) {
      let last_comment_container = document.getElementById(
        `comment-${last_comment_id}`
      );
      last_comment_container.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }

  typedMessage(e) {
    this.new_message = e.currentTarget.value;
  }

  getNewComments() {
    let content = JSON.parse(JSON.stringify(this.state.content));
    let last_comment_id = this.state.last_comment_id;
    makeAPICall(
      "GET",
      `/series/videos/${this.video_id}?last_comment_id=${last_comment_id}`,
      undefined,
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let res = response["message"];
          res.forEach((comment) => {
            if (comment["comment_id"] > last_comment_id) {
              content.push(comment);
            }
          });
          last_comment_id =
            res.length > 0
              ? res[res.length - 1]["comment_id"]
              : last_comment_id;
          this.setState({ content: content, last_comment_id: last_comment_id });
        }
      }
    );
  }

  sendMessage() {
    makeAPICall(
      "POST",
      `/series/videos/${this.video_id}`,
      {
        comment: this.new_message,
      },
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        }else{
          this.new_message = "";
          document.getElementById("send-box").value = "";
        }
      }
    );
  }

  render() {
    let video = this.state.video_info;
    let content = this.state.content;
    return (
      <div className="page" id="play-page">
        <div id="play-page-video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/q_j-jRttfV0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div id="comments">
          <div id="comment-header">
            <label>Comments</label>
          </div>
          <div id="comment-body">
            <label className="centered-italized-small-text">
              Share helpful insights, other aligned info, or valuable quotes!
            </label>
            <hr className="horizontal-line" style={{ width: "50%" }} />
            <div id="comment-display">
              {content.map((comment, index) => {
                let mine = comment["my_comment"];
                let do_not_show_username =
                  mine ||
                  (index !== content.length - 1 &&
                    content[index + 1]["sender_id"] === comment["sender_id"]);
                return (
                  <div
                    key={index}
                    id={"comment-" + comment["comment_id"]}
                    className="comment-box-outer"
                    style={{
                      justifyContent: mine ? "end" : "start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                      }}
                    >
                      <div
                        className="comment-box-inner"
                        style={{
                          backgroundColor: mine ? "#D5F3FE" : "#fffffe",
                        }}
                      >
                        <label>{comment["comment"]}</label>
                      </div>
                      {do_not_show_username ? null : (
                        <label
                          className="italized-small-text"
                          style={{ marginTop: "2px" }}
                        >
                          {comment["sender_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div id="comment-send-options">
            <input
              type="text"
              id="send-box"
              placeholder="Enter Message Here..."
              onChange={this.typedMessage}
            />
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "-0.3rem",
              }}
            >
              <CircularButton
                icon={faPaperPlane}
                buttonColor="#3C45A8"
                textColor="#fffffe"
                onClick={this.sendMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayPage;
