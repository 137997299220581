import React, { Component } from "react";
import "./metrics.css";
import { makeAPICall } from "../../global/global-functions";
import {
  faUserCircle as userIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MetricsAdmin extends Component {
    constructor(props) {
        super(props);
    }
    state = {
      total: [
        // {label: 'Users', total: 288, week: 20, month: 140},
        // {label: 'Big Wins', total: 254, week: 30, month: 120},
        // {label: 'Steping Stones', total: 387, week: 82, month: 189},
        // {label: 'Distinct Schools', total: 108, week: 23, month: 56},
      ],
      average: [
        // {label: 'Big Wins', total: 42, week: 8, month: 19},
        // {label: 'Steping Stones', total: 87, week: 14, month: 35},
        // {label: 'Sections', total: 322, week: 67, month: 159},
        // {label: 'Headers', total: 510, week: 107, month: 259},
      ],
    };

    componentDidMount() {
      makeAPICall("GET", "/admin/metrics", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          const data = response["message"];
          this.setState({ ...data });
        }
        this.setState({metricsReady: true});
      });
    }


    render(){
      const {total, average} = this.state;
      return <div id="metrics-admin">
        <div>
          <div id="legend">
            <div><span>7 days (weekly trend)</span></div>
            <div><span>30 days (monthly trend)</span></div>
          </div>
          <div id="content">
            <div>
              <h1>Overview <span>(Totals)</span></h1>
              <table className="results">
                {total.map((t, i)=>
                  <tr key={'key-total-'+i}>
                    <td>
                      <label>{t.label}</label>
                      <div>
                        <span className={t.label === 'Users'? 'hl':''}>
                          <span>{t.total}</span>
                        </span>
                        <span className="week">
                          <div>
                            <FontAwesomeIcon icon={userIcon}/>
                            <span>{t.week}</span>
                          </div>
                        </span>
                        <span className="month">
                          <div>
                            <FontAwesomeIcon icon={userIcon}/>
                            <span>{t.month}</span>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div>
              <h1>Overview <span>(Per Profile Average)</span></h1>
              <table className="results">
                {average.map((t, i)=>
                  <tr key={'key-total-'+i}>
                    <td>
                      <label>{t.label}</label>
                      <div>
                        <span>
                          <span>{t.total}</span>
                        </span>
                        <span className="week">
                          <div>
                            <FontAwesomeIcon icon={userIcon}/>
                            <span>{t.week}</span>
                          </div>
                        </span>
                        <span className="month">
                          <div>
                            <FontAwesomeIcon icon={userIcon}/>
                            <span>{t.month}</span>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>;
    }

}

export default MetricsAdmin;
  