import party from "party-js";
function getApiUrl(){
  return getServerUrl()+'/api'
}
function getStaticUrl(){
  return getServerUrl()+'/api-static'
}
function getAvatarsUrl(){
  return getStaticUrl()+'/avatar/';
}
function getServerUrl(){
  const API_URL =
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_API_URL
      : process.env.REACT_APP_PROD_API_URL;
  return API_URL;
}
function getHeaders(){
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  let token = localStorage.getItem("mvision_user_token");
  if (token !== null && token.length > 0) {
    headers["Authorization"] = "Bearer " + token;
  }
  return headers;
}
async function makeAPICall(verb, endpoint, body, callback) {
  const API_PREFIX = getApiUrl();

  const headers = getHeaders();
  const url = `${API_PREFIX}${endpoint}`;
  fetch(url, {
    method: verb.toUpperCase(),
    body: JSON.stringify(body),
    headers: headers,
  })
    .then(async (response) => {
      if(response.status === 401){
        LogOut();
        return false;
      }
      let haserror = !response.ok;
      const result = await response.json();
      callback(haserror, result, response.status);
    })
    .catch((err) => {
      callback(true, {
        message:
          "Internal Server Error: Please try again later. Error - " +
          err.toString(),
      });
    });
}

function validateRole(){
  const userIsAdmin = localStorage.getItem("userIsAdmin");
  if(userIsAdmin==='1'){
    return 'admin';
  }
  else if(userIsAdmin==='0'){
    return 'user';
  }
  else{
    window.location.href = window.location.origin
    return false;
  }
}

function validEmail(email) {
  return (
    email !== undefined &&
    email !== null &&
    email.length > 0 &&
    email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
    ) !== null
  );
}

function validPassword(password) {
  let count = 0;
  let i = 0;
  let ch = null;

  while (i < password.length && count !== 4) {
    ch = password[i];
    if (ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90) {
      count += 1;
    } else if (ch.charCodeAt() >= 97 && ch.charCodeAt() <= 122) {
      count += 1;
    } else if (ch.charCodeAt() >= 48 && ch.charCodeAt() <= 57) {
      count += 1;
    } else {
      count += 1;
    }

    i += 1;
  }

  return password.length >= 8 && count === 4;
}

function validPhone(phone) {
  return phone.match(/^[0-9]{7,}[0-9]$/) !== null;
}

function validateUserToken(callback) {
  let token = localStorage.getItem("mvision_user_token");

  if (token === null || token.length === 0) {
    callback(false);
  } else {
    makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
      callback(!haserror, response);
    });
  }
}

function getUserInfo(callback) {
  makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
    callback(haserror, response);
  });
}


function entryPathToArray(path) {
  let res = [];
  let current_val = null;
  let i = 1;
  let length = path.length - 1;
  while (i < length) {
    if (current_val === null) {
      current_val = path[i];
      i += 1;
      while (i < length && path[i] !== "/") {
        current_val += path[i];
        i += 1;
      }
      res.push(current_val);
      current_val = null;
    }
    i += 1;
  }
  return res;
}

function depthAdd(data, content_tree) {
  let level_entries = data.shift();
  let path = null;
  let position = null;
  let data_length = data.length;

  for (let entry of level_entries) {
    path = entryPathToArray(entry["entry_path"]);
    position = content_tree["sections"][path[0]];
    if(position.hasOwnProperty('children')){
      for (let i = 1; i < path.length; i++) {
        if(position["children"].hasOwnProperty(path[i])){
          position = position["children"][path[i]];
        }
      }
      position["children"][entry["entry_id"]] = Object.assign({}, entry);
      position["children"][entry["entry_id"]]["children"] = data_length > 0 ? {} : null;
    }
  }

  return data_length > 0 ? depthAdd(data, content_tree) : content_tree;
}

function LogOut() {
  makeAPICall("POST", "/user/logout", undefined, (haserror, response) => {
    return function(haserror, response){}
  });
  localStorage.removeItem("mvision_user_token");
  localStorage.removeItem("userIsAdmin");
  localStorage.removeItem("user");
  window.location.href = window.location.origin
}

function entryCompleted(entry, level, callback){
  if(level===4){
    bigWinCompleted(entry, callback);
  }
  else if(level===5){
    stepingStoneCompleted(entry, callback);
  }
}

function bigWinCompleted(entry){
  
  const element = document.getElementById('big-win-'+entry);
  sparkles(element);
  setTimeout(() => {
    confeti(element);
  }, 1000);
}

function stepingStoneCompleted(entry){
  const element = document.getElementById('steping-stone-'+entry);
  element.classList.add("bouncing-ball");
  setTimeout(() => {
    element.classList.remove("bouncing-ball");
    sparkles(element)
  }, 2000);
}

function toMysqlDate(date=new Date()){
  return date.toISOString().replace('T', ' ').substring(0,19);
}

function confeti(element){
  party.confetti(element, {
    count: 50,
  });
}
function sparkles(element){
  party.sparkles(element, {
    count: 20,
    speed: 100,
  });
}
function downloadFile(url, callback){
  makeAPICall(
    'GET', url, undefined,
    (haserror, response) => {
      if(!haserror){
        const linkSource = `data:application/pdf;base64,${response.message}`;
        const downloadLink = document.createElement("a");
        const fileName = "MyVision.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      callback();
    }
  );
}

function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function prepareVision(sections){
  let data = [];
  let groupKeys = [];
  try {
    groupKeys = Object.keys(sections);
  } catch (error) {
    return [];
  }
  for(let groupId of groupKeys){
    const group = sections[groupId];
    let groupData = {
      title: group.entry,
      icon: group.icon,
      id: group.entry_id,
      index: group.index || group.entry_id,
      sections: []
    }
    const sectionKeys = Object.keys(group.children);
    for(const sectionId of sectionKeys){
      const section = group.children[sectionId];
      let sectionData = {
        title: section.entry,
        icon: section.icon,
        id: section.entry_id,
        index: section.index || section.entry_id,
        headers: []
      }
      const headerKeys = Object.keys(section.children);
      for(const headerId of headerKeys){
        const header = section.children[headerId];
        let headerData = {
          title: header.entry,
          icon: header.icon,
          index: header.index || header.entry_id,
          id: header.entry_id,
          descriptions: []
        }
        const descriptionKeys = Object.keys(header.children);
        for(const descriptionId of descriptionKeys){
          const description = header.children[descriptionId];
          headerData.descriptions.push({
            id: description.entry_id,
            icon: description.icon,
            title: description.entry,
          })
        }
        sectionData.headers.push(headerData);
      }
      sectionData.headers.sort((a, b)=> a.index - b.index);
      groupData.sections.push(sectionData);
    }
    groupData.sections.sort((a, b)=> a.index - b.index);
    data.push(groupData);
  }
  data.sort((a, b)=> a.index - b.index);
  return data;
}

function stripHtml(html){
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
}

function scrollToJustAbove(element, margin=20) {
  let dims = element.getBoundingClientRect();
  window.scrollTo(window.scrollX, dims.top - margin);
}

function removeClass(className){
  const elements = document.querySelectorAll('.'+className);
  elements.forEach((element) => {
    element.classList.remove(className);
  });
}


function scrollAnchor(e) {
  e.preventDefault();
  const href = e.target.getAttribute("href");
  document.querySelector(href).scrollIntoView({ behavior: "smooth" });
}

async function uploadFile(formData, endpoint){
  const url = getApiUrl() + endpoint;
  let headers = getHeaders();
  delete headers['Content-Type'];
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: headers
    });

    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    return;
  }
}

export {
  makeAPICall,
  validEmail,
  validPassword,
  validPhone,
  validateUserToken,
  getUserInfo,
  entryPathToArray, 
  depthAdd,
  LogOut,
  entryCompleted,
  downloadFile,
  capitalize,
  stripHtml,
  prepareVision,
  scrollToJustAbove,
  removeClass,
  toMysqlDate,
  validateRole,
  scrollAnchor,
  uploadFile,
  getAvatarsUrl
};
