import React from "react";
import { Navigate } from "react-router-dom";
import {
  makeAPICall,
  validEmail,
  validateUserToken,
} from "../../../global/global-functions";
import Button from "../../../reuseables/button/button";
import LoadingModal from "../../../reuseables/loading/loading";
import Static from "../static/static";
import "./login.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      validEmail: true,
      redirect: false,
      loggedIn: false,
      checkedLoggedIn: false,
      invalidLoggedIn: null,
    };

    this.validEmail = this.validEmail.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.login = this.login.bind(this);

    this.credentials = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    const newUserEmail = localStorage.getItem("newUserEmail");
    if(newUserEmail){
      setTimeout(() => {
        localStorage.setItem("newUserEmail", '');
        document.querySelector('input[name="email"]').value = newUserEmail;
      }, 3000);
    }
    validateUserToken((valid) => {
      setTimeout(() => {
        this.setState({ loggedIn: valid, checkedLoggedIn: true });
      }, 1000);
    });
  }

  validEmail() {
    let valid = validEmail(this.credentials["email"]);
    this.setState({ validEmail: valid });
    return valid;
  }

  updateForm(event) {
    let key = event.target.name;
    let value = event.target.value.trim();
    this.credentials[key] = value;
  }

  login() {
    let valid = this.validEmail();

    if (valid) {
      this.setState({ buttonLoading: true, invalidLoggedIn: '' });
      makeAPICall(
        "POST",
        "/user/validations",
        this.credentials,
        (haserror, response) => {
          // setTimeout(() => {
            if (haserror) {
              let message = response.message;
              this.setState({ buttonLoading: false, invalidLoggedIn: message });
            } else {
              localStorage.setItem(
                "mvision_user_token",
                response["Authorization"]
              );
              const user = response['message'];
              localStorage.setItem('user', JSON.stringify(user));
              const userIsAdmin = this.credentials.email==='jackpotvin50@gmail.com';
              localStorage.setItem('userIsAdmin', userIsAdmin?1:0);
              if(userIsAdmin){
                window.open('/admin/user','_self');
              }
              this.setState({
                buttonLoading: false,
                loggedIn: true,
                invalidLoggedIn: null,
              });
            }
          // }, 1000);
        }
      );
    }
  }

  render() {
    if (!this.state.checkedLoggedIn) {
      return <LoadingModal />;
    }
    if (this.state.loggedIn) {
      return <Navigate to="/myvision" replace={true} />;
    }
    return (
      <div id="login-page">
        <div className="auth-static">
          <Static />
        </div>
        <div className="auth-form">
          <div className="auth-form-container">
            <label className="label-header">Log into your account</label>
            <div className="auth-form-input">
              <div className="auth-100-div">
                <input type="email" placeholder="Email" name="email" className="email" onChange={this.updateForm} />
                {this.state.validEmail ? null : (
                  <label className="input-error-message">
                    Ensure you typed in a correctly formatted email address.
                  </label>
                )}
              </div>
            </div>
            <div className="auth-form-input">
              <div className="auth-100-div">
                
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.updateForm}
                />
                {this.state.invalidLoggedIn ? (
                  <label className="input-error-message">
                    {this.state.invalidLoggedIn}
                  </label>
                ) : null}
              </div>
            </div>
            <div style={{ margin: "15px 0" }}></div>
            <label id="forgot-password">forgot your password?</label>
            <div style={{ margin: "15px 0" }}>
              <Button
                buttonName="Login"
                buttonColor="#3C45A8"
                textColor="#FFFFFF"
                loading={this.state.buttonLoading}
                onClick={this.login}
              />
            </div>
            <label id="auth-link">
              Don't have an account?{" "}
              <a href="/signup">
                <span>Sign up</span>
              </a>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
