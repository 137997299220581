import React from "react";
import "./report.css";
import { makeAPICall, getAvatarsUrl } from "../../global/global-functions";
import ProfileMenu from '../profile/profile-menu';
import UserContext from "../../reuseables/user-context/user-context";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const avatarUrlRoot = getAvatarsUrl();

class ReportPage extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      user_info: null,
      loading: false,
      successNessage: '',
      errorMessage: '',
    };
  }
  avatar(info){
    if(!info)
      return (<></>);
    if (info["profile_picture"]) {
      return (
        <img src={avatarUrlRoot+info["profile_picture"]} alt="user icon" />
      );
    }
    return (
      <label>
        <span>
          {info["first_name"][0] + info["last_name"][0]}
        </span>
      </label>
    );
  }
  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;
    
    if (user === null) {
      makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let user_info = response["message"];
          setUser(user_info);
          this.setState({ user_info: user_info });
        }
      });
    } else {
      this.setState({ user_info: user });
    }
  }

  sendReport(update) {
    this.setState({ loading: true, successNessage: '' });
    makeAPICall("POST", "/user/bug", update,
      (haserror, response) => {
        if (haserror) {
          
        } else {
          this.setState({ successNessage: 'Bug reported.' });
        }
        const form = document.getElementById("report_form").reset();
        const button = document.querySelector('button[type=submit]');
        button.disabled = false;
      }
    );
    this.setState({ loading: false, isEditMode: false });
  }

  preSubmit(form){
    form.preventDefault();
    const button = form.target.querySelector('button[type=submit]');
    button.disabled = true
    const reportData = Object.fromEntries(new FormData(form.target));
    this.sendReport(reportData);
  }

  render() {
    
    let user_info = this.state.user_info;
    const preSubmit = this.preSubmit.bind(this);
    return (
      <div id="profile-container">
        <div className="parent0">
          <ProfileMenu
            current='report'
            avatar={this.avatar(user_info)}
            userInfo={user_info}
            />
            <div className="profile-content">
              <form onSubmit={preSubmit} id="report_form">
                <div id="report-page" className="">
                  <div>
                    <h2>
                      Report a Bug &nbsp;
                      <FontAwesomeIcon icon={faLightbulb} color="#3C45A8"/>
                    </h2>
                  </div>
                  <div id="report-form">
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                      <div>
                        <div className="report-input">
                          <label htmlFor="first_name">First Name:</label>
                          <input type="text" name="first_name"  value={user_info?.first_name}/>
                        </div>
                        <div className="report-input">
                          <label htmlFor="last_name">Last Name:</label>
                          <input type="text" name="last_name"  value={user_info?.last_name}/>
                        </div>
                        <div className="report-input">
                          <label htmlFor="email">Email:</label>
                          <input type="email" name="email" value={user_info?.email}/>
                        </div>
                        <div className="report-input">
                          <label htmlFor="subject">Subject:</label>
                          <input type="text" name="subject" />
                        </div>
                      </div>
                      <div className="avatar">
                        {this.avatar(user_info)}
                      </div>
                    </div>
                    <div className="report-input">
                      <label htmlFor="body">Message:</label>
                      <textarea name="body" rows={10} autoCorrect={true} />
                    </div>
                    <br/>
                    <button type="submit"
                      className="mv-btn mv-blue btn-sm">
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      Save
                      &nbsp; &nbsp; &nbsp; &nbsp;
                    </button>
                    <br/>
                    <br/>
                    <span>{this.state.errorMessage}</span>
                    {this.state.successNessage && <span style={{color: 'purple'}}>{this.state.successNessage}</span>}
                  </div>
                  <br/>
                </div>
            </form>
          </div>
        </div>
      </div>
    );  
  }
}

export default ReportPage;
