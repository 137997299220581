import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  makeAPICall,
} from "../../../global/global-functions";

function ConfirmPage() {
  const { confirmation_token } = useParams();
  const [message, setMessage] = useState("Confirmating user...");

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = `/user/confirm/${confirmation_token}`;
    
    // Make the API call using fetch

    makeAPICall("GET", apiUrl, undefined, (haserror, response) => {
      if(response.status === 200){
        setMessage('Account confirmed. Redirecting...');
        setTimeout(() => {
          window.open('/login','_self');
        }, 3000);
      }
      else{
        setMessage(response.message);
      }
    });
    
  }, [confirmation_token]);
  
  return (
    <div>
      <h3>&nbsp;&nbsp;&nbsp;{message}</h3>
    </div>
  );
}

export default ConfirmPage;
