import React from "react";

const UserContext = React.createContext();

class UserProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };

    this.setUser = this.setUser.bind(this);
  }

  setUser(user) {
    this.setState({ user: user });
  }

  render() {
    let children = this.props.children;
    let user = this.state.user;
    let setUser = this.setUser;

    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserContext;

export { UserProvider };
