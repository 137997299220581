import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactDragListView from 'react-drag-listview'
import { faChevronDown, faChevronUp, faArrowsUpDown as dragIcon, faPlus as newFrameworkIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import Headers from './headers'
import ReactModal from 'react-modal';

class TableOfContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.prepareData(),
      showNewFrameworkModal: false
    };
  }

  prepareData(){
    let sections = this.props.content.sections;
    let data = [];
    let groupKeys = [];
    try {
      groupKeys = Object.keys(sections);
    } catch (error) {
      return [];
    }
    for(let groupId of groupKeys){
      const group = sections[groupId];
      let groupData = {
        title: group.entry,
        icon: group.icon,
        id: group.entry_id,
        index: group.index || group.entry_id,
        sections: []
      }
      const sectionKeys = Object.keys(group.children);
      for(const sectionId of sectionKeys){
        const section = group.children[sectionId];
        let sectionData = {
          title: section.entry,
          icon: section.icon,
          id: section.entry_id,
          index: section.index || section.entry_id,
          headers: []
        }
        const headerKeys = Object.keys(section.children);
        for(const headerId of headerKeys){
          const header = section.children[headerId];
          sectionData.headers.push({
            title: header.entry,
            icon: header.icon,
            index: header.index || header.entry_id,
            id: header.entry_id,
          });
        }
        sectionData.headers.sort((a, b)=> a.index - b.index);
        groupData.sections.push(sectionData);
      }
      groupData.sections.sort((a, b)=> a.index - b.index);
      data.push(groupData);
    }
    data.sort((a, b)=> a.index - b.index);
    return data;
  }
  
  updateData(){
    const data = this.prepareData();
    this.setState({data});
  }

  componentDidUpdate(prevProps){
    if (prevProps !== this.props) {
      this.updateData();
    }    
  }

  componentDidMount(){
    this.setContentTableHeight();
  }

  toggleSubsections(hide=false){
    const subsections = document.querySelectorAll('#table-of-content .subsection-div');
    [...subsections].forEach(icon=>{
      icon.style.visibility = hide ? 'hidden': 'visible';
    })
  }

  preReorderSections(groupId, fromIndex, toIndex){
    const group = this.props.content.sections[groupId].children;
    const sectionsKeys = Object.keys(group);
    const from = sectionsKeys[fromIndex];
    const to = sectionsKeys[toIndex];
    this.props.reorderSections(groupId, parseInt(from), parseInt(to));
  }

  collapseToggle(e){
    const section = $(e.currentTarget).closest('section');
    section.find('.subsection-div').slideToggle(300);
    section.find('button.collapse').toggleClass('on');
  }

  setContentTableHeight(){
    const table = document.querySelector('#table-of-content');
    const bar = document.querySelector('#nav-bar');
    try {
      const height = (window.innerHeight - table.offsetTop - bar.offsetTop - 30) + 'px';
      table.style.height = height;
    } catch (error) {
      return false;
    }
  }

  newFrameworkModal(){
    const closeModal = ()=>{
      this.setState({ showNewFrameworkModal: false });
    }
    const createFrameWork = (e)=>{
      e.preventDefault();
      const button = e.target.querySelector('button[type=submit]');
      button.disabled = true
      const framework = new FormData(e.target).get("framework");
      this.saveNewFramework(framework);
      setTimeout(() => { button.disabled = false; closeModal(); }, 500);
    }
    return <ReactModal
        isOpen={this.state.showNewFrameworkModal}
        id="new-framework-modal">
        <h3>New framework</h3>
        <form onSubmit={createFrameWork} autoComplete="off">
            <input type='text' name='framework'/>
						<button type='submit' className="mv-btn btn-sm mv-blue">Add</button>
            <button className="mv-btn mv-black btn-sm" onClick={closeModal}>Cancel</button>
        </form>
      </ReactModal>
  }

  saveNewFramework(value){
    const  entry = {
      entry: value,
      entry_path: '/',
      entry_level: 1,
      entry_weight: 0,
      children: {},
    };
    this.props.addFunction(entry);
  }

  render() {
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: 'section',
      handleSelector: 'i'
    };

    return (
      <div
        id="table-of-content"
        className='mvision-content-table-scrollbar'
        style={{height: 500, overflow: 'auto'}}>
        <div>
          
          {this.state.data.map((group, groupIndex)=>
            <div key={'group-key-'+groupIndex}>
              <h1 style={{margin: '0 30px 0 0', textAlign: 'center'}}>
                <button id={group.id} className='mv-btn mv-blue btn-sm'>{group.title}</button>
              </h1>
              <ReactDragListView
                {...dragProps} 
                lineClassName="drag-line"
                onDragEnd={(fromIndex, toIndex)=>{
                  this.preReorderSections(group.id, fromIndex, toIndex);
                }}
                >
                {group.sections.map((item, index) => (
                  <section key={'section-key-'+index}>
                    <div
                      href={"#" + item.id}>
                      <label className="section-label label-input">
                        <div>
                          <span className='section-icon'>
                            {String.fromCodePoint(item.icon)}
                          </span>
                          <span className='title'>{item.title}</span>
                          {item.headers.length > 0 && 
                            <button className='collapse no-btn pointer' onClick={this.collapseToggle}>
                              <FontAwesomeIcon color="purple" className='down' icon={faChevronDown}/>
                              <FontAwesomeIcon color="purple" className='up' icon={faChevronUp}/>                          
                            </button>
                          }
                        </div>
                      </label>
                      <i style={{padding: '2px 6px', cursor: 'row-resize'}}>
                        <FontAwesomeIcon icon={dragIcon}/>
                      </i>
                    </div>
                    <div className="subsection-div">
                      <Headers
                        group={group.id}
                        section={item.id}
                        reorderHeaders={this.props.reorderHeaders}
                        headers={item.headers}/>
                    </div>
                  </section>
                  
                ))}
              </ReactDragListView>
            </div>
          )}
        </div>
        <div id={'new-framework'}>
          <h1 style={{margin: '0 30px 0 0', textAlign: 'center'}}>
            <a href='#' onClick={()=>{this.setState({ showNewFrameworkModal: true });}}>
              <FontAwesomeIcon icon={newFrameworkIcon}/>
              &nbsp;
              New Framework</a>
          </h1>
        </div>
        {this.newFrameworkModal()}
      </div>
    );
  }
}

export default TableOfContent;
