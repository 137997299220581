import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../reuseables/button/button";
import CircularButton from "../../../reuseables/button/circularbutton";
import Quote from "../../../reuseables/quote/quote";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./notrack.css";
import "../../../reuseables/loading/loading.css";
import { Widget } from "@typeform/embed-react";
import { makeAPICall } from "../../../global/global-functions";


class NoTrackView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewForm: true,
      user_info: null,
      loadingVision: false
    };

    this.changeFormView = this.changeFormView.bind(this);
    this.storeResponseID = this.storeResponseID.bind(this);
    this.REACT_APP_TYPE_FORM_ID = process.env.REACT_APP_TYPE_FORM_ID;
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;

    if (user === null) {
      makeAPICall("GET", "/user/users", undefined, (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let user_info = response["message"];
          setUser(user_info);
          this.setState({ user_info: user_info });
        }
      });
    } else {
      this.setState({ user_info: user });
    }
  }

  storeResponseID(response_id) {
    this.setState({loadingVision: true, viewForm: false});
    makeAPICall(
      "POST",
      "/typeform/responses",
      {
        response_id: response_id,
      },
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          window.location.reload(false);
          console.log(response["message"]);
        }
      }
    );
  }

  changeFormView() {
    this.setState((prevState, prevProp) => {
      return { viewForm: !prevState.viewForm };
    });
  }

  render() {
    return (
      <div id="no-track-view" className="page">
        <div>
          <Quote
            quote="If you don't know where you are going, you will probably end up
          somewhere else."
            author="Lawrence J. Peter"
            align="center"
            showLine={true}
          />
        </div>
        <div id="no-track-view-content">
          {this.state.loadingVision || !this.props.userReady ?
            <div id="creating-vision">
              <h2>Loading vision</h2>
              <div id="page-loading-icon"></div>
            </div>
            :
            <div style={{ height: "fit-content" }}>
              <label
                className="centered-italized-small-text"
                style={{ fontWeight: "bolder" }}
              >
                You have not selected a Track. Select a Track to start your
                vision.
              </label>
              <Button
                buttonName="Select a Track"
                buttonColor="#3C45A8"
                textColor="#fffffe"
                fontSize="0.8rem"
                onClick={this.changeFormView}
              />
              <label className="centered-italized-small-text">
                You can take a look at{" "}
                <Link className="page-link" to="/inspire/templates">
                  Experts Vision Framework
                </Link>{" "}
                to see an example of each category
              </label>
            </div>
          }
        </div>

        {this.state.viewForm ? (
          <div id="create-framework-form-top-container">
            <div id="create-framework-form-container">
              <div id="create-framework-form-close-container">
                <CircularButton
                  icon={faXmark}
                  buttonColor="#f0807f"
                  textColor="#fffffe"
                  fontSize="0.8rem"
                  onClick={this.changeFormView}
                />
              </div>
              
              <Widget
                id={this.REACT_APP_TYPE_FORM_ID}
                className="typeform-widget"
                style={{ height: "calc(100vh - 30px)", marginTop: -110 }}
                onSubmit={(payload) => {
                  let response_id = payload.response_id;
                  this.storeResponseID(response_id);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default NoTrackView;
