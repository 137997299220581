import "./404.css";
import React, { Component } from 'react';

function NotFoundPage() {
  return (
    <div id="not-found-page">
      <div>
        <label className="label-header" style={{fontSize: "4rem"}}>404</label>
        <label className="label-header" style={{fontSize: "1.5rem"}}>
          The requested page does not exist. Please check that you entered the
          right url
        </label>
        <label className="centered-italized-small-text">
          Go To {" "}
          <a href="/myvision" className="page-link">
            MyVision Page
          </a>
        </label>
      </div>
    </div>
  );
}

export default NotFoundPage;
