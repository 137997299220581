import { Component } from 'react';
import "./inspire.css";
import "./library.css";
import {
    faBook, faVideo, faScroll, faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../navbar/logo-mvision.jpg";
import {
  makeAPICall,
} from "../../global/global-functions";

class KnowledgeLibraryPage extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        content: [],
        types: [],
        votes: [],
        filterByType: '',
        voting: 0,
        pagination: {
            page: 1,
            // pages: 1,
            perPage: 12
        },
        ready: false
    }
    componentDidMount(){
        const query = new URLSearchParams(window.location.search);
        const filterByType = parseInt(query.get('type'));
        this.setState({filterByType})
        
        makeAPICall("GET", "/library/prepare", undefined, (haserror, response) => {
            if (haserror) {
                console.log(response["message"]);
            } else {
                const {types, content, votes} = response["message"];
                this.setState({ types, content, votes, ready: true });
            }
        });
    }
    upVote(contentId){
        const down = this.state.votes.includes(contentId);
        const action = down ? 'downvote' : 'upvote';
        this.setState({voting: contentId})
        makeAPICall(
            "POST", "/library/" + action,
            {content_id: contentId}, (haserror, response) => {
                if (haserror) {
                    console.error(response["message"]);
                } else {
                    let {content, votes} = this.state;
                    if(down){
                        const index = votes.indexOf(contentId);
                        if (index > -1) {
                            votes.splice(index, 1);
                        }
                    }
                    else{
                        votes.push(contentId);
                    }
                    for (let index = 0; index < content.length; index++) {
                        const element = content[index];
                        if(element.id === contentId){
                            content[index]['vote_count'] = response["message"];
                        }            
                    }
                    this.setState({content, votes});
                }
                this.setState({voting: 0});
            });
    }
    getPage(){
        const {filterByType, pagination} = this.state;
        let data = this.state.content.filter(content=>(!filterByType||content.content_type_id===filterByType));
        const pages = Math.ceil(data.length/pagination.perPage);
        const startingFrom = (pagination.page-1)*pagination.perPage;
        data = data.splice(startingFrom, pagination.perPage);
        return {data, pages}
    }
    filterByType(type){
        let {filterByType, pagination} = this.state;
        filterByType = filterByType === type ? '': type;
        pagination.page = 1;
        this.setState({filterByType, pagination})
    }
    setPage(page){
        let {pagination} = this.state;
        if(pagination.page==page){
            return false;
        }
        pagination.page = page;
        this.setState({pagination})
    }
    description(content){
        // if(content.content_type.name=='book'){
        //     return 'by '+content.author;
        // }
        if(content.content_type.name=='movie'){
            return <i className='movie'>{content.description}</i>
        }
        return <i className='book'>{'by '+content.author}</i>
    }
    render() { 
        const {filterByType, ready, votes, pagination} = this.state;
        const page = this.getPage();
        return (
            <div id='knowledge-library-page'>
                <div className="container">
                    <div className='row header'>
                        <h1>The MVision Knowledge Library</h1>
                        <h2>Curated, high-impact knowledge... the best of the best that we & our community have found!</h2>
                    </div>
                    <div className='row types'>
                        <div className='content-type-container'>
                            {this.state.types.map((type, index)=>
                                <div
                                    onClick={()=>{this.filterByType(type.id)}}
                                    key={'type-'+index}
                                    className='content-type-button-wrapper'>
                                    <div className={'content-type-button '+(filterByType==type.id?'active':'')}>
                                        <div className='icon'><FontAwesomeIcon icon={faScroll}/></div>
                                        <span>{type.name.toUpperCase()}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row library-content'>
                        {page.data.map((content, index)=>
                            <div key={'content-'+index} className='library-content-container'>
                                <img src={content.thumbnail}/>
                                <h1>{content.title}</h1>
                                {this.description(content)}
                                <div>
                                    <div>
                                        <span>{content.vote_count}</span>&nbsp;
                                        <div><FontAwesomeIcon icon={faThumbsUp}/></div>
                                    </div>
                                    <div>
                                        <button 
                                            style={votes.includes(content.id)?{opacity: 0.5}:{}}
                                            onClick={()=>this.upVote(content.id)} 
                                            className="mv-btn mv-light-purple btn-sm no-border">
                                                Upvote{votes.includes(content.id)?'d':''}
                                            </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {page.data.length < 1 &&
                            <div className='empty'>{ready ? 'There is no available content':'Loading content...'}</div>
                        }
                    </div>
                    <div className='pagination'>
                    {[...Array(page.pages+1).keys()].splice(1).map((n, index)=>
                        <button
                            key={'pagination-'+index}
                            className='mv-btn mv-blue btn-xs'
                            disabled={n===pagination.page}
                            onClick={()=>{this.setPage(n)}}>{n}</button>
                    )}
                    </div>
                </div>
            </div>
        );
    }
}
 
export default KnowledgeLibraryPage;