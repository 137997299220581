import React from "react";
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import { faUser, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import {
  faBoltLightning,
  faGaugeHigh,
  faBriefcase,
  faFireFlameCurved, 
  faRightFromBracket, 
  faDownload, 
  faGripLines as iconFrameworks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogOut, downloadFile } from "../../global/global-functions";

class ProfileDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }

  componentDidMount(){
  }
  downloadPdf(){
    if(this.state.downloading){
      return false;
    }
    this.setState({downloading: true});
    downloadFile('/user/myvision/download/pdf', ()=>{
      this.setState({downloading: false})
    });
  }
  render(){
    const admin = this.props.role === 'admin';
    const user = this.props.user;
    return (
      <Dropdown className="profile" id={this.props.id}>
        <DropdownTrigger>
          <div className="avatar">
            {this.props.icon}
          </div>
        </DropdownTrigger>
        <DropdownContent>
          <div className="content-container">
            <header>
              <div className="avatar">
                {this.props.icon}
              </div>
              <div className="profile-data">
                <h1>{user?.first_name}</h1>
                <h2>@{user?.username || (user?.first_name)}</h2>
                <h2>{user?.email}</h2>
              </div>
              <div className="subscription">
                {this.props.subscription===1 &&
                  <button className="mv-btn btn-sm purple-grad">Pro</button>
                }
              </div>
            </header>
            <div className="options">
              {admin ||
              <>
                <hr className="hide-small"/>
                <a href="/myvision" className="option hide-small">
                  <FontAwesomeIcon icon={faBoltLightning}/>
                  <span>MyVision</span>
                </a>
                <a href="/mymetrics" className="option hide-small">
                  <FontAwesomeIcon icon={faGaugeHigh}/>
                  <span>MyMetrics</span>
                </a>
                <a href="/mytools" className="option hide-small">
                  <FontAwesomeIcon icon={faBriefcase}/>
                  <span>MyTools</span>
                </a>
                <hr/>
                <a href="/profile" className="option">
                  <FontAwesomeIcon icon={faUser}/>
                  <span>Account settings</span>
                </a>
                <a href="/statics" className="option">
                  <FontAwesomeIcon icon={iconFrameworks}/>
                  <span>Edit Framework</span>
                </a>
                <a href="#" onClick={()=>{this.downloadPdf()}}
                  className={"option"}>
                  <FontAwesomeIcon
                    className={this.state.downloading?"fa-spin":''}
                    icon={faDownload}/>
                  <span>Download MyVision to pdf</span>
                </a>
                <a href="/report" className="option">
                  <FontAwesomeIcon icon={faLightbulb}/>
                  <span>Report a bug</span>
                </a>
              </>
              }
            </div>
            <hr/>
            <footer>
              <a onClick={LogOut} className="option">
                <FontAwesomeIcon icon={faRightFromBracket}/>
                <span>Logout</span>
              </a>
            </footer>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

export default ProfileDropDown;