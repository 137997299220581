import React from "react";
import { Navigate } from "react-router-dom";
import "./landing.css";
import Logo from "./Logo_V2.png";
import LPage from "./LPage_Image.png";
import Carousel from "nuka-carousel"
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class OurProductPage extends React.Component{

    render(){
        return (
            <div id='product'>
                <div>
                    <div className="topnav" id="myTopnav">

                        <a className="home" href="/"><img id="logo" src={Logo} alt="MVision Logo" height={60} /></a>
                        <a href="/login" className="active" id="login">Login</a>
                        <a href="/contact">Contact Us</a>
                        <a href="#about">About Us</a>
                        <a href="/schools">For Schools</a>
                        <a href="#product" className="active">Our Product</a>
                        <a href="javascript:void(0);" className="icon" onclick="myFunction()">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div id="about-content">
                    <div id="header">
                        <br/>
                        <h1>Our Product | MVision LifeOS</h1>
                        <br/>
                        <h2>
                            Develop your personal life vision 0 to 1, driving 
                            more purposeful & directed life
                        </h2>
                        <br/>
                    </div>
                    <div id="carousel-container">
                        <Carousel 
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleLeft}/>
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="carousel-control">
                                    <FontAwesomeIcon icon={faChevronCircleRight}/>
                                </button>
                            )}
                            wrapAround autoplay autoplayInterval={5000}>
                            <img src="/capture1.jpg" />
                            <img src="/capture2.jpg" />
                            <img src="/capture3.jpg" />
                        </Carousel>
                    </div>
                    <div id="footer">
                        <hr/>
                        <h1>How it Works</h1>
                        <h2>
                            MVision is an easy-to-use software platform for young people 
                            to<br/>develop and begin to achieve their fulfilled life vision
                        </h2>
                        <br/>
                    </div>
                    <br/>
                    <div id="actions">
                        <a id="button2" href="/signup">Build My Vision <img className="infinity-emoji" src="/infinity-emoji.png" /></a>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default OurProductPage;