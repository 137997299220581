import React from "react";
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import helpContent from '../../../assets/help.json';
import { faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, duotone } from "@fortawesome/react-fontawesome";


class HeaderHelp extends React.Component {
  componentDidMount(){
    if(!Object.keys(helpContent).includes(this.props.header)){
      return null;
    }
    document.querySelector('#'+this.props.id+' div.help-content').innerHTML = helpContent[this.props.header];
  }
  render(){
    if(!Object.keys(helpContent).includes(this.props.header)){
      return null;
    }
    return (
      <Dropdown className="header-help" id={this.props.id}>
        <DropdownTrigger>
          <div className="icon"><FontAwesomeIcon icon={faGlasses}/></div>
        </DropdownTrigger>
        <DropdownContent>
          <div className="dead-zone"/>
          <div className="content-container">
            <header>
              <div className="left">
                <div className="icon">{String.fromCodePoint(this.props.emoji)}</div>
                <span>{this.props.header}</span>
              </div>
              <div className="right">
                <button disabled className="purple-button grad">
                  <div className="icon"><FontAwesomeIcon color="#000" icon={faGlasses}/></div>
                  <span>Examples</span>
                </button>
              </div>
            </header>
            <div className="help-content mvision-scrollbar"></div>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

export default HeaderHelp;