import React from "react";
import "./quote-carousel.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { faPen, faCheck, faTimes, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "nuka-carousel"

let _quotes = [
  '<p><i>We cannot solve problems with the kind of thinking we employed when we came up with them</i></p> <p style="text-align:right">-<strong>Albert Einstein</strong></p>',
  '<p><i>When you change your thoughts, remember to also change your world</i></p> <p style="text-align:right">-<strong>Norman Vincent Peale</strong></p>',
  '<p><i>Learn as if you will live forever, live like you will die tomorrow</i></p> <p style="text-align:right">-<strong>Mahatma Ghandi</strong></p>',
];
class QuoteCarousel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      editMode: false,
      showingIndex: 0
    };
    this.saveChanges = this.saveChanges.bind(this);
    this.undoChanges = this.undoChanges.bind(this);
  }
  prepareQuotes(){
    let quotes = [];
    for(const q of _quotes){
      quotes.push(q);
    }
    this.setState({quotes});
  }
  isEditing(){
    return this.state.editMode;
  }
  componentDidMount(){
    this.prepareQuotes();
  }

  quillComponent(quote, id){
    const modules = {
      toolbar: {
        container: '#toolbar'+id,
        handlers: {
        },
      },
      clipboard: {
        matchVisual: false,
      }
    }
    return (
      <div
        key={'quote-'+id}
        className={"quill-container ql-snow quote "+(this.state.editMode?'editable':'readonly')}>
        <div id={"toolbar"+id} className="ql-toolbar ql-snow">
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-align"></button>
            <button className="ql-align" value="right"></button>
            <button className="ql-align" value="center"></button>
          </span>
        </div>
        <ReactQuill
          id={id}
          readOnly={!this.state.editMode}
          className="quill-container"
          modules={modules}
          theme="snow"          
          value={quote}
          onChange={(html)=>{this.quillUpdated(html, id)}}
        />
      </div>
    );

  }
  quillUpdated(html, index){
    if(_quotes[0]===html){
      return;
    }
    _quotes[index] = html;
  }
  saveChanges(){
    this.prepareQuotes();
    this.setState({editMode: false});
  }
  undoChanges(){
    _quotes = [];
    for(const q of this.state.quotes){
      _quotes.push(q);
    }
    this.setState({editMode: false});
  }
  render() {
    return (
      <div className="quote-nuka-carousel-container">
        <section className="slider-wrapper">
          <Carousel
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => (
                <button onClick={previousSlide} className="carousel-control">
                    <FontAwesomeIcon fontSize={20} color='purple' icon={faChevronLeft}/>
                </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
                <button onClick={nextSlide} className="carousel-control">
                    <FontAwesomeIcon fontSize={20} color='purple' icon={faChevronRight}/>
                </button>
            )}
            wrapAround autoplay autoplayInterval={5000}>
              {_quotes.map((quote, i) =>
                <div key={i} className="slide">
                  {this.quillComponent(quote, i)}
                </div>
              )}
          </Carousel>
        </section>
        <div style={{paddingLeft: 30, display: 'none'}} >
          {this.state.editMode?
            <>
              <button
                className="mv-btn mv-blue btn-xs"
                onClick={this.saveChanges}>
                DONE &nbsp;<FontAwesomeIcon icon={faCheck}/>
              </button>
              <button
                className="mv-btn mv-blue-light btn-xs"
                onClick={this.undoChanges}>
                CANCEL &nbsp;<FontAwesomeIcon icon={faTimes}/>
              </button>
            </>
          :
            <button
              className="mv-btn mv-blue btn-xs"
              onClick={()=>{this.setState({editMode: true})}}>
              EDIT &nbsp;<FontAwesomeIcon icon={faPen}/>
            </button>
          }
        </div>
      </div>
    );
  }
}
export default QuoteCarousel;
