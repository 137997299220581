import autosize from "autosize";
import React from "react";
import { depthAdd, makeAPICall } from "../../global/global-functions";
import TableOfContent from "../../reuseables/table-of-content/table-of-content";
import "./track.css";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.depthRender = this.depthRender.bind(this);
  }

  depthRender(tree) {
    let res = [];
    let render = null;
    let child = null;
    let children = tree["children"];

    for (let id in children) {
      child = children[id];
      render = (
        <li key={id}>
          <div id={id} className="entry-container" style={{ marginTop: "0" }}>
            <textarea
              className="entry-label entry-input-view-mode"
              value={child["entry"]}
              id={"input-" + id}
              readOnly={true}
              rows={1}
            ></textarea>
          </div>
          {this.depthRender(child)}
        </li>
      );
      res.push(render);
    }
    return <ul className="entry-div">{res}</ul>;
  }

  render() {
    let content = this.props.content;
    return (
      <div id="myvision-content">
        {(() => {
          let res = [];
          let sections = content["sections"];
          for (let sec_id in sections) {
            res.push(
              <div key={sec_id} id={sec_id}>
                <div className="entry-container">
                  <input
                    className="section-label label-input entry-input-view-mode"
                    value={sections[sec_id]["entry"]}
                    id={"input-" + sec_id}
                    readOnly={true}
                  />
                </div>
                <div className="subsection-div">
                  {(() => {
                    let res1 = [];
                    let subsections = sections[sec_id]["children"];

                    for (let subsec_id in subsections) {
                      res1.push(
                        <div key={subsec_id} id={subsec_id}>
                          <div className="entry-container">
                            <input
                              className="subsection-label entry-input-view-mode"
                              value={subsections[subsec_id]["entry"]}
                              id={"input-" + subsec_id}
                              readOnly={true}
                            />
                          </div>

                          <ul className="entry-div">
                            {(() => {
                              let res2 = [];
                              let entries =
                                sections[sec_id]["children"][subsec_id][
                                  "children"
                                ];

                              for (let first_data_level_id in entries) {
                                res2.push(
                                  <li key={first_data_level_id}>
                                    <div
                                      id={first_data_level_id}
                                      className="entry-container"
                                      style={{ marginTop: "0" }}
                                    >
                                      <textarea
                                        className="entry-label entry-input-view-mode"
                                        id={"input-" + first_data_level_id}
                                        value={
                                          entries[first_data_level_id]["entry"]
                                        }
                                        readOnly={true}
                                        rows={1}
                                      ></textarea>
                                    </div>
                                    {this.depthRender(
                                      entries[first_data_level_id]
                                    )}
                                  </li>
                                );
                              }
                              return res2;
                            })()}
                          </ul>
                        </div>
                      );
                    }
                    return res1;
                  })()}
                </div>
              </div>
            );
          }
          autosize(document.querySelectorAll("textarea"));
          return res;
        })()}
      </div>
    );
  }
}

class TemplateViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: { sections: {} },
    };
  }

  componentDidMount() {
    let path_array = window.location.pathname.split("/");
    let length = path_array.length;

    let track_id = path_array[length - 2];
    let template_id = path_array[length - 1];
    makeAPICall(
      "GET",
      `/template/templates/${track_id}/${template_id}`,
      undefined,
      (haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let data = [...response["message"]];
          let content = { sections: {} };
          let sections = data.shift();

          for (let entry of sections) {
            content["sections"][entry["entry_id"]] = Object.assign({}, entry);
            content["sections"][entry["entry_id"]]["children"] = {};
          }
          content = depthAdd(data, content);
          this.setState({ content: content });
        }
      }
    );
  }

  render() {
    let content = this.state.content;
    return (
      <div id="myvision" className="page">
        <div id="table-of-content-container">
          <div style={{ margin: "0 auto", width: "90%" }}>
            <label className="label-header-left-aligned">
              Table of Contents
            </label>
            <hr className="horizontal-line" />
            <TableOfContent content={content} />
          </div>
        </div>
        <div id="myvision-content-container">
          <div style={{ margin: "0 auto", width: "98%", height: "100%" }}>
            <label className="label-header-left-aligned">MyVision</label>
            <hr className="horizontal-line" />
            <Content content={content} />
          </div>
        </div>
      </div>
    );
  }
}

export default TemplateViewPage;
