import React from "react";
import "./profile.css";
import { faUser, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faRightFromBracket, faDownload, faGripLines as iconFrameworks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogOut, downloadFile } from "../../global/global-functions";

class ProfileMenu extends React.Component {

  componentDidMount() {
  }

  downloadPdf(){
    downloadFile('/user/myvision/download/pdf');
  }

  render() {
    let userInfo = this.props.userInfo;
    return (
      <div className="menu">
        <div className="avatar">
          {this.props.avatar}
        </div>
        <header>
          <div className="profile-data">
            <h1>{userInfo?.first_name} {userInfo?.last_name}</h1>
            <h2>{userInfo?.username}</h2>
            <h2>{userInfo?.email}</h2>
          </div>
          <div className="subscription">
            <button className="mv-btn btn-sm purple-grad">Pro</button>
          </div>
        </header>
        <hr/>
        <div className="options">
          <a
            href={this.props.current=='profile'?"#":'/profile'}
            className={"option "+(this.props.current==='profile'?'selected':'')}>
            <FontAwesomeIcon icon={faUser}/>
            <span>Account settings</span>
          </a>
          <a
            href={this.props.current=='statics'?"#":'/statics'}
            className={"option "+(this.props.current==='statics'?'selected':'')}>
            <FontAwesomeIcon icon={iconFrameworks}/>
            <span>Edit Framework</span>
          </a>
          <a href="#" onClick={this.downloadPdf} className="option">
            <FontAwesomeIcon icon={faDownload}/>
            <span>Download MyVision to pdf</span>
          </a>
          <a
            href={this.props.current=='report'?"#":'/report'}
            className={"option "+(this.props.current==='report'?'selected':'')}>
            <FontAwesomeIcon icon={faLightbulb}/>
            <span>Report a bug</span>
          </a>
        </div>
        <hr/>
        <footer>
          <a onClick={LogOut} className="option">
            <FontAwesomeIcon icon={faRightFromBracket}/>
            <span>Logout</span>
          </a>
        </footer>
      </div>
    );
  }
}

export default ProfileMenu;
