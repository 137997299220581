import React from "react";
import { Navigate } from "react-router-dom";
import "./landing.css";
import Logo from "./Logo_V2.png";
import {
    faEnvelope, faMessage, faBars
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import {removeClass} from '../../global/global-functions'


class ContactPage extends React.Component{

    render(){
        return (
            <div id="whole">
                <div>
                    <div className="topnav" id="myTopnav">

                        <a className="home" href="/"><img id="logo" src={Logo} alt="MVision Logo" height={60} /></a>
                        <a href="/login" id="login">Login</a>
                        <Dropdown className="landing-links">
                            <DropdownTrigger><FontAwesomeIcon icon={faBars}/></DropdownTrigger>
                            <DropdownContent>
                                <ul>
                                    <li><a href="/#product">Our Product</a></li>
                                    <li><a href="/schools">For Schools</a></li>
                                    <li><a href="/about">About Us</a></li>
                                    {/* <li><a href="/contact">Contact Us</a></li> */}
                                </ul>
                            </DropdownContent>
                        </Dropdown>
                        <a href="#contact" className="active">Contact Us</a>
                        <a href="#about">About Us</a>
                        <a href="/schools">For Schools</a>
                        <a href="/#product">Our Product</a>
                        <a href="javascript:void(0);" className="icon" onclick="myFunction()">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div id="schools" className="contact-fix">
                    <div id="header">
                        <br/>
                        <h1>Contact Our Team</h1>
                        <h2>
                            Get in touch and see how our product aligns with your school's vision!
                        </h2>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                    <div id="contact-section-container">
                        <div id="contact-options">
                            <div className="contact-option">
                                <div className="header">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                    <h1>Contact Sales</h1>
                                </div>
                                <div className="contact-text">
                                    <h2>
                                        Please Contact MVision Founder Jack : <br/><br/>
                                        <a href="mailto:jackpotvin50@gmail.com">
                                        jackpotvin50@gmail.com
                                        </a>
                                    </h2>
                                </div>
                                <div className="action">
                                </div>
                            </div>
                            <div className="contact-option">
                                <div className="header">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faMessage}/>
                                    </div>
                                    <h1>Message Us</h1>
                                </div>
                                <div className="contact-text">
                                    <h2>Send over a  message:</h2>
                                </div>
                                <div className="action">
                                    <a href="mailto:Sales@Mvision.world">
                                        Write Message
                                    </a>
                                </div>
                            </div>
                            <div className="contact-option">
                                <div className="header">
                                    <img src="/linkedin.png"></img>
                                    <h1>Follow Us!</h1>
                                </div>
                                <div className="contact-text">
                                    <h2>Stay up to date with exciting news and new features:</h2>
                                </div>
                                <div className="action">
                                    <a href="https://www.linkedin.com/company/manifestvision/about/">
                                        View LinkedIn
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        
                    </div>
                    <hr className="foot-separator"/>
                    <br/>
                    <br/>
                    <div id="actions">
                        {/* <a id="button1" href="/about">Learn more</a> */}
                        <a id="button2" href="/#product">Learn More</a>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default ContactPage;