import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faBriefcase,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import Logo from "./logo-mvision.jpg";

import React from "react";
import "./navbar.css";
import { getUserInfo, getAvatarsUrl } from "../../global/global-functions";
import UserContext from "../../reuseables/user-context/user-context";

import ProfileDropDown from "./profile-dropdown";

const NAV_ROUTES = [
  {
    name: "Metrics Dashboard",
    font: faChartLine,
    route: "/admin/metrics",
  },
  {
    name: "Admin Table",
    font: faBriefcase,
    route: "/admin/user",
  },
  {
    name: "Knowledge Library",
    font: faBook,
    route: "/admin/content",
  },
];

const avatarUrlRoot = getAvatarsUrl();

class AdminNavBar extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      showProfileOptions: false,
      location: '',
    };
  }

  componentDidMount() {
    let user = this.context.user;
    let setUser = this.context.setUser;

    if (user === null) {
      getUserInfo((haserror, response) => {
        if (haserror) {
          console.log(response["message"]);
        } else {
          let data = response["message"];
          setUser(data);
          this.setState({ userInfo: data });
        }
      });
    }else{
      this.setState({ userInfo: user });
    }    
  }

  render() {
    let icon = null;
    if (this.state.userInfo) {
      if (this.state.userInfo["profile_picture"]) {
        icon = (
          <img src={avatarUrlRoot+this.state.userInfo["profile_picture"]} alt="user icon" />
          );
        } else {
          icon = (
          <label className="no-avatar" style={{backgroundImage: 'url(/logo_white.png)'}}>
            <span>
              <br/>
              {this.state.userInfo["first_name"][0] + this.state.userInfo["last_name"][0]}
            </span>
          </label>
        );
      }
    }
    return (
      <div id="nav-bar">
        <div id="nav-bar-logo" className="nav-bar-column">
          <a href="/landing">
            <button className="logo-btn no-btn">
              <img id="logo" src={Logo} alt="MVision Logo" height={40} />
              <br/>
              <span>Behind the Scenes 👀</span>
            </button>
          </a>
        </div>
        <div id="nav-bar-routes" className="nav-bar-column">
          {NAV_ROUTES.map((route, index) => {
            const url = route["route"];
            const active = '';
            return (
              <div
                key={index}
                id={`route-div-${index}`}>
                <div className={"nav-item "+active} id={`route-item-${index}`}>
                  <Link to={url}>
                    <FontAwesomeIcon icon={route.font} />
                    <label className="route-label"> {route.name}</label>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div id="nav-bar-profile" className="nav-bar-column">
          <ProfileDropDown user={this.state.userInfo} subscription={1} role='admin' icon={icon}/>
        </div>
      </div>
    );
  }
}

export default AdminNavBar;
